import React, {useEffect, useState} from 'react';
import {generateAIVideosText} from '../generateAIVideosText';
import './styles.css';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import {
  FILE_PICK_STEP,
  IMAGE_GENERATION_STEP,
  MUTE_EXCLUDE_STEP,
  VIDEO_DURATION_STEP,
  VOICE_NARRATION_STEP,
  SUMMARY_STEP,
} from '../config';
import MuteExcudeStep from '../components/MuteExcludeStep';
import VideoDuratonStep from '../components/VideoDurationStep';
import ImageGenerationStep from '../components/ImageGenerationStep';
import SummaryStep from '../components/SummarySteps';
import VoiceNarrationStep from '../components/VoiceNarrationStep';
import GenerationSteps from '../components/GenerationSteps';
import UploadFileStep from '../components/UploadFIleStep';
import HelpTip from '../components/HelpTip';
import {Col, Container, Row} from 'react-bootstrap';
import {
  DIALECTS,
  GENERATED_VIDEO_SHORT,
  VIDEO_RESPONSE_TYPES,
  VOICE_TONE,
} from '../../../../data/models/AI/VideoGenerationOptions';
import {getSecondsFromTimeText} from '../../../../utils/getTimeTextFromSeconds';
import {parseStringArrayToInt} from '../../../../utils/parseStringArrayToInt';
import Button from '../../../../uiToolkit/Buttons/Button';
import PageTitle from '../../../../uiToolkit/PageTitle';
import {tapyblAiHomeText} from '../../Home/tapyblAiHomeText';
import BackButton from '../../../../uiToolkit/Buttons/BackButton';
import {MediaFile} from '../../../../data/models/LibraryFile/MediaFile';
import {VideoGenerationConfig} from '../../../../data/models/AI/VideoGenerationConfig';
import {MyLibraryModal} from '../../../../modules/MyLibraryModal';

const MAX_IGNORE_AUDIO_SLIDES_DURATION_SECONDS = 3600;

interface Props {
  onSave: (config: VideoGenerationConfig) => void;
  calculateEstimation: (
    fileId: string,
    duration: number,
    aiImageRatio: number,
  ) => void;
  onCancel: () => void;
  isLoading: boolean;
  estimatedCost: number;
  availableCredits: number;
  fileOrientation: string;
  fetchFileOrientation: (fileId: string) => void;
}

const GenerateAIVideosUI = React.memo(
  ({
    onSave,
    onCancel,
    isLoading,
    calculateEstimation,
    estimatedCost,
    availableCredits,
    fileOrientation,
    fetchFileOrientation,
  }: Props) => {
    const [ignoreAudioSlidesList, setIgnoreAudioSlidesList] = useState('');
    const [ignoreSlidesList, setIgnoreSlidesList] = useState('');
    const [ignoreAudioSlidesDuration, setIgnoreAudioSlidesDuration] =
      useState('00:00:00');
    const [ignorePhrasesList, setIgnorePhrasesList] = useState<string[]>([]);
    const [videoDuration, setVideoDuration] = useState<string>(
      GENERATED_VIDEO_SHORT.toString(),
    );
    const [contextFiles, setContextFiles] = useState<MediaFile[]>([]);
    const [sliderValue, setSliderValue] = useState(0);
    const [file, setFile] = useState<MediaFile>();
    const [voiceGender, setVoiceGender] = useState('female');
    const [language, setLanguage] = useState('en');
    const initialDialects = DIALECTS[language as keyof typeof DIALECTS].map(
      (dialect: any) => ({key: dialect, value: dialect}),
    );
    const [dialectOptions, setDialectOptions] =
      useState<{key: string; value: string}[]>(initialDialects);
    const [selectedDialect, setSelectedDialect] = useState<string>(
      initialDialects[0]?.key,
    );
    const [resposeStyle, setResponseStyle] = useState<string>(
      VIDEO_RESPONSE_TYPES[0].key,
    );
    const [resposeTone, setResponseTone] = useState<string>(VOICE_TONE[0].key);
    const [videoImageStyle, setVideoImageStyle] = useState<string>();

    const [ignoreListIsValid, setIgnoreListIsValid] = useState(true);
    const [ignoreAudioListIsValid, setIgnoreAudioListIsValid] = useState(true);

    const [step, setStep] = useState(FILE_PICK_STEP);

    useEffect(() => {
      const newDialects = DIALECTS[language as keyof typeof DIALECTS].map(
        (dialect: any) => ({key: dialect, value: dialect}),
      );
      setDialectOptions(newDialects);
      setSelectedDialect(newDialects[0]?.key);
    }, [language]);

    useEffect(() => {
      if (file) {
        fetchFileOrientation(file.id);
      }
    }, [file]);

    useEffect(() => {
      if (step === SUMMARY_STEP && file) {
        calculateEstimation(file.id, parseInt(videoDuration, 10), sliderValue);
      }
    }, [step]);

    const ignoreAudioSlidesDurationIsValid = () => {
      if (ignoreAudioSlidesList === '') {
        return true;
      }
      return (
        ignoreAudioSlidesDuration !== '00:00:00' &&
        getSecondsFromTimeText(ignoreAudioSlidesDuration) <=
          MAX_IGNORE_AUDIO_SLIDES_DURATION_SECONDS
      );
    };

    const onSaveForm = () => {
      if (file && ignoreAudioSlidesDurationIsValid()) {
        onSave({
          libraryMediaFileId: file.id,
          generateAiImages: sliderValue !== 0,
          slideToArtifactRatio:
            sliderValue > 0 ? Math.abs(100 - sliderValue) : undefined,
          ignoreSlides: parseStringArrayToInt(ignoreSlidesList),
          ignoreAudioSlides: parseStringArrayToInt(ignoreAudioSlidesList),
          ignoreAudioSlidesDuration: getSecondsFromTimeText(
            ignoreAudioSlidesDuration,
          ),
          duration: parseInt(videoDuration || '0', 10),
          gender: voiceGender,
          language: language,
          dialect: selectedDialect,
          phrasesToIgnore: ignorePhrasesList,
          generateAiVideos: false,
          responseStyle: resposeStyle,
          communicationTone: resposeTone,
          imageStyle: videoImageStyle,
          additionalContextFiles: contextFiles.map(file => file.id),
        });
      }
    };

    const onAudioIgnoreSlidesChange = (value: string, isValid: boolean) => {
      setIgnoreAudioSlidesList(value);
      setIgnoreAudioListIsValid(isValid);
    };

    const onIgnoreSlidesChange = (value: string, isValid: boolean) => {
      setIgnoreSlidesList(value);
      setIgnoreListIsValid(isValid);
    };

    const updateDialectOptions = (language: string) => {
      const dialects = DIALECTS[language as keyof typeof DIALECTS];
      const options = dialects.map((dialect: any) => ({
        key: dialect,
        value: dialect,
      }));
      setDialectOptions(options);
    };

    const onUpdateAiImageValue = (value: number) => {
      if (value === 0) {
        setVideoImageStyle(undefined);
      } else if (sliderValue === 0 && value !== 0) {
        setVideoImageStyle('Cinematic Photo');
      }
      setSliderValue(value);
    };

    const onLanguageSelect = (value: string) => {
      setLanguage(value);
      updateDialectOptions(value);
    };

    const onNextStep = () => {
      if (step !== SUMMARY_STEP) {
        setStep(step + 1);
      } else {
        onSaveForm();
      }
    };

    const onSkipStep = () => {
      if (step === MUTE_EXCLUDE_STEP) {
        setIgnoreAudioSlidesList('');
        setIgnoreSlidesList('');
        setIgnoreAudioListIsValid(true);
        setIgnoreListIsValid(true);
      }
      if (step === IMAGE_GENERATION_STEP) {
        setSliderValue(0);
      }
      setStep(step + 1);
    };

    const canSkipCurrentStep = () => {
      if (
        step !== FILE_PICK_STEP &&
        step !== VIDEO_DURATION_STEP &&
        step !== VOICE_NARRATION_STEP &&
        step !== SUMMARY_STEP
      ) {
        return true;
      }
      return false;
    };

    const getNextButtonTitleForStep = () => {
      if (step !== SUMMARY_STEP) {
        return generateAIVideosText.nextStep;
      } else {
        return generateAIVideosText.generateVideo;
      }
    };

    const onPreviousStep = () => {
      if (step !== 0) {
        setStep(step - 1);
      }
    };

    const currentStepIsValid = () => {
      switch (step) {
        case FILE_PICK_STEP:
          return file !== undefined;
        case MUTE_EXCLUDE_STEP: {
          if (
            ignoreAudioSlidesList !== '' &&
            getSecondsFromTimeText(ignoreAudioSlidesDuration) === 0
          ) {
            return false;
          }
          return (
            ignoreAudioListIsValid &&
            ignoreListIsValid &&
            ignoreAudioSlidesDurationIsValid()
          );
        }
        case VIDEO_DURATION_STEP:
          return videoDuration !== undefined;
        case IMAGE_GENERATION_STEP:
          return true;
        case VOICE_NARRATION_STEP:
          return true;
        case SUMMARY_STEP:
          return estimatedCost < availableCredits;
      }
    };

    const getContentForStep = () => {
      switch (step) {
        case FILE_PICK_STEP:
          return <UploadFileStep onFileSelected={setFile} file={file} />;
        case MUTE_EXCLUDE_STEP:
          return (
            <MuteExcudeStep
              ignoreAudioSlidesDuration={ignoreAudioSlidesDuration}
              ignoreAudioSlidesList={ignoreAudioSlidesList}
              ignoreSlidesList={ignoreSlidesList}
              setIgnoreAudioSlidesDuration={setIgnoreAudioSlidesDuration}
              setIgnoreAudioSlidesList={onAudioIgnoreSlidesChange}
              setIgnoreSlidesList={onIgnoreSlidesChange}
              ignorePhrasesList={ignorePhrasesList}
              setIgnorePhrasesList={setIgnorePhrasesList}
            />
          );
        case VIDEO_DURATION_STEP:
          return (
            <VideoDuratonStep
              videoDuration={videoDuration}
              setVideoDuration={setVideoDuration}
              onContextFilesSelected={setContextFiles}
              files={contextFiles}
            />
          );
        case IMAGE_GENERATION_STEP:
          return (
            <ImageGenerationStep
              fileOrientation={fileOrientation}
              setSliderValue={onUpdateAiImageValue}
              sliderValue={sliderValue}
              videoImageStyle={videoImageStyle || ''}
              setVideoImageStyle={setVideoImageStyle}
            />
          );
        case VOICE_NARRATION_STEP:
          return (
            <VoiceNarrationStep
              language={language}
              setLanguage={onLanguageSelect}
              dialectOptions={dialectOptions}
              selectedDialect={selectedDialect}
              setSelectedDialect={setSelectedDialect}
              voiceGender={voiceGender}
              setVoiceGender={setVoiceGender}
              resposeStyle={resposeStyle}
              setResposeStyle={setResponseStyle}
              responseTone={resposeTone}
              setResponseTone={setResponseTone}
            />
          );
        case SUMMARY_STEP:
          return (
            <SummaryStep
              filename={file?.fileName || ''}
              excludePhrases={
                ignorePhrasesList.length
                  ? ignorePhrasesList.reduce(
                      (acc, value) => (acc += ', ' + value),
                    )
                  : ''
              }
              muteDuration={ignoreAudioSlidesDuration}
              muteSlides={ignoreAudioSlidesList}
              excludeSlides={ignoreSlidesList}
              duration={videoDuration || '0'}
              generateImages={sliderValue > 0}
              imageRatio={sliderValue}
              language={language}
              dialect={selectedDialect}
              voiceGender={voiceGender}
              estimatedCost={estimatedCost}
              availableCredits={availableCredits}
              responseStyle={resposeStyle}
              responseTone={resposeTone}
              imageStyle={videoImageStyle}
            />
          );
        default:
          return <div />;
      }
    };

    return (
      <div className="AIGenerationContainer">
        <div className="GenerateAIVideosContainer">
          <PageTitle
            title={tapyblAiHomeText.title}
            subtitle={tapyblAiHomeText.subtitle}
          />
          <div className="Header">
            <div className="BackButton">
              <BackButton />
            </div>
            <h2>{generateAIVideosText.title}</h2>
          </div>
          <Container>
            <Row className="Content">
              <Col lg={12} xl={{span: 10, offset: 1}}>
                <GenerationSteps activeStep={step} />
                <div className="Body">
                  <div className="Steps">
                    {getContentForStep()}
                    <div className="Footer">
                      {step === SUMMARY_STEP && (
                        <Button
                          title={generateAIVideosText.exit}
                          onClick={onCancel}
                          uiType="text"
                        />
                      )}
                      <Button
                        title={generateAIVideosText.previousStep}
                        onClick={onPreviousStep}
                        uiType="hollow"
                        className="SkipButton"
                        disabled={step === 0}
                      />
                      {canSkipCurrentStep() && (
                        <Button
                          title={generateAIVideosText.skipStep}
                          onClick={onSkipStep}
                          uiType="hollow"
                          className="SkipButton"
                        />
                      )}
                      <Button
                        title={getNextButtonTitleForStep()}
                        onClick={onNextStep}
                        uiType="action"
                        disabled={!currentStepIsValid() || isLoading}
                        isLoading={isLoading}
                      />
                    </div>
                  </div>
                  <HelpTip step={step} />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <MyLibraryModal />
      </div>
    );
  },
);

export default GenerateAIVideosUI;
