import React from 'react';
import {
  MediaFile,
  MEDIA_FILES_DOCUMENT,
} from '../../../../data/models/LibraryFile/MediaFile';
import SpecialLibraryFilesInput from './SpecialLibraryFilesInput';
import {inject, observer} from 'mobx-react';
import MyLibraryModalStore from '../../../../modules/MyLibraryModal/store/MyLibraryModalStore';

interface Props {
  myLibraryModalStore?: MyLibraryModalStore;
  sourceFiles?: MediaFile[];
  onFilesSelected: (files: MediaFile[]) => void;
  disabled?: boolean;
}

const SpecialBrowse: React.FC<Props> = ({
  myLibraryModalStore,
  sourceFiles,
  onFilesSelected,
  disabled,
}) => {
  const openMediaLibrary = () => {
    myLibraryModalStore?.showLibrary(
      MEDIA_FILES_DOCUMENT,
      files => onFilesSelected(files),
      true,
      sourceFiles || [],
    );
  };

  return (
    <div className="SepcialBrowse">
      <SpecialLibraryFilesInput
        onBrowse={openMediaLibrary}
        files={sourceFiles || []}
        onRemoveFile={onFilesSelected}
        disabled={disabled}
      />
    </div>
  );
};

export default inject('myLibraryModalStore')(observer(SpecialBrowse));
