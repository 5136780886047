import React, {useCallback, useEffect, useRef, useState} from 'react';
import {aiAssistantText} from '../aiAssistantText';
import {AiAssistantListItem} from '../../../../data/models/AI/AiAssistantModels';

interface Props {
  onPreviewAssistant: (id: string) => void;
  onDeleteAssistant: (id: string) => void;
  onEditAssistant: (id: string) => void;
  assistant: AiAssistantListItem;
}

const AiAssistantOptions = React.memo(
  ({
    onPreviewAssistant,
    onDeleteAssistant,
    assistant,
    onEditAssistant,
  }: Props) => {
    const [showOptions, setShowOptions] = useState(false);

    const openerRef = useRef<any>(null);
    const openerArrowRef = useRef<any>(null);

    const onPreviewOption = () => {
      setShowOptions(false);
      onPreviewAssistant(assistant.id);
    };

    const onEditOption = () => {
      setShowOptions(false);
      onEditAssistant(assistant.id);
    };

    const onDelete = () => {
      onDeleteAssistant(assistant.id);
    };

    useEffect(() => {
      window.addEventListener('click', handleBodyClick);
      return () => {
        window.removeEventListener('click', handleBodyClick);
      };
    }, [showOptions]);

    const handleBodyClick = useCallback(
      (ev: any) => {
        if (
          showOptions &&
          openerRef?.current !== ev.target &&
          openerArrowRef?.current !== ev.target
        ) {
          setShowOptions(false);
        }
      },
      [showOptions],
    );

    return (
      <div className="AssistantOptions">
        <button
          className="Opener"
          onClick={() => setShowOptions(!showOptions)}
          ref={openerRef}>
          <span className="icon-dots-horizontal" ref={openerArrowRef}></span>
        </button>
        {showOptions && (
          <div className="Options">
            <div className={`Option`} onClick={onPreviewOption}>
              {aiAssistantText.test}
            </div>
            <div className={`Option`} onClick={onEditOption}>
              {aiAssistantText.edit}
            </div>
            <div className={`Option DeleteOption`} onClick={() => onDelete()}>
              {aiAssistantText.delete}
            </div>
          </div>
        )}
      </div>
    );
  },
);

export default AiAssistantOptions;
