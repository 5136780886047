import React, {useState} from 'react';
import {generateAIVideosText} from '../generateAIVideosText';
import {SelectOption} from '../../../../data/models/UI/SelectOption';
import Dropdown from '../../../../uiToolkit/Inputs/Dropdown';
import {
  LANGUAGE,
  VIDEO_RESPONSE_TYPES,
  VOICE_TONE,
} from '../../../../data/models/AI/VideoGenerationOptions';
import MaleFemaleSelect from '../../../../uiToolkit/Inputs/MaleFemaleSelect';

interface Props {
  language: string;
  setLanguage: (value: string) => void;
  dialectOptions: SelectOption[];
  selectedDialect: string;
  setSelectedDialect: (value: string) => void;
  voiceGender: string;
  setVoiceGender: (value: string) => void;
  resposeStyle: string;
  setResposeStyle: (style: string) => void;
  responseTone: string;
  setResponseTone: (tone: string) => void;
}

const VoiceNarrationStep = React.memo(
  ({
    language,
    selectedDialect,
    setLanguage,
    dialectOptions,
    setSelectedDialect,
    voiceGender,
    setVoiceGender,
    resposeStyle,
    setResposeStyle,
    responseTone,
    setResponseTone,
  }: Props) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    return (
      <div className="StepContainer">
        <h5 className="StepTitle">{generateAIVideosText.narrationTitle}</h5>
        <h5 className="SectionTitle mt-4">
          {generateAIVideosText.languageDropdownHeading}
        </h5>
        <div className="DropdownGroup">
          <Dropdown
            options={LANGUAGE}
            selectedOption={language}
            setSelectedOption={setLanguage}
            className="LanguageDropdown"
          />
          <Dropdown
            options={dialectOptions}
            selectedOption={selectedDialect}
            setSelectedOption={setSelectedDialect}
            className="LanguageDropdown"
          />
        </div>
        <h5 className="SectionTitle mt-4">
          {generateAIVideosText.voiceGenderHeading}
        </h5>
        <div className="DropdownGroup">
          <Dropdown
            options={VIDEO_RESPONSE_TYPES}
            selectedOption={resposeStyle}
            setSelectedOption={setResposeStyle}
            className="LanguageDropdown"
            onDropdownVisibilityToggle={setDropdownOpen}
          />
          <Dropdown
            options={VOICE_TONE}
            selectedOption={responseTone}
            setSelectedOption={setResponseTone}
            className="LanguageDropdown"
            onDropdownVisibilityToggle={setDropdownOpen}
          />
        </div>
        <h5 className="SectionTitle mt-4 mb-3">
          {generateAIVideosText.narrationGenderLabel}
        </h5>
        <MaleFemaleSelect
          selectedOption={voiceGender}
          onSelectOption={setVoiceGender}
        />
        <div className="mb-4" />
        {dropdownOpen && <div className="DropdownPadding" />}
      </div>
    );
  },
);

export default VoiceNarrationStep;
