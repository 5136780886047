import {VideoGenerationConfig} from '../models/AI/VideoGenerationConfig';
import {ApiResponse, PaginatedApiReponse} from '../models/ApiResponse';
import {GeneratedVideoChunk} from '../models/LibraryFile/GeneratedVideoChunk';
import {
  FileOrientation,
  MediaFile,
  VideoTranscriptSegment,
  VimeoMediaFile,
} from '../models/LibraryFile/MediaFile';
import {UploadUrlResponse} from '../models/LibraryFile/UploadUrlResponse';
import {apiClient, withAuth} from './baseApiClient';

class LibraryApi {
  public async uploadLibraryVideo(
    organizationId: string,
    video: File,
    duration: number,
  ): Promise<ApiResponse<any>> {
    const headers = await withAuth({});
    const form = new FormData();
    form.append('file', video);
    form.append('duration', duration.toString());
    return apiClient.post(`/mediaLibrary/${organizationId}/upload`, form, {
      headers,
    });
  }

  public uploadFileToStorage = async (file: File, signedUrl: string) => {
    return fetch(signedUrl, {
      method: 'PUT',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/octet-stream',
      },
      body: file,
    });
  };

  public async generateVideoUploadLink(
    organizationId: string,
    type: string,
    fileSize: number,
  ): Promise<ApiResponse<UploadUrlResponse>> {
    const headers = await withAuth({});
    return apiClient.post(
      `/mediaLibrary/${organizationId}/upload_url?type=${type}&fileSizeInMb=${fileSize}`,
      {},
      {
        headers,
      },
    );
  }

  public async createMediaLibraryFile(
    organizationId: string,
    storageFileName: string,
    type: string,
    name: string,
    fileSizeInMb: number,
    duration?: number,
    width?: number,
    height?: number,
    targetRes?: number | null,
  ): Promise<ApiResponse<UploadUrlResponse>> {
    const headers = await withAuth({});
    return apiClient.post(
      `/mediaLibrary/${organizationId}/add`,
      {
        storageFileName,
        type,
        name,
        fileSizeInMb,
        duration,
        width,
        height,
        videoResolution: targetRes,
      },
      {
        headers,
      },
    );
  }

  public async getLibraryFiles(
    page: number,
    organizationId: string,
    pageSize: number,
    orderBy: string,
    typeFilter: number,
    search?: string,
    orderAsk?: boolean,
    origin?: number,
  ): Promise<PaginatedApiReponse<MediaFile>> {
    const headers = await withAuth({});
    return apiClient.post(
      `/mediaLibrary/${organizationId}/list`,
      {
        page,
        pageSize,
        orderBy,
        typeFilter,
        search,
        orderAsk,
        origin,
      },
      {headers},
    );
  }

  public async getFileByContentId(
    organizationId: string,
    contnentId: string,
  ): Promise<ApiResponse<MediaFile>> {
    const headers = await withAuth({});
    return apiClient.get(
      `/mediaLibrary/${organizationId}/get_by_content/${contnentId}`,
      {headers},
    );
  }

  public async downloadTranscript(
    organizationId: string,
    mediaFileId: string,
  ): Promise<ApiResponse<VideoTranscriptSegment[]>> {
    return apiClient.get(
      `/mediaLibrary/${organizationId}/transcript/${mediaFileId}`,
    );
  }

  public async deleteMediaFile(
    organizationId: string,
    mediaFileId: string,
  ): Promise<ApiResponse<any>> {
    const headers = await withAuth({});
    return apiClient.delete(`/mediaLibrary/${organizationId}/${mediaFileId}`, {
      headers,
    });
  }

  public async getVimeoVideos(
    organizationId: string,
    page: number,
    pageSize: number,
  ): Promise<ApiResponse<VimeoMediaFile[]>> {
    const headers = await withAuth({});
    return apiClient.get(
      `/mediaLibrary/${organizationId}/vimeo/videos?page=${page}&pageSize=${pageSize}`,
      {
        headers,
      },
    );
  }

  public async generateAiVideosFromSlides(
    organizationId: string,
    config: VideoGenerationConfig,
  ): Promise<ApiResponse<MediaFile[]>> {
    return apiClient.post(
      `/mediaLibrary/${organizationId}/generate_video_from_slide_new`,
      config,
    );
  }

  public async getVideoChunksForGeneratedContent(
    organizationId: string,
    mediaFileId: string,
  ): Promise<ApiResponse<GeneratedVideoChunk[]>> {
    return apiClient.get(
      `/mediaLibrary/${organizationId}/generate_video_from_slide/${mediaFileId}/chunks`,
    );
  }

  public async updateVideoChunksForGeneratedContent(
    organizationId: string,
    mediaFileId: string,
    chunks: GeneratedVideoChunk[],
  ): Promise<ApiResponse<GeneratedVideoChunk[]>> {
    return apiClient.post(
      `/mediaLibrary/${organizationId}/generate_video_from_slide/${mediaFileId}/chunks`,
      chunks,
    );
  }

  public async compileVideoFile(
    organizationId: string,
    mediaFileId: string,
  ): Promise<ApiResponse<any>> {
    return apiClient.post(
      `/mediaLibrary/${organizationId}/generate_video_from_slide/${mediaFileId}/compile`,
    );
  }

  public async getFileOrientation(
    organizationId: string,
    fileId: string,
  ): Promise<ApiResponse<FileOrientation>> {
    return apiClient.get(
      `/mediaLibrary/${organizationId}/get_file_orientation?mediaFileId=${fileId}`,
    );
  }
}

export default LibraryApi;
