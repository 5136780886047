import React, {useState} from 'react';
import './styles.css';
import {learnerAiAssistantsText} from '../learnerAiAssitantsText';
import {MyAssitantListItem} from '../../../data/models/AI/AiAssistantModels';
import {AiAssistantChatbot} from '../../AiAssistantChatbot';

interface Props {
  assistants: MyAssitantListItem[];
  openAssistant: (id: string) => void;
  closeAssistant: () => void;
}

const LearnerAiAssistantsUI = React.memo(
  ({assistants, openAssistant, closeAssistant}: Props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedAssistant, setSelectedAssistant] = useState('');

    const togglePopupVisibility = () => {
      if (isOpen) {
        setIsOpen(!isOpen);
        setSelectedAssistant('');
        closeAssistant();
      } else {
        setIsOpen(!isOpen);
      }
    };

    const onAssistantClick = (id: string) => {
      openAssistant(id);
      setSelectedAssistant(id);
    };

    return (
      <div className="LearnersAiAssistantsContainer">
        {isOpen && !selectedAssistant ? (
          <div className="AssistantsListContainer">
            <div className="Header">
              <p>{learnerAiAssistantsText.hello}</p>
              <p>{learnerAiAssistantsText.info}</p>
            </div>
            <div className="AssistantsList">
              {assistants.map(assistant => (
                <div
                  key={assistant.id}
                  className="AssistantContainer"
                  onClick={() => onAssistantClick(assistant.id)}>
                  {assistant.imageUrl ? (
                    <img src={assistant.imageUrl} />
                  ) : (
                    <div className="Placeholder">
                      <span className="icon-ai_assistant_ic"></span>
                    </div>
                  )}
                  <div>
                    <p className="Title">{assistant.name}</p>
                    <p className="Description">{assistant.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : isOpen && selectedAssistant ? (
          <AiAssistantChatbot onClosed={togglePopupVisibility} learnerView />
        ) : null}
        <button className="OpenerButton" onClick={togglePopupVisibility}>
          <span
            className={isOpen ? 'icon-plus' : 'icon-ai_assistant_ic'}></span>
        </button>
      </div>
    );
  },
);

export default LearnerAiAssistantsUI;
