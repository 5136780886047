import {SortOption} from '../../data/models/UI/SelectOption';

export const MEDIA_FILES_PAGE_SIZE = 9;

export const MEDIA_FILES_SORT_OPTIONS: SortOption[] = [
  {
    field: 'file_name',
    order: 'asc',
    title: 'Name (A-Z)',
  },
  {
    field: 'file_name',
    order: 'desc',
    title: 'Name (Z-A)',
  },
  {
    field: 'date_created',
    order: 'desc',
    title: 'Uploaded date (Newest)',
  },
  {
    field: 'date_created',
    order: 'asc',
    title: 'Uploaded date (Oldest)',
  },
];
