import React from 'react';
import {tapyblAiHomeText} from './tapyblAiHomeText';
import './styles.css';
import {Link} from 'react-router-dom';
import PageTitle from '../../../uiToolkit/PageTitle';

const TapyblAIHome = React.memo(() => {
  return (
    <div className="TapyblAiHomeContainer">
      <PageTitle
        title={tapyblAiHomeText.title}
        subtitle={tapyblAiHomeText.subtitle}
      />
      <div className="InfoSection">
        <h1 className="Heading">{tapyblAiHomeText.selection}</h1>
        <div className="Options">
          <Link to="ai_assistant" className="PathCard">
            <span className="icon-ai_assistant_ic" />
            <h1 className="PathTitle">{tapyblAiHomeText.aiAssistants}</h1>
            <p>{tapyblAiHomeText.aiAssistantsDescription}</p>
          </Link>
          <Link to="generate_video" className="PathCard">
            <span className="icon-ai_video_icon" />
            <h1 className="PathTitle">{tapyblAiHomeText.videos}</h1>
            <p>{tapyblAiHomeText.aiVideosDescription}</p>
          </Link>
        </div>
      </div>
    </div>
  );
});

export default TapyblAIHome;
