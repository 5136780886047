export const aiAssistantText = {
  title: 'Tapybl AI',
  subtitle: 'Powered by AI, driven by you',
  newAssistant: 'New assistant',
  assigned: 'Assigned',
  test: 'Test',
  edit: 'Edit',
  delete: 'Delete',
  visibilityOFF: 'Users can no longer access this AI Assistant',
  visibilityON: 'Users can now access this AI Assistant',
  assistantDeleted: 'AI Assistant deleted',
  confirmDelete: 'Are you sure you want to delete this AI assistant?',
  emptyState1: 'Oops! Looks like you haven’t added any assistants yet.',
  emptyState2: 'Let’s get started by creating your first assistant now!',
  assistantIsProcessing: 'AI Assistant is being created...',
};
