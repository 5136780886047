import {
  imageStyle2dAmination,
  imageStyle3dAnimation,
  imageStyleChalkboardSketch,
  imageStyleCinematicPhoto,
  imageStyleVectorIllustration,
} from '../../../assets/images';

export const VOICE_GENDER_FEMALE = 'female';
export const VOICE_GENDER_MALE = 'male';

export const GENERATED_VIDEO_SHORT = 0;
export const GENERATED_VIDEO_AVERAGE = 1;
export const GENERATED_VIDEO_LONG = 2;

export const VOICE_TONE = [
  {
    key: 'calm',
    value: 'Calm',
  },
  {
    key: 'energetic',
    value: 'Energetic',
  },
  {
    key: 'friendly',
    value: 'Friendly',
  },
  {
    key: 'serious',
    value: 'Serious',
  },
];

export const VIDEO_LENGTH_OPTIONS = [
  {
    key: GENERATED_VIDEO_SHORT.toString(),
    value: 'Short',
  },
  {
    key: GENERATED_VIDEO_AVERAGE.toString(),
    value: 'Average',
  },
  {
    key: GENERATED_VIDEO_LONG.toString(),
    value: 'Long',
  },
];

export const LANGUAGE = [
  {
    key: 'ar',
    value: 'Arabic',
  },
  {
    key: 'az',
    value: 'Azerbaijani',
  },
  {
    key: 'bn',
    value: 'Bengali',
  },
  {
    key: 'bs',
    value: 'Bosnian',
  },
  {
    key: 'bg',
    value: 'Bulgarian',
  },
  {
    key: 'ca',
    value: 'Catalan',
  },
  {
    key: 'cs',
    value: 'Czech',
  },
  {
    key: 'da',
    value: 'Danish',
  },
  {
    key: 'de',
    value: 'German',
  },
  {
    key: 'el',
    value: 'Greek',
  },
  {
    key: 'en',
    value: 'English',
  },
  {
    key: 'es',
    value: 'Spanish',
  },
  {
    key: 'he',
    value: 'Hebrew',
  },
  {
    key: 'hi',
    value: 'Hindi',
  },
  {
    key: 'cy',
    value: 'Welsh',
  },
  {
    key: 'tr',
    value: 'Turkish',
  },
].sort((a, b) => a.value.localeCompare(b.value));

export const DIALECTS: any = {
  ar: ['AE'],
  az: ['AZ'],
  bg: ['BG'],
  bn: ['BD', 'IN'],
  bs: ['BA'],
  ca: ['ES'],
  cs: ['CZ'],
  cy: ['GB'],
  da: ['DK'],
  de: ['AT', 'CH', 'DE'],
  el: ['GR'],
  en: ['US', 'AU', 'CA', 'GB', 'HK', 'IE', 'IN', 'ZA'],
  es: ['AR', 'BO', 'CL', 'CO'],
  he: ['IL'],
  hi: ['IN'],
  tr: ['TR'],
};

export const VIDEO_RESPONSE_TYPES = [
  {
    key: 'conversational',
    value: 'Conversational',
  },
  {
    key: 'informative',
    value: 'Informative',
  },
  {
    key: 'professional',
    value: 'Professional',
  },
  {
    key: 'storytelling',
    value: 'Storytelling',
  },
];

export const VIDEO_IMAGE_STYLES = [
  {
    key: '2D Animation',
    value: '2D Animation',
    image: imageStyle2dAmination,
  },
  {
    key: '3D Character Art',
    value: '3D Character Art',
    image: imageStyle3dAnimation,
  },
  {
    key: 'Chalkboard Sketch',
    value: 'Chalkboard Sketch',
    image: imageStyleChalkboardSketch,
  },
  {
    key: 'Cinematic Photo',
    value: 'Cinematic Photo',
    image: imageStyleCinematicPhoto,
  },
  {
    key: 'Vector Illustration',
    value: 'Vector Illustration',
    image: imageStyleVectorIllustration,
  },
];
