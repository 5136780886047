import {useEffect} from 'react';

let isGtmInitialized = false;

const useGoogleTagManager = (gtmId: string): void => {
  useEffect(() => {
    if (isGtmInitialized) return;

    const gtmScript = document.createElement('script');
    gtmScript.src = `https://www.googletagmanager.com/gtag/js?id=${gtmId}`;
    gtmScript.async = true;
    document.head.appendChild(gtmScript);

    gtmScript.onload = () => {
      const inlineScript = document.createElement('script');
      inlineScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${gtmId}');
      `;
      document.head.appendChild(inlineScript);

      isGtmInitialized = true; // Marcar GTM como inicializado
      document.dispatchEvent(new Event('gtm_initialized')); // Disparar evento personalizado
    };

    return () => {
      document.head.removeChild(gtmScript);
    };
  }, [gtmId]);
};

export default useGoogleTagManager;

export const sendPageView = (pagePath: string, title?: string): void => {
  const pushEvent = () => {
    const dataLayer = (window as any).dataLayer;
    if (dataLayer) {
      dataLayer.push({
        event: 'page_view',
        page_path: pagePath,
        page_title: title || document.title,
      });
      console.log(`Pageview sent: ${pagePath}`);
    } else {
      console.error('GTM not initialized or dataLayer is unavailable.');
    }
  };

  if (typeof window !== 'undefined' && (window as any).dataLayer) {
    pushEvent(); // Si ya está inicializado, enviar evento
  } else {
    console.log('Waiting for GTM initialization...');
    document.addEventListener('gtm_initialized', pushEvent, {once: true}); // Esperar a GTM
  }
};
