import {makeAutoObservable} from 'mobx';
import LibraryRepository from '../../../data/repositories/LibraryRepository';
import {
  MEDIA_FILES_ALL,
  MediaFile,
} from '../../../data/models/LibraryFile/MediaFile';
import {toast} from 'react-toastify';
import {getErrorFromCode} from '../../../data/errorCodes';
import {toastConfig} from '../../../uiToolkit/Toast/toastConfig';
import SubscriptionRepository from '../../../data/repositories/SubscriptionRepository';

class MyLibraryModalStore {
  private libraryRepo = new LibraryRepository();
  private subscriptionRepo = new SubscriptionRepository();

  private isLibraryShown = false;
  private sourceType?: number;
  private onFileCallback?: (files: MediaFile[]) => void;
  private selectMultiple = false;
  private selectedFiles: MediaFile[] = [];

  public isLoading = false;

  public get libraryShown() {
    return this.isLibraryShown;
  }

  public get canSelectMultiple() {
    return this.selectMultiple;
  }

  public get preSelectedFiles() {
    return this.selectedFiles;
  }

  public getStorageLimit = async (organizationId: string) => {
    const result =
      await this.subscriptionRepo.getSubscriptionPermissions(organizationId);
    if (result.success && result.data) {
      return result.data.storageLimit;
    }
    return 100;
  };

  public showLibrary(
    sourceType: number,
    onFileCallback: (files: MediaFile[]) => void,
    selectMultiple?: boolean,
    selectedFiles?: MediaFile[],
  ) {
    this.onFileCallback = onFileCallback;
    this.isLibraryShown = true;
    this.sourceType = sourceType;
    this.selectMultiple = selectMultiple !== undefined ? selectMultiple : false;
    this.selectedFiles = selectedFiles ? selectedFiles : [];
  }

  public getSourceType() {
    if (this.sourceType === undefined) {
      return MEDIA_FILES_ALL;
    }
    return this.sourceType;
  }

  public closeLibrary() {
    this.onFileCallback = undefined;
    this.isLibraryShown = false;
    this.selectMultiple = false;
    this.selectedFiles = [];
  }

  public onFileSelect(files: MediaFile[]) {
    if (this.onFileCallback) {
      this.onFileCallback(files);
      this.closeLibrary();
    }
  }

  public async uploadLibraryFile(
    organizationId: string,
    video: File,
    duration: number,
    targetRes: number,
  ) {
    this.isLoading = true;
    const result = await this.libraryRepo.uploadVideo(
      organizationId,
      video,
      duration,
      targetRes,
    );
    if (result.errors && result.errors[0]) {
      toast.error(getErrorFromCode(result.errors[0]), toastConfig);
    }
    //Specific server error for file size
    if (result.errors && result.errors[0] && (result.errors[0] as any).errors) {
      toast.error(getErrorFromCode('file_too_large'), toastConfig);
    }

    this.isLoading = false;
    return result.success;
  }

  public async getMediaFiles(
    page: number,
    organizationId: string,
    pageSize: number,
    orderBy: string,
    search: string,
    selectedFilter?: number,
    orderAsc?: boolean,
  ) {
    if (this.sourceType === undefined) {
      return {
        data: [],
        pagesCount: 0,
      };
    }

    this.isLoading = true;
    const result = await this.libraryRepo.getMediaFiles(
      page,
      organizationId,
      pageSize,
      orderBy,
      selectedFilter,
      search,
      orderAsc,
    );
    this.isLoading = false;
    if (result.success && result.data) {
      return result.data;
    }
    return {
      data: [],
      pagesCount: 0,
    };
  }

  public async getMaxAllowedResolution(organizationId: string) {
    const result =
      await this.subscriptionRepo.getSubscriptionPermissions(organizationId);
    if (result.success && result.data) {
      return result.data.maxResolution;
    }
    return 0;
  }

  constructor() {
    makeAutoObservable(this);
  }
}

export default MyLibraryModalStore;
