import {getLocalizationBasedOnLang} from '../../../data/models/User/UserInfo';
import {APP_PERMISSIONS} from '../../../data/permissions';

export const navConfig = (id?: string) => [
  {
    icon: 'icon-home-line',
    title: getLocalizationBasedOnLang('Workspace', 'Gweithle'),
    route: `/manage/${id}/workspace`,
    activeIcon: 'Home',
    reqireSubscription: false,
    permission: APP_PERMISSIONS.ORG_ACCESS_DASHBOARD,
  },
  {
    icon: 'icon-ai_assistant_ic',
    title: getLocalizationBasedOnLang('Tapybl AI', 'Tapybl AI'),
    route: `/manage/${id}/ai`,
    activeIcon: 'Home',
    reqireSubscription: false,
    permission: APP_PERMISSIONS.ORG_ACCESS_DASHBOARD,
  },
  {
    icon: 'icon-laptop',
    title: getLocalizationBasedOnLang('Library', 'Llyfrgell'),
    route: `/manage/${id}/library`,
    activeIcon: 'Page',
    permission: APP_PERMISSIONS.ORG_ACCESS_LIBRARY,
    reqireSubscription: true,
  },
  {
    icon: 'icon-video-recorder',
    title: getLocalizationBasedOnLang('Courses', 'Cyrsiau'),
    route: `/manage/${id}/courses`,
    activeIcon: 'Course',
    permission: APP_PERMISSIONS.ORG_LIST_COURSES,
    reqireSubscription: true,
  },
  {
    icon: 'icon-users',
    title: getLocalizationBasedOnLang('Learners', 'Dysgwyr'),
    route: `/manage/${id}/learners`,
    activeIcon: 'Users',
    permission: APP_PERMISSIONS.ORG_LIST_LEARNERS,
    reqireSubscription: true,
  },

  {
    icon: 'icon-settings',
    title: getLocalizationBasedOnLang('Settings', 'Gosodiadau'),
    route: `/manage/${id}/settings`,
    activeIcon: 'Settings',
    reqireSubscription: false,
    permission: APP_PERMISSIONS.ORG_MANAGE_SETTINGS,
  },
];

export const helpConfig = {
  icon: 'icon-mail',
  title: getLocalizationBasedOnLang('Help & Tutorials', 'Cymorth a Thiwtorïau'),
  route: `https://help.tapybl.com/support/home`,
  activeIcon: 'Help',
};

export const contactConfig = {
  icon: 'icon-annotation-question',
  title: getLocalizationBasedOnLang('Contact us', 'Cysylltwch â Ni'),
  route: `https://help.tapybl.com/support/tickets/new?ticket_form=ask_a_question`,
  activeIcon: 'Mail',
};
