import React, {useEffect, useState} from 'react';
import {lessonEditorText} from '../../../lessonEditorText';
import {LessonContentChallengeBranchImageOption} from '../../../../../data/models/LessonContent/LessonContentBase';
import {
  MEDIA_FILES_IMAGE,
  MediaFile,
} from '../../../../../data/models/LibraryFile/MediaFile';
import BoxImagePreview from '../../../../../uiToolkit/BoxImagePreview';
import EditorOptionsFooter from '../../../../../uiToolkit/Editor/EditorOptionsFooter';
import YesNoOption from '../../../../../uiToolkit/Inputs/YesNoOption';
import {BaseContainerForwardProps} from '../../BaseOptionsContainer';
import ReplaceNode from '../../ReplaceNode';

interface Props extends BaseContainerForwardProps {
  entityToEdit: LessonContentChallengeBranchImageOption;
}

const ChallengeImageOptionOptionsUI = React.memo(
  ({
    entityToEdit,
    onEdit,
    isLoading,
    onDelete,
    isPublished,
    onOpenLibrary,
  }: Props) => {
    const [isCorrect, setIsCorrect] = useState(false);
    const [newFile, setNewFile] = useState<MediaFile>();

    const openLibrary = () => {
      onOpenLibrary(MEDIA_FILES_IMAGE, files => setNewFile(files[0]));
    };

    const onEditImage = () => {
      onEdit({
        ...entityToEdit,
        contentId: newFile
          ? newFile.storageFileName
          : (entityToEdit as LessonContentChallengeBranchImageOption)
              ?.contentId,
        isCorrect,
      } as LessonContentChallengeBranchImageOption);
    };

    useEffect(() => {
      setIsCorrect(entityToEdit?.isCorrect || false);
    }, [entityToEdit]);

    return (
      <div>
        <BoxImagePreview
          heading={lessonEditorText.source}
          source={newFile ? newFile.fileUrl : entityToEdit.contentUrl}
          actionTitle={lessonEditorText.editSource}
          action={openLibrary}
        />
        <YesNoOption
          value={isCorrect}
          onSelect={setIsCorrect}
          title={lessonEditorText.isCorrect}
          uiType="box"
          className="mt-4"
        />
        <ReplaceNode currentNode={entityToEdit} />
        <EditorOptionsFooter
          onSave={onEditImage}
          saveEnabled={
            newFile !== undefined || entityToEdit?.isCorrect !== isCorrect
          }
          onDelete={onDelete}
          isLoading={isLoading}
          deleteDisabled={isPublished}
        />
      </div>
    );
  },
);

export default ChallengeImageOptionOptionsUI;
