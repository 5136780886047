import React from 'react';
import {generateAiAssistantText} from '../generateAiAssisntantText';

interface Props {
  value: string;
  setValue: (value: string) => void;
}

const TitleInput = React.memo(({value, setValue}: Props) => {
  return (
    <div className="AiAssitantTitleInput">
      <span className="TitleStar">*</span>
      <input
        value={value}
        onChange={e => setValue(e.target.value)}
        className="TitleInput"
        placeholder={generateAiAssistantText.titlePlaceholder}
      />
    </div>
  );
});

export default TitleInput;
