import React from 'react';
import {dashboardText} from '../dashboardText';
import PageTitle from '../../../uiToolkit/PageTitle';
import './styles.css';
import {
  aIassistantSmallIc,
  folderIcEmpty,
  homeActive,
  subscriptionExpiredImage,
} from '../../../assets/images';
import DashboardCard from '../../../uiToolkit/DashboardCard';
import TrackerDashboardCard from '../component/TrackerDashboardCard';
import DashboardCardSlim from '../../../uiToolkit/DashboardCardSlim';
import Button from '../../../uiToolkit/Buttons/Button';
import MeterDashboardCard from '../component/MeterDashboardCard';
import {DashboardData} from '../../../data/models/Workspace/Dashboard';
import AiActionsUsedCard from '../component/AiActionsUsedCard';
import {
  LTI_SUB_FEATURE,
  organizationHasFeature,
  WHITE_LABEL_CUSTOMIZATION,
} from '../../../data/models/Subscription/SubscriptionPermission';

interface Props {
  dashboardData?: DashboardData;
  onSubscribe: () => void;
  onStartTrial: () => void;
  navigateCustomizeWorkspaceBranding: () => void;
  navigateManageAccount: () => void;
  navigateManageIntegrations: () => void;
  hasSubscription: boolean;
  usedTrial: boolean;
  isLoading: boolean;
  hasAdminPermissions: boolean;
  subscriptionPermissions: any;
  onContactUs: () => void;
  onBuyAddons: () => void;
}

const WorkspaceUI = React.memo(
  ({
    dashboardData,
    onSubscribe,
    navigateCustomizeWorkspaceBranding,
    navigateManageAccount,
    navigateManageIntegrations,
    hasAdminPermissions,
    hasSubscription,
    isLoading,
    onBuyAddons,
    subscriptionPermissions,
  }: Props) => {
    const getContent = () => {
      if (!hasSubscription) {
        return (
          <div className="mt-4">
            <PageTitle title={dashboardText.title} />
            {hasAdminPermissions ? (
              <div className="SubscriptionStartContainer">
                <img src={subscriptionExpiredImage} />
                <h1>{dashboardText.subcriptionEnded}</h1>
                <p>{dashboardText.subscriptionEndedInfo}</p>
                <p>{dashboardText.subscriptionEndedInfo2}</p>

                <>
                  <span className="ClickToUpgrade">
                    {dashboardText.clickToSub}
                  </span>
                  <Button
                    title={dashboardText.upgradeNow}
                    onClick={onSubscribe}
                    isLoading={isLoading}
                    className="StartTrialButton"
                  />
                </>
              </div>
            ) : (
              <div className="SubscriptionStartContainer">
                <span>{dashboardText.subscriptionExpired}</span>
              </div>
            )}
          </div>
        );
      }
      if (dashboardData) {
        return (
          <div>
            <div className="CoursesListHeader">
              <PageTitle title={dashboardText.title} />
            </div>
            <div className="DashboardContainer">
              <DashboardCard
                title={dashboardText.totalLearners}
                icon={'icon-typeUsers'}
                element={
                  <TrackerDashboardCard
                    data1={(
                      dashboardData.learnerNumbers +
                      dashboardData.instructorNumbers
                    ).toString()}
                    data2={dashboardData.maxLearnersAvailable.toString()}
                  />
                }
                buttonText={dashboardText.buyMoreUsers}
                onClick={onBuyAddons}
              />

              <DashboardCard
                title={dashboardText.totalAuthors}
                icon={'icon-typePage'}
                element={
                  <TrackerDashboardCard
                    data1={dashboardData.amountOfCreators.toString()}
                    data2={dashboardData.maxAmountOfCreators.toString()}
                  />
                }
                buttonText={dashboardText.buyMoreUsers}
                onClick={onBuyAddons}
              />

              <DashboardCard
                title={dashboardText.totalCourses}
                icon={'icon-typePage'}
                element={
                  <TrackerDashboardCard
                    data1={dashboardData.courseNumbers.toString()}
                  />
                }
              />

              {dashboardData.maxAmountOfCourses != -1 && (
                <DashboardCard
                  title={dashboardText.totalCreatedCourses}
                  icon={'icon-typePage'}
                  element={
                    <TrackerDashboardCard
                      data1={dashboardData.amountOfCourses.toString()}
                      data2={dashboardData.maxAmountOfCourses.toString()}
                    />
                  }
                  buttonText={dashboardText.getUnlimitedCourses}
                  onClick={onBuyAddons}
                />
              )}
            </div>
            <div className="DashboardContainer">
              <DashboardCard
                title={dashboardText.aiActionsUsed}
                img={aIassistantSmallIc}
                buttonText={dashboardText.upgradeForMoreActions}
                onClick={onBuyAddons}
                element={
                  <AiActionsUsedCard
                    actionsAvailable={dashboardData.aiCreditsAvailable}
                  />
                }
              />

              <DashboardCard
                title={dashboardText.spaceQuota}
                img={folderIcEmpty}
                buttonText={dashboardText.buyMoreSpace}
                onClick={onBuyAddons}
                element={
                  <MeterDashboardCard
                    upperLimit={dashboardData.maxStorageSpaceAvailable / 1000}
                    lowerLimit={0}
                    currentCount={
                      dashboardData.storageSpaceUsed >
                      dashboardData.maxStorageSpaceAvailable
                        ? dashboardData.maxStorageSpaceAvailable / 1000
                        : dashboardData.storageSpaceUsed / 1000
                    }
                  />
                }
              />
            </div>

            <div className="SlimDashboardContainer">
              <h1>{dashboardText.quickLinks}</h1>
              {organizationHasFeature(
                subscriptionPermissions,
                WHITE_LABEL_CUSTOMIZATION,
              ) && (
                <DashboardCardSlim
                  title={dashboardText.customizeWorkspaceBranding}
                  icon="icon-typeEdit-with-square"
                  onClick={navigateCustomizeWorkspaceBranding}
                />
              )}

              <DashboardCardSlim
                title={dashboardText.manageAccount}
                icon="icon-typeProfile"
                onClick={navigateManageAccount}
              />
              {organizationHasFeature(
                subscriptionPermissions,
                LTI_SUB_FEATURE,
              ) && (
                <DashboardCardSlim
                  title={dashboardText.manageIntegrations}
                  icon="icon-typeSettings"
                  onClick={navigateManageIntegrations}
                />
              )}
              <DashboardCardSlim
                title={dashboardText.manageIntegrations}
                icon="icon-typeSettings"
                onClick={onSubscribe}
                element={
                  <Button
                    title={dashboardText.upgrade}
                    uiType="secondary"
                    onClick={onSubscribe}
                  />
                }
              />
            </div>
          </div>
        );
      }
      return (
        <div className="CoursesListHeader">
          <PageTitle title={dashboardText.title} icon={homeActive} />
        </div>
      );
    };

    return getContent();
  },
);

export default WorkspaceUI;
