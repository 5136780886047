import React, {useEffect, useState} from 'react';
import './styles.css';

interface SelectOption {
  key: string;
  value: string;
}

interface ToggleSwitchProps {
  options: SelectOption[];
  selectedKey?: string;
  selectedOption?: string;
  setSelectedOption?: (value: string) => void; // Updated to be a function
  onChange?: (key: string) => void;
  disabled?: boolean;
}

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
  options,
  selectedKey,
  setSelectedOption,
  onChange,
  disabled,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    const index = options.findIndex(option => option.key === selectedKey);
    if (index !== -1) {
      setSelectedIndex(index);
      setSelectedOption?.(options[index].value); // Use options[index].value here
    }
  }, [selectedKey, options, setSelectedOption]);

  const handleClick = (index: number) => {
    setSelectedIndex(index);
    const selectedKey = options[index].key;
    const selectedValue = options[index].value;
    if (onChange) onChange(selectedKey);
    setSelectedOption?.(selectedValue); // Call setSelectedOption with the value
  };

  return (
    <div className="ToggleSwitch_Container">
      {options.map((option, index) => (
        <React.Fragment key={option.key}>
          <div
            className={`ToggleSwitch_Button ${
              disabled ? 'ToggleDisabled' : ''
            } ${selectedIndex === index ? 'selected' : ''} `}
            onClick={disabled ? undefined : () => handleClick(index)}>
            {option.value}
          </div>
        </React.Fragment>
      ))}
      <div
        className="ToggleSwitch_Indicator"
        style={{
          width: `${100 / options.length}%`, // Corrected to use `options.length`
          left: `${(100 / options.length) * selectedIndex}%`,
        }}></div>
    </div>
  );
};

export default ToggleSwitch;
