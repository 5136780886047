import {makeAutoObservable} from 'mobx';
import {AIRepository} from '../../../data/repositories/AiRepository';

class LearnerAiAssistantsStore {
  private aiRepository = new AIRepository();

  constructor() {
    makeAutoObservable(this);
  }

  public async getMyAiAssistants(courseId?: string) {
    const result = await this.aiRepository.getMyAiAssitants(courseId);
    if (result.success && result.data) {
      return result.data;
    }
    return [];
  }
}

export default LearnerAiAssistantsStore;
