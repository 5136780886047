import React, {useEffect, useState} from 'react';
import StaffEditUI from '../view/StaffEditUI';
import {useNavigate, useParams} from 'react-router-dom';
import {inject, observer} from 'mobx-react';
import {Role} from '../../../../data/models/Oganization/Role';
import OrganizationStaffStore from '../../store/OrganizationStaffStore';
import {StaffRecord} from '../../../../data/models/Oganization/StaffRecord';
import ConfirmationModal from '../../../../uiToolkit/ConfirmationModal';
import {settingsText} from '../../settingsText';
import {toast} from 'react-toastify';
import {toastConfig} from '../../../../uiToolkit/Toast/toastConfig';

interface Props {
  organizationStaffStore?: OrganizationStaffStore;
}

const StaffEditContainer = inject('organizationStaffStore')(
  observer(({organizationStaffStore}: Props) => {
    const [roles, setRoles] = useState<Role[]>([]);
    const [member, setMember] = useState<StaffRecord>();
    const [newRoleId, setNewRoleId] = useState<string>();
    const {organizationId, memberId} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
      if (organizationId && memberId) {
        organizationStaffStore
          ?.getOrganizationRoles(organizationId)
          .then(roles => {
            setRoles(roles.filter(x => x.roleName !== 'Workspace Instructor'));
          });
        organizationStaffStore!
          .getStaffMember(organizationId, memberId)
          .then(setMember);
      }
    }, []);

    const onCancel = () => {
      navigate(`/manage/${organizationId}/settings/users`);
    };

    const onEditMember = async () => {
      if (member && member.staffMemberId && organizationId && newRoleId) {
        const success = await organizationStaffStore!.editStaffMemeber(
          organizationId,
          member.staffMemberId,
          newRoleId,
        );
        setNewRoleId(undefined);
        if (success) {
          toast.success(settingsText.editRoleSuccess, toastConfig);
          onCancel();
        }
      }
    };

    const onInitiateEdit = (roleId: string) => {
      setNewRoleId(roleId);
    };

    return member ? (
      <>
        <StaffEditUI
          onCancel={onCancel}
          roles={roles}
          isLoading={organizationStaffStore!.isLoading}
          member={member}
          onSubmit={onInitiateEdit}
        />
        <ConfirmationModal
          shown={newRoleId !== undefined}
          title={settingsText.editConfirmation}
          onCancel={() => setNewRoleId(undefined)}
          onAccept={onEditMember}
        />
      </>
    ) : null;
  }),
);

export default StaffEditContainer;
