import {getLocalizationBasedOnLang} from '../../../data/models/User/UserInfo';

const english = {
  title: 'Create new AI video',
  text: 'Generate AI Videos',
  nextStep: 'Next Step',
  skipStep: 'Skip step',
  exceptionsTitle: 'Exceptions',
  slideIgnoreAudioHeadingText: 'Slides or Pages to Silence',
  slideIgnoreAudioPlaceholderText:
    'Enter slide/page numbers (e.g., 1,2,3 or 4-6)',
  slideIgnoreHeadingText: 'Slides or Pages to Exclude',
  slideIgnorePlaceholderText: 'Enter slide/page numbers (e.g., 1,2,3 or 4-6)',
  textIgnoreHeadingText: 'Exclude Text from Audio',
  textIgnorePlaceholder: 'Enter all text to exclude, separated by commas',
  videoDurationTitle: 'Video duration',
  videoDurationLabel: 'Choose your video duration',
  contextLabel: 'Add context for narration (Optional)',
  generateImagesTitle: 'AI Media',
  aiImageNone: 'No',
  aiImageFew: 'A few',
  aiImageSome: 'Some',
  aiImageMostly: 'Mostly',
  aiImageEntirely: 'Entirely',
  generateImagesLabel:
    'Do you want to include AI-generated images in your final video?',
  imageStyleLabel: 'Choose an image style',
  generateVideosTitle: 'Include AI-Generated Videos',
  uploadPPTText: 'Upload your file',
  successMessage:
    'AI Video is being generated. You will be notified once it is ready.',
  uploadPPTSubtitle: 'Accepted formats: pdf, docx, pptx',
  uploadPPTInfo1: 'Bring your content to life.',
  uploadPPTInfo2: ' Select your file below and start your video journey.',
  narrationTitle: 'Customize voice narration',
  voiceGenderHeading: 'Select the style and the tone of the narrator',
  languageDropdownHeading:
    'Select the language and the dialect of the narrator',
  slideIgnoreAudioDurationTitle: 'Set display duration for muted slides',
  narrationGenderLabel: 'Select the voice of the narrator',
  generateVideo: 'Generate video',
  slideIgnoreAudioDurationWarning:
    'The duration must not exceed a limit of 10 minutes (00:10:00).',
  summaryReady: 'We’re ready to generate your video.',
  summaryInfo:
    'Once started, this action cannot be canceled. Video generation may take some time, depending on the length of your uploaded file and the selected options. The final video will be available in your library when the process is complete.',
  summary: 'Summary',
  summaryFile: 'File: ',
  summaryExceptions: 'Exceptions: ',
  summaryVideoDuration: 'Video Duration: ',
  summaryMedia: 'AI Media: ',
  summaryNarration: 'Narration: ',
  summaryRatio: ' ratio',
  summaryNoImages: 'No Images',
  summaryMale: 'Male',
  summaryFemale: 'Female',
  summaryExclude: 'Exclude pages ',
  summaryMute: 'Mute pages ',
  summaryExcludePhrases: 'Exclude phrases',
  summaryFor: ' for ',
  summaryAvailableCredits: 'Available Credits: ',
  summaryEstimatedCost: 'Estimated Credit Cost: ',
  summaryTone: 'Style & Tone: ',
  exit: 'Cancel',
  sliderLeft: 'AI Images',
  sliderRight: 'Your content',
  previousStep: 'Previous Step',
  helpTipFile1: 'Upload one file.',
  helpTipFile2: '75 slides limit for pptx',
  helpTipFile3: '75 page limit for pdf or docx',
  helpTipFile4: 'Larger files will result in longer videos',
  helpTipFile5:
    "If you'd like to include AI images, please upload a landscape-oriented file.",
  helpTipExclude1:
    'If there are specific pages, slides, or sections you’d like to exclude or modify in your final video, let us know here:',
  helpTipExclude2:
    'A) Slides or pages to silence: List any slides or pages, such as diagrams or image-only content, that you’d like to display visually without narration.',
  helpTipExclude3:
    'B) Slides or pages to exclude: Specify slide or page numbers you want completely removed from your video. These will not appear visually, and their content will not be narrated—essentially, think of this as deleting them from the final presentation.',
  helpTipExclude4:
    'C) Exclude text from audio: Provide details of any text, such as document authors, headers, or footers, that you’d like excluded from narration. The narrator will skip over these in the final video.',
  helpTipDuration:
    'Select "Short" for minimal narration, "Average" for standard detail, or "Long" for in-depth commentary on each slide. ',
  helpTipDuration2:
    'For videos containing math equations or formulas, we recommend choosing "Average" or "Long" to provide the best experience for learners.',
  helpTipDuration3:
    'Let tapybl AI contextualize based on your presentation, or upload relevant documents to provide additional guidance for the AI.',
  helpTipImageGen1:
    'Note: This section is experimental and can give unexpected results. Proceed with caution. You will be able to remove unwanted images using the timeline editor, but doing so will also remove your slide or document content from that portion of the video.',
  helpTipImageGen2:
    'Select an option to adjust how much of your video includes AI-generated images relative to your slide or document content:',
  helpTipImageGen3: '0%: Only your content is shown (no AI images).',
  helpTipImageGen4: '25%: A few AI images are included.',
  helpTipImageGen5: '50%: An equal mix of your content and AI images.',
  helpTipImageGen6: '75%: Mostly AI images with some of your content.',
  helpTipImageGen7: '100%: Only AI images are shown.',
  helpTipNarration:
    'Select the narrator’s voice, language, and dialect from the available options for your video.',
  helpTipNarration2:
    'Note: Translations impact the audio of your generated video, and not the visual elements.',
  helpTipSummary: 'This action costs AI credits',
  helpTipSummary2: 'Once you begin, the action cannot be canceled.',
  helpTipSummary3: 'Your credits will be updated after the video is generated.',
  helpTitle: 'Tapybl tips',
  browse: 'Browse',
  fileOrientationWarning:
    'Note: AI images are only available for landscape-oriented files. This option is unavailable for your current upload. Please use a landscape-oriented file to enable AI images.',
};

const welsh = {
  ModalHeader: 'Cynhyrchydd Fideo AI',
  text: 'Cynhyrchwch fideos AI',
  nextStep: 'Cam Nesaf',
  skipStep: 'Can Blaenorol',
  slideIgnoreAudioHeadingText: 'Sleidiau neu Dudalennau i Dawelu',
  slideIgnoreAudioPlaceholderText:
    'Nodwch rifau sleidiau neu dudalennau wedi’u separo gan gommasau, fel 1, 2, 4-6, 8.',
  slideIgnoreHeadingText: 'Sleidiau neu Dudalennau i Eithrio',
  slideIgnorePlaceholderText:
    'Nodwch rifau sleidiau neu dudalennau wedi’u separo gan gommasau, fel 1, 2, 4-6, 8.',
  textIgnoreHeadingText: "Eithrio Testun o'r Sain",
  textIgnorePlaceholder:
    'Nodwch yr holl frawddegau i eithrio, wedi’u separo gan gommasau',
  videoDurationTitle: 'Dewiswch hyd eich fideo',
  generateImagesTitle: 'Ychwanegwch Delweddau a Grëwyd gan AI',
  sliderHeading: 'Cyfran cynnwys i ddelwedd',
  uploadPPTText: 'Ychwanegwch eich ffeil',
  successMessage:
    'Mae fideo AI yn cael ei gynhyrchu. Byddwch yn cael gwybod unwaith y bydd yn barod',
  uploadPPTSubtitle: 'Fformatau a dderbynnir: pdf, docx, pptx',
  voiceGenderHeading: 'Dewiswch Llais y Llenor',
  languageDropdownHeading: 'Dewiswch Iath y LLenor',
  dialectDropdownHeading: 'Dewiswch Ddiwyliant y Llenor',
  narrationCustomization: 'Addasu Adrodd Llais',
  slideIgnoreAudioDurationTitle: 'Dewiswch y Hyd ar gyfer Sleidiau i Daweithio',
  generateVideo: 'Cynhyrchwch fideo',
  slideIgnoreAudioDurationWarning:
    "Ni ddylai'r hyd fod yn fwy na 10 munud (00:10:00)",
  summaryReady: 'Rydym yn barod i gynhyrchu eich fideo',
  summaryInfo:
    'Ni ellir dychwelyd yr weithred hon. Sylwch y gall gynhyrchu fideo gymryd amser, yn dibynnu ar hyd eich ffeil a ychwanegwyd a’r dewisiadau cynhyrchu. Byddwch yn gallu gweld y fideo terfynol yn eich llyfrgell unwaith y bydd wedi gorffen',
  summary: 'Crynodeb',
  summaryFile: 'Ffeil:',
  summaryExceptions: 'Eithriadau:',
  summaryVideoDuration: 'Hyd y Fideo:',
  summaryMedia: 'Cyfryngau AI:',
  summaryNarration: 'Adrodd:',
  summaryRatio: 'Cyfran',
  summaryNoImages: 'Dim Delweddau',
  summaryExclude: 'Eithrwch tudalennau',
  summaryMute: 'Tawelwch tudalennau',
  summaryExcludePhrases: 'Eithrwch frawddeg',
  summaryFor: 'ar gyfer',
  exit: 'Canslo',
  sliderLeft: 'Delwaddau AI',
  sliderRight: 'Eich cynnwys',
  previousStep: 'Cam Blaenorol',
  helpTipFile1: 'Ychwanegwch 1 ffeil',
  helpTipFile2: 'Terfyn 75 sleid ar gyfer pptx',
  helpTipFile3: 'Terfyn 75 tudalen ar gyfer pdf neu docx',
  helpTipFile4: 'Bydd ffeiliau mwy yn arwain at fideos hwy.',
  helpTipExclude1:
    'Os oes gennych gynnwys tudalen neu sleid, neu rannau cyfan yr hoffech eu cadw rhag cael eu harddangos yn eich fideo terfynol, gadewch i ni wybod yma:',
  helpTipExclude2:
    "A) Sleidiau neu Dudalennau i Dawelu: Os oes gennych dudalennau o ddiagramau neu sleidiau sy'n cynnwys dim ond delweddau yr hoffech eu harddangos yn weledol heb unrhyw adrodd, rhestrwch nhw ym.",
  helpTipExclude3:
    "B) Sleidiau neu Dudalennau i Eithrio: y rhifau sleid neu dudalen i'w heithrio o'ch cynnwys fideo a gynhyrchir. Ni fyddant yn ymddangos yn weledol, ac ni fydd eu cynnwys yn cael ei adrodd. Meddyliwch am hyn fel tudalennau yr hoffech eu dileu o'ch dogfen/pwyslais.",
  helpTipExclude4:
    'Eithrio Testun o’r Sain: unrhyw destun, megis awduron dogfen, pennawdau neu droednodau, i’w heithrio o’r adrodd. Bydd y darllenydd yn mynd heibio iddynt yn y fideo a gynhyrchir.',
  helpTipDuration:
    "Dewiswch 'Fer' ar gyfer adrodd lleiaf, 'Cyfartalog' ar gyfer manylion safonol, neu 'Hir' ar gyfer sylwebaeth fanwl ar bob sleid",
  helpTipImageGen1:
    'Nodyn: Mae’r adran hon yn arbrofol a gall roi canlyniadau annisgwyl. Parhewch gyda gofal. Byddwch yn gallu dileu delweddau annymunol gan ddefnyddio’r golygydd amserlinell, ond bydd gwneud hynny hefyd yn dileu cynnwys eich sleid neu ddogfen o’r rhan honno o’r fideo.',
  helpTipImageGen2:
    "Symudwch y llithrydd i ddewis hyd y cyfnod y bydd y delweddau'n cael eu harddangos mewn perthynas â chynnwys y sleid neu'r ddogfen. Bydd dewis 100 yn dangos yn unig eich cynnwys, tra bydd 0 yn dangos yn unig ddelweddau AI i'r gwylwyr.",
  helpTipNarration:
    'Dewiswch lais y darllenydd, y iaith, a’r naws o’r dewisiadau sydd ar gael ar gyfer eich fideo',
  helpTitle: 'Cynigion tapybl',
  helpTipNarration2:
    'Nodyn: Mae cyfieithiadau yn effeithio ar sain eich fideo a gynhyrchir, ac nid ar yr elfennau gweledol',
  helpTipSummary:
    "Mae'r weithred hon yn costio credydau AI. Bydd eich credydau'n cael eu diweddaru ar ôl cynhyrchu'r fideo.",
};

export const generateAIVideosText: any = getLocalizationBasedOnLang(
  english,
  welsh,
);
