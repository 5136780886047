import React from 'react';
import {
  getFileExtensionByFileType,
  getIconByFileType,
  MediaFile,
} from '../../../../data/models/LibraryFile/MediaFile';
import {isImageFile} from '../../../../data/models/LibraryFile/VideoSourceShort';
import {logoTextWhite} from '../../../../assets/images';
import {formatDateTime} from '../../../../utils/notificationTimeTextConvertor';
import LibraryFileOptions from './LibraryFileOptions';
import Tooltip from '../../../../uiToolkit/Tooltip';
import FileStatusDisplay from './FileStatusDisplay';

interface Props {
  file: MediaFile;
  onPreview: (file: MediaFile) => void;
  onEditVideo: (file: MediaFile) => void;
  onDownloadTranscript: (id: string) => Promise<any>;
  onDownloadVideo: (file: MediaFile) => Promise<any>;
  onDelete: (id: string) => void;
}

const LibraryFileCard = React.memo(
  ({
    file,
    onPreview,
    onEditVideo,
    onDownloadTranscript,
    onDownloadVideo,
    onDelete,
  }: Props) => {
    return (
      <div className="LibraryFileCardContainer">
        <div className="LibraryFileCard">
          <div className="ImageContainer">
            {file.fileStatus !== 0 ? (
              <span className="icon-ai_video_icon" />
            ) : isImageFile(file.fileType) ? (
              file.fileUrl && <img src={file.fileUrl} />
            ) : file.placeholderUrl ? (
              <img src={file.placeholderUrl} />
            ) : (
              <img src={logoTextWhite} className="PlaceholderLogo" />
            )}
          </div>
          <div className="InfoContainer">
            <div>
              <Tooltip tooltipText={file.fileName}>
                <p>{file.fileName}</p>
              </Tooltip>
              <span className="DateUploaded">
                {file.fileStatus === 0
                  ? formatDateTime(file.dateCreated.toString())
                  : null}
              </span>
            </div>
            {file.fileStatus !== 0 ? (
              <FileStatusDisplay status={file.fileStatus || 0} />
            ) : null}
            {file.fileStatus === 0 ? (
              <div className="Footer">
                <div className="d-flex align-items-center">
                  <span
                    className={`${getIconByFileType(
                      file,
                    )} TypeIndicator`}></span>
                  <span>{getFileExtensionByFileType(file)}</span>
                </div>
                <span>{file.fileSize} MB</span>
              </div>
            ) : null}
          </div>
          {file.fileStatus === 0 ? (
            <div className="FileOptionsPosition">
              <LibraryFileOptions
                file={file}
                onPreview={onPreview}
                onEditVideo={onEditVideo}
                onDownloadTranscript={onDownloadTranscript}
                onDownloadVideo={onDownloadVideo}
                onDelete={onDelete}
              />
            </div>
          ) : null}
        </div>
      </div>
    );
  },
);

export default LibraryFileCard;
