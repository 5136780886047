import React, {useEffect, useState} from 'react';
import VideoPlaybackUI from '../view/VideoPlaybackUI';
import {inject, observer} from 'mobx-react';
import VideoPlaybackStore from '../store/VideoPlaybackStore';
import {useNavigate, useParams} from 'react-router-dom';
import {LessonPresentation} from '../../../data/models/Course/LessonPresentation';
import {LessonLearner} from '../../../data/models/LessonContent/LessonLearner';
import {LearnerProgressModel} from '../../../data/models/LessonContent/CreateLearnerLessonModel';
import {parseLessonAnswers} from '../lessonAnswersParset';

interface Props {
  videoPlaybackStore?: VideoPlaybackStore;
  preview?: boolean;
}

const VideoPlaybackContainer = inject('videoPlaybackStore')(
  observer(({videoPlaybackStore, preview}: Props) => {
    const [lessonPresentation, setLessonPresentation] =
      useState<LessonPresentation>();
    const [finishedLessons, setFinishedLessons] = useState<string[]>([]);
    const [nextLessonId, setNextLessonId] = useState<string>();

    const [progress, setProgress] = useState<LearnerProgressModel>();
    const [lessonLearner, setLessonLearner] = useState<LessonLearner>();

    const navigate = useNavigate();
    const {courseId, lessonId, organizationId} = useParams();

    useEffect(() => {
      initializaData();
    }, [videoPlaybackStore, lessonId]);

    useEffect(() => {
      if (lessonPresentation && courseId && !preview) {
        videoPlaybackStore?.getLearnerCourseProgress(courseId).then(res => {
          if (
            res?.nextLessonId &&
            res?.nextLessonId ===
              lessonPresentation?.currentLesson.supplementalLessonId
          ) {
            navigateToSupplemental(res?.nextLessonId || '');
          }
        });
      }
    }, [lessonPresentation]);

    const initializaData = async () => {
      setLessonPresentation(undefined);
      setLessonLearner(undefined);
      setProgress(undefined);
      setNextLessonId(undefined);
      if (courseId && lessonId) {
        if (preview && organizationId) {
          await videoPlaybackStore!
            .getLessonPreview(organizationId, courseId, lessonId)
            .then(setLessonPresentation);
        } else {
          await videoPlaybackStore!
            .getLessonPresentation(courseId, lessonId)
            .then(setLessonPresentation);
        }
        await videoPlaybackStore
          ?.getLearnerProgress(courseId, lessonId)
          .then(res => {
            setLessonLearner(res?.lessonLearner);
            setFinishedLessons(res?.finishedLessonIdsInCourse || []);
            setProgress(res?.progress);
          });
      }
    };

    useEffect(() => {
      if (!preview && courseId && lessonId) {
        videoPlaybackStore!.createLessonLearner(courseId, {
          lessonId: lessonId,
        });
      }
    }, [finishedLessons]);

    const onFinishedLesson = (body: LearnerProgressModel) => {
      if (courseId && lessonId) {
        videoPlaybackStore
          ?.editLessonLearnerProgress(courseId, lessonId, body)
          .then(res => {
            setNextLessonId(res?.nextLessonId);
            setProgress(res?.progress);
            setLessonLearner(res?.lessonLearner);
          });
      }
    };

    const onBack = () => {
      navigate(-1);
    };

    const getGradesResult = () => {
      if (
        progress &&
        lessonLearner &&
        lessonPresentation &&
        lessonPresentation.currentLesson.isGradable &&
        lessonPresentation.currentLesson.passingScore !== undefined
      ) {
        const grade = parseLessonAnswers(
          progress,
          lessonPresentation.lessonContent,
          lessonLearner,
          lessonPresentation.currentLesson.passingScore,
        );
        return grade;
      }
      return undefined;
    };

    const onContinue = () => {
      if (preview) {
        navigate(-1);
      } else if (nextLessonId) {
        if (nextLessonId === lessonId) {
          window.location.reload();
        } else {
          navigate(`/course/${courseId}/lesson/${nextLessonId}`, {
            replace: true,
          });
        }
      }
    };

    const navigateToSupplemental = (supplementalLessonId: string) => {
      navigate(`/course/${courseId}/lesson/${supplementalLessonId}`, {
        replace: true,
      });
    };

    const getPassingScore = () => {
      return lessonPresentation?.currentLesson?.passingScore;
    };

    const onCourseHome = () => {
      if (preview) {
        navigate(-1);
      } else {
        navigate(`/course/${courseId}`, {replace: true});
      }
    };

    const onOpenLesson = (lessonId: string) => {
      navigate(`/course/${courseId}/lesson/${lessonId}`);
    };

    const navigateToLesson = async (navigateToLessonId: string) => {
      if (preview) {
        navigate(
          `/preview/${organizationId}/course/${courseId}/lesson/${navigateToLessonId}`,
        );
      } else if (lessonPresentation?.organization) {
        const publishedId = await videoPlaybackStore!.getPublishedLessonId(
          lessonPresentation.organization.id,
          navigateToLessonId,
        );
        if (publishedId) {
          navigate(`/course/${courseId}/lesson/${publishedId}`);
        }
      }
    };

    return lessonPresentation ? (
      <VideoPlaybackUI
        presentation={lessonPresentation}
        currentLessonId={lessonId || ''}
        gradeResult={getGradesResult()}
        preview={preview}
        onBack={onBack}
        onContinue={onContinue}
        onCourseHome={onCourseHome}
        finishedLessons={finishedLessons}
        onOpenLesson={onOpenLesson}
        passingScore={getPassingScore()}
        isLoading={videoPlaybackStore?.isLoading}
        nextLessonId={nextLessonId}
        onFinishedLesson={onFinishedLesson}
        navigateToLesson={navigateToLesson}
      />
    ) : null;
  }),
);

export default VideoPlaybackContainer;
