import React, {useEffect, useState} from 'react';
import './styles.css';
import FormField from '../components/FormField';
import Button from '../../../../uiToolkit/Buttons/Button';
import SpecialBrowse from '../components/SpecialBrowse';
import {MyLibraryModal} from '../../../../modules/MyLibraryModal';
import {MediaFile} from '../../../../data/models/LibraryFile/MediaFile';
import AvatarLibrary from '../components/AvatarLibrary';
import TitleInput from '../components/TitleInput';
import {generateAiAssistantText} from '../generateAiAssisntantText';
import {
  AI_OPTION_DIRECT,
  AI_OPTION_INDIRECT,
  AiAssistantCommunicationOptions,
  AiAssistantResponseOptions,
} from '../../../../data/models/AI/AiAssisntantOptions';
import {
  AiAssistantEditModel,
  CreateAiAssistantModel,
} from '../../../../data/models/AI/AiAssistantModels';
import SearchDropdown from '../components/SearchDropdown';
import ToggleSwitch from '../../../../uiToolkit/Inputs/ToggleSwitch';
import Dropdown from '../../../../uiToolkit/Inputs/Dropdown';
import {CourseShortListModel} from '../../../../data/models/Course/CourseList';
import PageTitle from '../../../../uiToolkit/PageTitle';
import {tapyblAiHomeText} from '../../Home/tapyblAiHomeText';

interface Props {
  courseSearch: string;
  courses: any;
  setSearchText: (config: string) => void;
  onSave: (model: CreateAiAssistantModel, avatar?: File) => void;
  onCancel: any;
  isLoading: boolean;
  organizationAvatar?: string;
  editing?: boolean;
  assistant?: AiAssistantEditModel;
}

const GenerateAIAssistantUI = React.memo(
  ({
    courseSearch,
    setSearchText,
    courses,
    onSave,
    onCancel,
    isLoading,
    organizationAvatar,
    editing,
    assistant,
  }: Props) => {
    const [sourceFiles, setSourceFiles] = useState<MediaFile[]>([]);
    const [avatarFile, setAvatarFile] = useState<File>();
    const [existingAvatarFile, setExistingAvatarFile] = useState<string>();
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [scopeIds, setScopeIds] = useState<string[]>([]);
    const [responseStyle, setResponseStyle] =
      useState<string>(AI_OPTION_DIRECT);
    const [communicationTone, setCommunicationTone] = useState<string>('');

    useEffect(() => {
      if (assistant) {
        setTitle(assistant.title);
        setDescription(assistant.description || '');
        if (assistant.isGlobal) {
          setScopeIds(['workspace', ...assistant.scopes]);
        } else {
          setScopeIds(assistant.scopes);
        }
        setResponseStyle(
          assistant.directAnswer ? AI_OPTION_DIRECT : AI_OPTION_INDIRECT,
        );
        setExistingAvatarFile(assistant.imageUrl);
        setCommunicationTone(assistant.conversationalTone || '');
        setSourceFiles(assistant.files);
      }
    }, [assistant]);

    const handleSave = () => {
      const model: CreateAiAssistantModel = {
        title: title,
        description: description,
        conversationalTone: communicationTone,
        scopeIds: scopeIds,
        libraryMediaFileIds: sourceFiles.map(x => x.id),
        directAnswer: responseStyle === AI_OPTION_DIRECT,
      };
      onSave(model, avatarFile);
    };

    const searchableSelectOptions = courses.map(
      (course: CourseShortListModel) => ({
        key: course.id,
        value: course.title,
        thumb: course.avatarUrl,
      }),
    );

    const saveEnabled = () => {
      return title != '' && sourceFiles.length > 0;
    };

    return (
      <div className="GenerateAIAssistantContainer">
        <PageTitle
          title={tapyblAiHomeText.title}
          subtitle={tapyblAiHomeText.subtitle}
        />

        <main className="GenerateAIAssistantContent">
          <section className="GenerateAIAssistantFormHeader">
            <h1>
              {editing
                ? generateAiAssistantText.editTitle
                : generateAiAssistantText.createTitle}
            </h1>
          </section>

          <section className="GenerateAIAssistantFormBody">
            <div className="GenerateAIAssistantFormContent">
              <div className="GenerateAIAssistantFormInnerHeader">
                <AvatarLibrary
                  onFileSelected={setAvatarFile}
                  file={avatarFile}
                  existingFileUrl={existingAvatarFile}
                />
                <div className="GenerateAIAssistantTitleDesc">
                  <TitleInput value={title} setValue={setTitle} />
                  <textarea
                    name="GenerateAIAssistantDescriptionField"
                    value={description}
                    onChange={e => setDescription(e.target.value)}
                    placeholder={
                      generateAiAssistantText.descriptionPlaceholder
                    }></textarea>
                </div>
              </div>

              <SpecialBrowse
                onFilesSelected={setSourceFiles}
                sourceFiles={sourceFiles}
                disabled={editing}
              />

              <FormField
                label="Visibility"
                tooltip={generateAiAssistantText.visibilityTooltip}>
                <SearchDropdown
                  options={searchableSelectOptions}
                  selectedOptions={scopeIds}
                  setSelectedOptions={setScopeIds}
                  placeholder="Choose where your AI assistant is available."
                  fieldError={false}
                  className="SearchDropdown_Container"
                  onSearch={setSearchText}
                  search={courseSearch}
                  specialOption={{
                    key: 'workspace',
                    value: 'All workspace',
                    thumb: organizationAvatar,
                  }}
                />
              </FormField>
              <FormField
                label="Response style"
                tooltip={generateAiAssistantText.responseStyleTooltip}>
                <ToggleSwitch
                  options={AiAssistantResponseOptions}
                  onChange={setResponseStyle}
                  selectedKey={responseStyle}
                  disabled={editing}
                />
              </FormField>
              <FormField label="Communication tone">
                <Dropdown
                  options={AiAssistantCommunicationOptions}
                  selectedOption={communicationTone}
                  setSelectedOption={setCommunicationTone}
                  placeholder="Select Tone"
                  fieldError={false}
                  isMandatory
                  disabled={editing}
                />
              </FormField>
            </div>
            <div className="GenerateAIAssistantActionButtons">
              <Button title="Cancel" onClick={onCancel} uiType="text" />
              <Button
                title="Save"
                onClick={handleSave}
                uiType="action"
                isLoading={isLoading}
                disabled={!saveEnabled()}
              />
            </div>
          </section>
        </main>
        <MyLibraryModal />
      </div>
    );
  },
);

export default GenerateAIAssistantUI;
