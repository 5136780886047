import React from 'react';
import {
  getFileExtensionByFileType,
  getIconByFileType,
  MediaFile,
} from '../../../data/models/LibraryFile/MediaFile';
import {isImageFile} from '../../../data/models/LibraryFile/VideoSourceShort';
import {logoTextWhite} from '../../../assets/images';
import {formatDateTime} from '../../../utils/notificationTimeTextConvertor';

interface Props {
  file: MediaFile;
  onSelectFile: (file: MediaFile) => void;
  selected?: boolean;
}

const MediaLibraryCard = React.memo(({file, onSelectFile, selected}: Props) => {
  return (
    <div className="MediaLibraryCard" onClick={() => onSelectFile(file)}>
      <div className="Poster">
        {isImageFile(file.fileType) ? (
          file.fileUrl && <img src={file.fileUrl} />
        ) : file.placeholderUrl ? (
          <img src={file.placeholderUrl} />
        ) : (
          <img src={logoTextWhite} className="PlaceholderLogo" />
        )}
      </div>
      <div className="InfoContainer">
        <p>{file.fileName}</p>
        <div className="d-flex flex-row justify-content-between align-items-end">
          <div>
            <span className={`${getIconByFileType(file)} TypeIndicator`}></span>
            <span>{getFileExtensionByFileType(file)}</span>
          </div>
          <span>{formatDateTime(file.dateCreated.toString())}</span>
        </div>
      </div>
      {selected && (
        <div className="SelectedFileOverlay">
          <div className="CheckContainer">
            <span className="icon-check"></span>
          </div>
        </div>
      )}
    </div>
  );
});

export default MediaLibraryCard;
