import React from 'react';
import './styles.css';
import {appText} from '../../assets/text/appText';
import UploadVideoButton from '../Inputs/UploadVideoButton';
import {libraryEmptyState} from '../../assets/images';

interface Props {
  onVideoUpload?: (video: File) => void;
  isLoading?: boolean;
  type?: number;
}

const LibraryEmptyState = React.memo(
  ({onVideoUpload, isLoading, type}: Props) => {
    return (
      <div className="LibraryEmptyStateContainer">
        <img src={libraryEmptyState} />
        <p className="Title">{appText.noLibraryTitle}</p>
        <p className="Title">{appText.noLibrarySubtitle}</p>
        {onVideoUpload && (
          <UploadVideoButton
            onVideoSelected={onVideoUpload}
            isLoading={isLoading}
            type={type}
          />
        )}
      </div>
    );
  },
);

export default LibraryEmptyState;
