import React from 'react';
import {inject, observer} from 'mobx-react';
import {
  LessonContentOverlayVideo,
  LessonContentVimeoOverlayVideo,
} from '../../../../../data/models/LessonContent/LessonContentBase';
import {lessonEditorText} from '../../../lessonEditorText';
import AddVideoOverlayStore from '../../../../../modules/Editor/AddVideoOverlayWizard/store/AddVideoOverlayStore';
import VideoOverlayOptionsUI from '../view/VideoOverlayOptionsUI';
import {
  MEDIA_FILES_ALL_VIDEO,
  MediaFile,
} from '../../../../../data/models/LibraryFile/MediaFile';
import EditVideoOverlayStore from '../../../../../modules/Editor/AddVideoOverlayWizard/store/EditVideoOverlayStore';
import BaseOptionsContainer, {
  BaseContainerForwardProps,
  BaseContainerProps,
} from '../../BaseOptionsContainer';

interface Props extends BaseContainerForwardProps {
  entityToEdit?: LessonContentOverlayVideo | LessonContentVimeoOverlayVideo;
  addVideoOverlayStore?: AddVideoOverlayStore;
  editVideoOverlayStore?: EditVideoOverlayStore;
}

const VideoOverlayOptionsContainer = inject(
  'lessonEditorStore',
  'addVideoOverlayStore',
  'editVideoOverlayStore',
)(
  observer(
    ({
      entityToEdit,
      onChangesMade,
      addVideoOverlayStore,
      isPublished,
      lessonContent,
      isGradable,
      editVideoOverlayStore,
      onDelete,
      onSoftDelete,
      isLoading,
      onOpenLibrary,
      onEdit,
    }: Props) => {
      const openMediaLibrary = () => {
        onOpenLibrary(MEDIA_FILES_ALL_VIDEO, (files: MediaFile[]) => {
          if (files.length && lessonContent && entityToEdit) {
            editVideoOverlayStore!.openWizard(
              files[0],
              null,
              onChangesMade,
              lessonContent,
              isGradable,
              entityToEdit.internalId,
            );
          }
        });
      };

      const onEditWithSource = () => {
        if (lessonContent) {
          addVideoOverlayStore!.openWizard(
            '',
            null,
            null,
            onChangesMade,
            lessonContent,
            isGradable,
            entityToEdit,
          );
        }
      };

      const onSaveTitle = (title: string) => {
        onEdit({
          ...entityToEdit,
          title: title,
        } as LessonContentOverlayVideo);
      };

      return entityToEdit ? (
        <>
          <VideoOverlayOptionsUI
            videoContent={entityToEdit}
            onDelete={onDelete}
            onSoftDelete={onSoftDelete}
            isLoading={isLoading}
            onPickNewFile={openMediaLibrary}
            isPublished={isPublished}
            onEdit={onEditWithSource}
            onSave={onSaveTitle}
          />
        </>
      ) : null;
    },
  ),
);

export default React.memo((props: BaseContainerProps) => (
  <BaseOptionsContainer
    {...props}
    deleteMessage={lessonEditorText.areYouSureDeleteVideo}
    softDeleteMessage={lessonEditorText.areYouSureDeleteVideoSoft}>
    {forwardProps => (
      <VideoOverlayOptionsContainer
        {...forwardProps}
        entityToEdit={forwardProps.entityToEdit as any}
      />
    )}
  </BaseOptionsContainer>
));
