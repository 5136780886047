import {makeAutoObservable} from 'mobx';
import LibraryRepository from '../../../../data/repositories/LibraryRepository';
import {toast} from 'react-toastify';
import {toastConfig} from '../../../../uiToolkit/Toast/toastConfig';
import SubscriptionRepository from '../../../../data/repositories/SubscriptionRepository';
import LoadingModule from '../../../../modules/LoadingModule/LoadingModule';
import {videoSourcesText} from '../../videoSourcesText';
import {MediaFile} from '../../../../data/models/LibraryFile/MediaFile';

class VideoSourcesStore {
  private libraryRepo = new LibraryRepository();
  private subscriptionRepo = new SubscriptionRepository();
  private loadingModule = new LoadingModule();

  constructor() {
    makeAutoObservable(this);
  }

  public get isLoading() {
    return this.loadingModule.isLoading;
  }

  public getStorageLimit = async (organizationId: string) => {
    const result =
      await this.subscriptionRepo.getSubscriptionPermissions(organizationId);
    if (result.success && result.data) {
      return result.data.storageLimit;
    }
    return 100;
  };

  public fetchMediaFile = async (organizationId: string, contentId: string) => {
    const result = await this.libraryRepo.getByContentId(
      organizationId,
      contentId,
    );
    if (result.success && result.data) {
      return result.data;
    }
  };

  public async getMyVideoSources(
    page: number,
    organizationId: string,
    pageSize: number,
    filter: number,
    orderBy: string,
    search?: string,
    orderAsk?: boolean,
  ) {
    this.loadingModule.startLoading();
    const result = await this.libraryRepo.getMediaFiles(
      page,
      organizationId,
      pageSize,
      orderBy,
      filter,
      search,
      orderAsk,
    );
    this.loadingModule.endLoading();

    if (result.success && result.data) {
      return result.data;
    } else {
      return {
        data: [],
        pagesCount: 0,
      };
    }
  }

  public async getVimeoVideos(
    organizationId: string,
    page: number,
    pageSize: number,
  ) {
    this.loadingModule.startLoading();
    const result = await this.libraryRepo.getVimeoVideos(
      organizationId,
      page,
      pageSize,
    );
    this.loadingModule.endLoading();
    if (result.success && result.data) {
      return result.data;
    }
    return [];
  }

  public async deleteMediaFile(organizationId: string, mediaFileId: string) {
    this.loadingModule.startLoading();
    const result = await this.libraryRepo.deleteMediaFile(
      organizationId,
      mediaFileId,
    );
    this.loadingModule.endLoading();
    if (result.success) {
      toast.success(videoSourcesText.fileDeleted, toastConfig);
    }
    return result.success;
  }

  public async downloadTranscript(organizationId: string, mediaFileId: string) {
    this.loadingModule.startLoading();
    const result = await this.libraryRepo.downloadTranscript(
      organizationId,
      mediaFileId,
    );
    this.loadingModule.endLoading();
    if (result.success && result.data) {
      return result.data;
    }
    return [];
  }

  public downloadFile = async (file: MediaFile) => {
    if (!file.fileUrl) return;

    return fetch(file.fileUrl, {
      method: 'GET',
    })
      .then(resp => resp.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = file.fileName;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      });
  };
}

export default VideoSourcesStore;
