import AiAssitantsApi from '../api/AiAssistants';
import QuestionGenerationAPI from '../api/QuestionGenerationAPI';
import {CreateAiAssistantModel} from '../models/AI/AiAssistantModels';
import {VideoCostEstimationModel} from '../models/AI/VideoGenerationConfig';

export class AIRepository {
  private aiApi = new QuestionGenerationAPI();
  private aiAssistantsApi = new AiAssitantsApi();

  public async getVideoCostEstimation(
    organizationId: string,
    model: VideoCostEstimationModel,
  ) {
    return this.aiApi.getVideoCostEstimation(organizationId, model);
  }

  public async createAiAssistant(
    organizationId: string,
    model: CreateAiAssistantModel,
    file?: File,
  ) {
    const result = await this.aiApi.createAiAssistant(organizationId, model);
    if (result.success && file && result.data) {
      await this.aiApi.uploadAIAvatar(organizationId, file, result.data?.id);
    }
    return result;
  }

  public async editAiAssistant(
    organizationId: string,
    model: CreateAiAssistantModel,
    assistantId: string,
    file?: File,
  ) {
    const result = await this.aiApi.editAiAssistant(
      organizationId,
      model,
      assistantId,
    );
    if (result.success && file && result.data) {
      await this.aiApi.uploadAIAvatar(organizationId, file, result.data?.id);
    }
    return result;
  }

  public async getAiAssistantsList(
    organizationId: string,
    page: number,
    pageSize: number,
  ) {
    return this.aiApi.getAiAssistantsList(organizationId, page, pageSize);
  }

  public async fetchCoursesShortList(organizationId: string, search: string) {
    return this.aiApi.fetchCoursesShortList(organizationId, search);
  }

  public async toggleAiAssitantVisibility(
    organizationId: string,
    assistantId: string,
  ) {
    return this.aiApi.toggleAiAssitantVisibility(organizationId, assistantId);
  }

  public async deleteAiAssistnat(organizationId: string, assistantId: string) {
    return this.aiApi.deleteAiAssistnat(organizationId, assistantId);
  }

  public async sendAssistantChat(
    organizationId: string,
    assistantId: string,
    question: string,
  ) {
    return this.aiApi.sendAssistantChat(organizationId, assistantId, question);
  }

  public async getAssistantChat(organizationId: string, assistantId: string) {
    return this.aiApi.getAssistantChat(organizationId, assistantId);
  }

  public async getMyAiAssitants(courseId?: string) {
    return this.aiAssistantsApi.getMyAssistants(courseId);
  }

  public async getAssitantForEditing(
    organicationId: string,
    assistantId: string,
  ) {
    return this.aiAssistantsApi.getAssitantForEditing(
      organicationId,
      assistantId,
    );
  }
}
