/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import {OverlayTrigger} from 'react-bootstrap';
import './styles.css';
import {tooltipArrow} from '../../assets/images';

interface Props {
  text: string;
}

const TooltipQuestionMark = React.memo(({text}: Props) => {
  const renderCloud = () => (
    <div className="TooltipCloud" id={'d-t'} style={{position: 'fixed'}}>
      {text}
      <div className="ArrowPosition">
        <img src={tooltipArrow} />
      </div>
    </div>
  );

  return (
    <div className="QuestionMarkTooltip">
      {/**@ts-ignore */}
      <OverlayTrigger placement="bottom" overlay={renderCloud()}>
        <span className="icon-typeQuestion"></span>
      </OverlayTrigger>
    </div>
  );
});

export default TooltipQuestionMark;
