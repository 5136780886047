import React from 'react';
import {
  FILE_PICK_STEP,
  MUTE_EXCLUDE_STEP,
  VIDEO_DURATION_STEP,
  IMAGE_GENERATION_STEP,
  VOICE_NARRATION_STEP,
  SUMMARY_STEP,
} from '../config';
import {generateAIVideosText} from '../generateAIVideosText';

interface HelpTipProps {
  step: number;
}

const HelpTip: React.FC<HelpTipProps> = ({step}) => {
  const getHelpContentForStep = () => {
    switch (step) {
      case FILE_PICK_STEP:
        return (
          <div>
            <ul>
              <li>{generateAIVideosText.helpTipFile1}</li>
              <li>{generateAIVideosText.helpTipFile2}</li>
              <li>{generateAIVideosText.helpTipFile3}</li>
              <li>{generateAIVideosText.helpTipFile4}</li>
              <li>{generateAIVideosText.helpTipFile5}</li>
            </ul>
          </div>
        );
      case MUTE_EXCLUDE_STEP:
        return (
          <div>
            <p>{generateAIVideosText.helpTipExclude1}</p>
            <p>{generateAIVideosText.helpTipExclude2}</p>
            <p>{generateAIVideosText.helpTipExclude3}</p>
            <p>{generateAIVideosText.helpTipExclude4}</p>
          </div>
        );
      case VIDEO_DURATION_STEP:
        return (
          <div>
            <p>{generateAIVideosText.helpTipDuration}</p>
            <p>{generateAIVideosText.helpTipDuration2}</p>
            <p>{generateAIVideosText.helpTipDuration3}</p>
          </div>
        );
      case IMAGE_GENERATION_STEP:
        return (
          <div>
            <p>{generateAIVideosText.helpTipImageGen1}</p>
            <p>{generateAIVideosText.helpTipImageGen2}</p>
            <ul>
              <li>{generateAIVideosText.helpTipImageGen3}</li>
              <li>{generateAIVideosText.helpTipImageGen4}</li>
              <li>{generateAIVideosText.helpTipImageGen5}</li>
              <li>{generateAIVideosText.helpTipImageGen6}</li>
              <li>{generateAIVideosText.helpTipImageGen7}</li>
            </ul>
          </div>
        );
      case VOICE_NARRATION_STEP:
        return (
          <div>
            <p>{generateAIVideosText.helpTipNarration}</p>
            <p>{generateAIVideosText.helpTipNarration2}</p>
          </div>
        );
      case SUMMARY_STEP:
        return (
          <div>
            <p>{generateAIVideosText.helpTipSummary}</p>
            <p>{generateAIVideosText.helpTipSummary2}</p>
            <p>{generateAIVideosText.helpTipSummary3}</p>
          </div>
        );
      default:
        return <p></p>;
    }
  };

  return (
    <div className="HelpTip">
      <h3>{generateAIVideosText.helpTitle}</h3>
      {getHelpContentForStep()}
    </div>
  );
};

export default HelpTip;
