import React from 'react';
import {formatDateTimeTextFormat} from '../../../utils/notificationTimeTextConvertor';
import DataTable from 'react-data-table-component';
import '../../../uiToolkit/DataTable/styles.css';
import TablePagination from '../../../uiToolkit/TablePagination';
import {RecentUserSignup} from '../../../data/models/Analytics';
import {getSubscriptionNameFromTier} from '../../../data/models/Subscription/Subscription';
import Tooltip from '../../../uiToolkit/Tooltip';

interface Props {
  signups: RecentUserSignup[];
  pages: number;
  onPageChange: (page: number) => void;
}

const columns = [
  {
    name: 'Email',
    selector: (row: RecentUserSignup) => row.email,
    sortable: true,
    sortField: 'file_name',
    cell: (row: RecentUserSignup) => (
      <Tooltip tooltipText={row.email}>
        <h5 className="DataTableText" data-tag="allowRowEvents">
          {row.email}
        </h5>
      </Tooltip>
    ),
    grow: 2,
  },
  {
    name: 'Workspace Name',
    selector: (row: RecentUserSignup) => row.organizationName,
    sortable: true,
    sortField: 'file_name',
    cell: (row: RecentUserSignup) => (
      <h5 className="DataTableText" data-tag="allowRowEvents">
        {row.organizationName}
      </h5>
    ),
  },
  {
    name: 'Sign Up Date',
    sortable: true,
    sortField: 'created_date',
    selector: (row: RecentUserSignup) => row.signUpDate?.toString() || '',
    cell: (row: RecentUserSignup) => (
      <h5 className="DataTableText" data-tag="allowRowEvents">
        {row.signUpDate
          ? formatDateTimeTextFormat(row.signUpDate.toString())
          : 'N/A'}
      </h5>
    ),
    id: 'created_date',
  },
  {
    name: 'Workspace Type',
    sortable: true,
    sortField: 'created_date',
    selector: (row: RecentUserSignup) => row.subscriptionType,
    cell: (row: RecentUserSignup) => (
      <h5 className="DataTableText" data-tag="allowRowEvents">
        {getSubscriptionNameFromTier(row.subscriptionType)}
      </h5>
    ),
    id: 'created_date',
  },
  {
    name: 'Trial Used',
    sortable: true,
    sortField: 'created_date',
    selector: (row: RecentUserSignup) => row.trialStatus,
    cell: (row: RecentUserSignup) => (
      <h5 className="DataTableText" data-tag="allowRowEvents">
        {row.trialStatus ? 'Yes' : 'No'}
      </h5>
    ),
    id: 'created_date',
  },
  {
    name: 'Trial Expiration Date',
    sortable: true,
    sortField: 'created_date',
    selector: (row: RecentUserSignup) => row.trialExpiryDate || '',
    cell: (row: RecentUserSignup) => (
      <Tooltip
        tooltipText={
          row.trialExpiryDate
            ? formatDateTimeTextFormat(row.trialExpiryDate)
            : '-'
        }>
        <h5 className="DataTableText" data-tag="allowRowEvents">
          {row.trialExpiryDate
            ? formatDateTimeTextFormat(row.trialExpiryDate)
            : '-'}
        </h5>
      </Tooltip>
    ),
    id: 'created_date',
  },
  {
    name: 'Last accessed at',
    sortable: true,
    sortField: 'created_date',
    selector: (row: RecentUserSignup) =>
      row.lastOrganizationAccessedAt?.toString() || '',
    cell: (row: RecentUserSignup) => (
      <h5 className="DataTableText" data-tag="allowRowEvents">
        {row.lastOrganizationAccessedAt
          ? formatDateTimeTextFormat(row.lastOrganizationAccessedAt.toString())
          : 'N/A'}
      </h5>
    ),
    id: 'created_date',
  },
];

const SignupsTable = React.memo(({signups, pages, onPageChange}: Props) => {
  return (
    <DataTable
      className="Table"
      columns={columns}
      data={signups}
      onChangePage={onPageChange}
      pagination
      paginationServer
      paginationPerPage={10}
      paginationComponent={TablePagination}
      paginationTotalRows={pages * 10}
      striped
      theme="custom-stripes"
    />
  );
});

export default SignupsTable;
