export const tapyblAiHomeText = {
  title: 'Tapybl AI',
  subtitle: 'Powered by AI, driven by you',
  selection: 'I’m here to generate:',
  aiAssistants: 'AI Assistants',
  videos: 'Videos',
  aiAssistantsDescription:
    'Generate AI assistants from your content, accessible to learners across your workspace or within specific courses.',
  aiVideosDescription:
    'Generate engaging videos aligned with your content to enhance learning and captivate your audience.',
};
