import {inject, observer} from 'mobx-react';
import React, {useEffect, useState} from 'react';
import AiAssistnatChatbotUI from '../view/AiAssistantChatbotUI';
import AiAssistantChatbotStore from '../store/AiAssistantChatbotStore';
import {AiAssitantChatMessage} from '../../../data/models/AI/AiAssistantModels';
import NavigationStore from '../../../navigation/store/NavigationStore';

interface Props {
  aiAssistantChatbotStore?: AiAssistantChatbotStore;
  navigationStore?: NavigationStore;
  onClosed?: () => void;
  learnerView?: boolean;
}

const AiAssistantChatbotContainer = inject(
  'aiAssistantChatbotStore',
  'navigationStore',
)(
  observer(
    ({
      aiAssistantChatbotStore,
      navigationStore,
      onClosed,
      learnerView,
    }: Props) => {
      const [chat, setChat] = useState<AiAssitantChatMessage[]>([]);
      const [assistantTitle, setAssistantTitle] = useState('');

      useEffect(() => {
        const orgId = navigationStore!.userOrganization?.id;
        if (
          aiAssistantChatbotStore!.shouldShowChatbot &&
          orgId &&
          aiAssistantChatbotStore!.currentAssistant
        ) {
          aiAssistantChatbotStore!.getAssistantChat(orgId).then(res => {
            if (res) {
              setChat(res.history.chat_history);
              setAssistantTitle(res.title);
            }
          });
        } else {
          setChat([]);
        }
      }, [
        aiAssistantChatbotStore!.shouldShowChatbot,
        aiAssistantChatbotStore!.currentAssistant,
      ]);

      const onClose = () => {
        aiAssistantChatbotStore!.closeChatbot();
        if (onClosed) {
          onClosed();
        }
      };

      const sendChatMessage = (message: string) => {
        const orgId = navigationStore!.userOrganization?.id;
        if (orgId) {
          const oldChat = [...chat];
          setChat([
            ...chat,
            {
              learner_question: message,
              assistant_response: '',
              created_on: '',
            },
          ]);
          aiAssistantChatbotStore!
            .sendMessage(orgId, message, learnerView)
            .then(res => {
              if (res) {
                setChat(res.chat_history);
              } else {
                setChat(oldChat);
              }
            });
        }
      };

      return aiAssistantChatbotStore!.shouldShowChatbot ? (
        <AiAssistnatChatbotUI
          onClose={onClose}
          chat={chat}
          onSendMessage={sendChatMessage}
          isLoading={aiAssistantChatbotStore!.isLoading}
          isSendingMessage={aiAssistantChatbotStore!.isSendingMessage}
          assistantTitle={assistantTitle}
        />
      ) : null;
    },
  ),
);

export default AiAssistantChatbotContainer;
