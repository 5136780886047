import React, {useRef} from 'react';
import {inject, observer} from 'mobx-react';
import {aiAssistantEmptyAvatar} from '../../../../assets/images/';

interface Props {
  file?: File;
  onFileSelected: (file: File) => void;
  existingFileUrl?: string;
}

const AvatarLibrary: React.FC<Props> = ({
  file,
  onFileSelected,
  existingFileUrl,
}) => {
  const inputFile = useRef<any>(null);

  const onBrowse = () => {
    if (inputFile.current) {
      inputFile.current.value = '';
      inputFile.current.click();
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file?.type.includes('image')) {
      return;
    }
    if (file) {
      onFileSelected(file);
    }
  };

  return (
    <div className="GenerateAIAssistantThumb">
      <div className="LibraryFilesButtonContainer">
        <a href="#" onClick={onBrowse}>
          <img
            src={
              file
                ? URL.createObjectURL(file)
                : existingFileUrl
                ? existingFileUrl
                : aiAssistantEmptyAvatar
            }
            alt="Avatar"
            className={!file && !existingFileUrl ? 'PlaceholderAvatar' : ''}
          />
          <span className="icon-image-mount"></span>
        </a>
        <input
          type="file"
          onChange={onChange}
          accept="image/*"
          style={{display: 'none'}}
          ref={inputFile}
        />
      </div>
    </div>
  );
};

export default inject('myLibraryModalStore')(observer(AvatarLibrary));
