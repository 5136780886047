import {APP_PERMISSIONS} from '../../../data/permissions';

export const courseEditTabs = (
  permissions: string[],
  organizationId?: string,
  courseId?: string,
) => [
  {
    title: 'General',
    route: `/manage/${organizationId}/courses/${courseId}/general`,
  },
  permissions.includes(APP_PERMISSIONS.ORG_EDIT_COURSES)
    ? {
        title: 'Learners Results',
        route: `/manage/${organizationId}/courses/${courseId}/results`,
      }
    : null,
  permissions.includes(APP_PERMISSIONS.ORG_ASSIGN_INSTRUCOR)
    ? {
        title: 'Instructors',
        route: `/manage/${organizationId}/courses/${courseId}/instructors`,
      }
    : null,
  permissions.includes(APP_PERMISSIONS.ORG_ENROLL_LEARNER)
    ? {
        title: 'Enroll Learners',
        route: `/manage/${organizationId}/courses/${courseId}/learners`,
      }
    : null,
  // {
  //   title: 'Syllabus',
  //   route: `/manage/${organizationId}/course/${courseId}/syllabus`,
  // },
];
