import React from 'react';
import {OverlayTrigger} from 'react-bootstrap';
import './styles.css';
import {tooltipArrow} from '../../assets/images';

interface Props {
  children: React.ReactElement;
  tooltipText: string;
  greenTooltip?: boolean;
  className?: string;
}

const Tooltip = React.memo(({children, tooltipText, className}: Props) => {
  const renderCloud = () => (
    <div className="TooltipCloud" id={'d-t'} style={{position: 'fixed'}}>
      <span>{tooltipText}</span>
      <div className="ArrowPosition">
        <img src={tooltipArrow} />
      </div>
    </div>
  );

  return (
    <div className={`${className ? className : ''}`}>
      <OverlayTrigger placement="bottom" overlay={renderCloud()}>
        <div>{children}</div>
      </OverlayTrigger>
    </div>
  );
});

export default Tooltip;
