import {
  AiAssistantEditModel,
  MyAssitantListItem,
} from '../models/AI/AiAssistantModels';
import {ApiResponse} from '../models/ApiResponse';
import {apiClient} from './baseApiClient';

class AiAssitantsApi {
  public getMyAssistants(
    courseId?: string,
  ): Promise<ApiResponse<MyAssitantListItem[]>> {
    if (courseId) {
      return apiClient.get(`/AiAssistant/my_assistants?courseId=${courseId}`);
    }
    return apiClient.get(`/AiAssistant/my_assistants`);
  }

  public getAssitantForEditing(
    organicationId: string,
    assistantId: string,
  ): Promise<ApiResponse<AiAssistantEditModel>> {
    return apiClient.get(
      `/videoQuestions/${organicationId}/ai_assistant/${assistantId}`,
    );
  }
}

export default AiAssitantsApi;
