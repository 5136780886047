import React from 'react';
import {
  SelectOption,
  SelectOptionImage,
} from '../../../data/models/UI/SelectOption';
import './styles.css';
import Tooltip from '../../Tooltip';

interface Props {
  options: SelectOptionImage[];
  className?: string;
  onSelect: (selectedOption: string) => void;
  selectedOption: string;
  disabledOptions?: SelectOption[];
  showTooltip?: boolean;
}

const CardOptionsInput = React.memo(
  ({
    options,
    className,
    onSelect,
    selectedOption,
    disabledOptions,
    showTooltip,
  }: Props) => {
    const renderButton = (option: SelectOptionImage) => (
      <button
        className={`CardOption ${
          selectedOption === option.key ? 'CardSelected' : ''
        } ${
          disabledOptions?.find(o => o.key === option.key) !== undefined
            ? 'CardDisabled'
            : ''
        }`}
        key={option.key}
        disabled={
          disabledOptions?.find(o => o.key === option.key) !== undefined
        }
        onClick={() => onSelect(option.key)}>
        {option.image ? (
          <img src={option.image} />
        ) : (
          <>
            <p>{option.key}</p>
            <span>{option.value}</span>
          </>
        )}
        {selectedOption === option.key && option.image ? (
          <div className="ImageSelectedIndicator">
            <span className="icon-check"></span>
          </div>
        ) : null}
      </button>
    );
    return (
      <div className={`CardOptionsInput ${className}`}>
        {options.map(option =>
          showTooltip ? (
            <Tooltip tooltipText={option.value} key={option.key}>
              {renderButton(option)}
            </Tooltip>
          ) : (
            renderButton(option)
          ),
        )}
      </div>
    );
  },
);

export default CardOptionsInput;
