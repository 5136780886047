import React from 'react';
import './styles.css';
import {appText} from '../../../assets/text/appText';

interface Props {
  value: boolean;
  onToggle: () => void;
  disabled: boolean;
}

const Switch = React.memo(({value, onToggle, disabled}: Props) => {
  return (
    <div
      className={`Switch ${value ? 'SwitchEnabled' : ''}`}
      onClick={!disabled ? () => onToggle() : undefined}>
      <span className={`StateText ${!value ? 'StateEnabled' : ''}`}>
        {value ? appText.on : appText.off}
      </span>
      <div className={`Knob ${value ? 'KnowEnabled' : ''}`} />
    </div>
  );
});

export default Switch;
