export const AI_OPTION_DIRECT = 'direct';
export const AI_OPTION_INDIRECT = 'indirect';

export const AiAssistantResponseOptions = [
  {
    key: AI_OPTION_DIRECT,
    value: 'Direct',
  },
  {
    key: AI_OPTION_INDIRECT,
    value: 'Indirect',
  },
];

export const AiAssistantCommunicationOptions = [
  {
    key: 'formal',
    value: 'Formal',
  },
  {
    key: 'informal',
    value: 'Informal',
  },
];
