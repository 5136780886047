import React, {useState} from 'react';
import {inject, observer} from 'mobx-react';
import GenerateAIVideosUI from '../view/GenerateAIVideosUI';
import GenerateAIVideosStore from '../store/GenerateAIVideosStore';
import {useNavigate, useParams} from 'react-router-dom';
import {VideoGenerationConfig} from '../../../../data/models/AI/VideoGenerationConfig';

interface Props {
  generateAIVideosStore?: GenerateAIVideosStore;
}

const GenerateAIVideosContainer = inject('generateAIVideosStore')(
  observer(({generateAIVideosStore}: Props) => {
    const [estimatedCost, setEstimatedCost] = useState(0);
    const [availableCredits, setAVailableCredits] = useState(0);
    const [fileOrientation, setFileOrientation] = useState('horizontal');

    const {organizationId} = useParams();
    const navigate = useNavigate();

    const onCancel = () => {
      navigate(`/manage/${organizationId}/ai`);
    };

    const onSave = (config: VideoGenerationConfig) => {
      if (organizationId) {
        generateAIVideosStore!
          .generateAiVideosFromSlides(organizationId, config)
          .then(success => {
            if (success) {
              onCancel();
            }
          });
      }
    };

    const onCalculateEstimation = (
      fileId: string,
      duration: number,
      aiImageRatio: number,
    ) => {
      if (organizationId) {
        generateAIVideosStore!
          .getVideoGenerationCost(organizationId, {
            libraryMediaFileId: fileId,
            duration,
            aiImageRatio,
          })
          .then(res => {
            setAVailableCredits(res.availableCredits);
            setEstimatedCost(res.estimatedCost);
          });
      }
    };

    const fetchFileOrientation = (fileId: string) => {
      if (organizationId) {
        generateAIVideosStore!
          .getFileOrientation(organizationId, fileId)
          .then(setFileOrientation);
      }
    };

    return (
      <GenerateAIVideosUI
        onCancel={onCancel}
        onSave={onSave}
        isLoading={generateAIVideosStore!.isLoading}
        calculateEstimation={onCalculateEstimation}
        estimatedCost={estimatedCost}
        availableCredits={availableCredits}
        fileOrientation={fileOrientation}
        fetchFileOrientation={fetchFileOrientation}
      />
    );
  }),
);

export default GenerateAIVideosContainer;
