import React, {useCallback, useEffect, useState} from 'react';
import LibraryUI from '../view/LibraryUI';
import {inject, observer} from 'mobx-react';
import VideoSourcesStore from '../store/VideoSourcesStore';
import {useNavigate, useParams} from 'react-router-dom';
import {
  MEDIA_FILES_ALL,
  MediaFile,
  VideoTranscriptSegment,
} from '../../../../data/models/LibraryFile/MediaFile';
import NavigationStore from '../../../../navigation/store/NavigationStore';
import {FileUploadModalBody} from '../../../../modules/FileUploadModalBody';
import {LIBRARY_ITEMS_PER_PAGE} from '../../conf';
import {SortOption} from '../../../../data/models/UI/SelectOption';
import FilePreviewContainer from './FilePreviewContainer';
import DownloadTranscriptionModal from '../components/DownloadTranscriptionModal';
import {videoSourcesText} from '../../videoSourcesText';
import ConfirmationModal from '../../../../uiToolkit/ConfirmationModal';

interface Props {
  videoSourcesStore?: VideoSourcesStore;
  navigationStore?: NavigationStore;
}

const LibraryContanier = inject(
  'videoSourcesStore',
  'navigationStore',
  'generateAIVideosStore',
)(
  observer(({videoSourcesStore, navigationStore}: Props) => {
    const [uploadedFile, setUploadedFile] = useState<File>();
    const [page, setPage] = useState(0);
    const [search, setSearch] = useState('');
    const [filter, setFilter] = useState(MEDIA_FILES_ALL);
    const [pagesCount, setPagesCount] = useState(1);
    const [sortOptions, setSortOptions] = useState<SortOption>({
      field: 'date_created',
      order: 'desc',
    });
    const [files, setFiles] = useState<MediaFile[]>([]);
    const [fileTranscriptions, setFileTranscriptions] =
      useState<VideoTranscriptSegment[]>();

    const [fileToPreview, setFileToPreview] = useState<MediaFile>();
    const [fileToDeleteId, setFileToDeleteId] = useState<string>();

    const {organizationId} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
      fetchFiles();
    }, [filter, search, sortOptions]);

    const onUpload = (video: File) => {
      setUploadedFile(video);
    };

    const fetchFiles = async () => {
      if (organizationId) {
        const result = await videoSourcesStore!.getMyVideoSources(
          0,
          organizationId,
          LIBRARY_ITEMS_PER_PAGE,
          filter,
          sortOptions.field,
          search,
          sortOptions.order === 'asc',
        );

        setFiles(result.data);
        setPage(0);
        setPagesCount(result.pagesCount);
      }
    };

    const loadNextPage = useCallback(async () => {
      if (organizationId && page + 1 <= pagesCount) {
        const result = await videoSourcesStore!.getMyVideoSources(
          page + 1,
          organizationId,
          LIBRARY_ITEMS_PER_PAGE,
          filter,
          sortOptions.field,
          search,
          sortOptions.order === 'asc',
        );
        setPagesCount(result.pagesCount);
        setFiles(oldFiles => [...oldFiles, ...result.data]);
        setPage(page + 1);
      }
    }, [pagesCount, page]);

    const onCloseUpload = () => {
      if (organizationId) {
        setUploadedFile(undefined);
        fetchFiles();
      }
    };

    const onEditVideo = (file: MediaFile) => {
      navigate(`/manage/${organizationId}/editVideo/${file.id}`);
    };

    const onDownloadTranscript = async (id: string) => {
      if (organizationId) {
        const result = await videoSourcesStore!.downloadTranscript(
          organizationId,
          id,
        );
        if (result.length) {
          setFileTranscriptions(result);
        }
      }
    };

    const onDownloadVideo = async (file: MediaFile) => {
      return videoSourcesStore!.downloadFile(file);
    };

    const onDelete = () => {
      if (organizationId && fileToDeleteId) {
        videoSourcesStore!
          .deleteMediaFile(organizationId, fileToDeleteId)
          .then(fetchFiles);
        setFileToDeleteId(undefined);
      }
    };

    return (
      <>
        <LibraryUI
          isLoading={videoSourcesStore!.isLoading}
          onUpload={onUpload}
          storageLimit={navigationStore!.getStorageUsed()}
          files={files}
          organizationId={organizationId || ''}
          search={search}
          setSearch={setSearch}
          filter={filter}
          setFilter={setFilter}
          sortOptions={sortOptions}
          setSort={setSortOptions}
          loadNextPage={loadNextPage}
          onPreview={setFileToPreview}
          onDelete={setFileToDeleteId}
          onEditVideo={onEditVideo}
          onDownloadTranscript={onDownloadTranscript}
          onDownloadVideo={onDownloadVideo}
        />
        <FilePreviewContainer
          fileToPreview={fileToPreview}
          onClose={() => setFileToPreview(undefined)}
        />
        <ConfirmationModal
          shown={fileToDeleteId !== undefined}
          onAccept={onDelete}
          onCancel={() => setFileToDeleteId(undefined)}
          acceptText={videoSourcesText.delete}
          title={videoSourcesText.confirmDelete}
        />
        {uploadedFile && (
          <FileUploadModalBody
            file={uploadedFile}
            onClose={onCloseUpload}
            onSuccess={onCloseUpload}
          />
        )}
        {fileTranscriptions && (
          <DownloadTranscriptionModal
            transcriptions={fileTranscriptions}
            onClose={() => setFileTranscriptions(undefined)}
          />
        )}
      </>
    );
  }),
);

export default LibraryContanier;
