import React from 'react';
import {videoGenerationSteps} from '../config';

interface Props {
  activeStep: number;
}

const GenerationSteps = React.memo(({activeStep}: Props) => {
  return (
    <div className="GenerationStepsContainer">
      {videoGenerationSteps.map((step, index) => (
        <div
          key={step.id}
          className={`${
            activeStep >= step.id ? 'GenerationStepActive' : ''
          } GenerationStep`}>
          <div className="StepNumber">
            <span>{index + 1}</span>
          </div>
          <span>{step.title}</span>
          {index + 1 !== videoGenerationSteps.length && (
            <span className="icon-chevron-right"></span>
          )}
        </div>
      ))}
    </div>
  );
});

export default GenerationSteps;
