import React from 'react';
import {deleteIcon, folderIc} from '../../../assets/images';
import {appText} from '../../../assets/text/appText';
import './styles.css';
import {toast} from 'react-toastify';
import {getErrorFromCode, internalErrorCodes} from '../../../data/errorCodes';
import {toastConfig} from '../../Toast/toastConfig';

interface Props {
  onChange: (file?: File) => void;
  image?: File;
  existingImage?: string;
  className?: string;
}

const BoxImagePickerInput = React.memo(
  ({onChange, image, existingImage, className}: Props) => {
    const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0];
      if (file && (fileIsAnImage(file) || fileIsAVideo(file))) {
        onChange(file);
      } else {
        toast.error(
          getErrorFromCode(internalErrorCodes.INVALID_FILE_EXTENSION),
          toastConfig,
        );
      }
    };

    const clearImage = () => {
      onChange();
    };

    const fileIsAnImage = (file: File) => {
      return file.type.includes('image');
    };

    const fileIsAVideo = (file: File) => {
      return file.type.includes('video');
    };

    return (
      <div className={`BoxImagePickerInput ${className}`}>
        <h3>{existingImage ? appText.replaceIcon : appText.addIcon}</h3>
        {image ? (
          <div className="ImagePreviewContainer">
            <img src={URL.createObjectURL(image)} alt="Preview" />
            <button className="ClearButton" onClick={clearImage}>
              <img src={deleteIcon} alt="delete" />
            </button>
          </div>
        ) : (
          <div className="ImagePreviewContainer">
            <img
              src={existingImage ? existingImage : folderIc}
              className="AttachFileImage"
            />
          </div>
        )}
        <label className="BrowseFileButton">
          {appText.browse}
          <input
            type="file"
            onChange={onFileChange}
            accept="image/*"
            style={{display: 'none'}}
            onClick={event => {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-ignore
              event.target.value = null;
            }}
          />
        </label>
      </div>
    );
  },
);

export default BoxImagePickerInput;
