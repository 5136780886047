import React from 'react';
import {appText} from '../../../assets/text/appText';
import {useNavigate} from 'react-router-dom';
import './styles.css';

const BackButton = React.memo(() => {
  const navigate = useNavigate();

  const onBack = () => {
    navigate(-1);
  };

  return (
    <button onClick={onBack} className="GlobalBackButton">
      <span className="icon-arrow-left"></span>
      <span>{appText.back}</span>
    </button>
  );
});

export default BackButton;
