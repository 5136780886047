import React, {useRef, useState} from 'react';
import {
  SelectOption,
  SortedSelectOption,
} from '../../../../data/models/UI/SelectOption';
import Button from '../../../../uiToolkit/Buttons/Button';
import SearchInput from '../../../../uiToolkit/Inputs/SearchInput';
import {getFirstCapitals} from '../../../../utils/getFirstCapitals';

interface Props {
  heading?: string;
  options: SelectOption[];
  placeholder?: string;
  selectedOptions: string[];
  setSelectedOptions: (options: string[]) => void;
  className?: string;
  fieldError?: boolean;
  disabled?: boolean;
  isMandatory?: boolean;
  subtitle?: string;
  onSearchDropdownVisibilityToggle?: (isVisible: boolean) => void;
  onSearch: (query: string) => void;
  search: string;
  specialOption?: SelectOption;
}

const SearchDropdown = React.memo(
  ({
    heading,
    options,
    placeholder,
    className,
    fieldError,
    disabled,
    isMandatory,
    subtitle,
    onSearchDropdownVisibilityToggle,
    setSelectedOptions,
    onSearch,
    search,
    selectedOptions,
    specialOption,
  }: Props) => {
    const [isOpen, setIsOpen] = useState(false);
    const openerRef = useRef<any>(null);

    const getOptions = (): SelectOption[] =>
      options.sort((a, b) => a.value.localeCompare(b.value));

    const groupOptionsByFirstLetter = (
      options: SelectOption[],
    ): SortedSelectOption[] => {
      const grouped = options.reduce(
        (acc, option) => {
          const firstLetter = option.value.charAt(0).toUpperCase();
          if (!acc[firstLetter]) acc[firstLetter] = [];
          acc[firstLetter].push(option);
          return acc;
        },
        {} as Record<string, SelectOption[]>,
      );

      return Object.entries(grouped).map(([key, value]) => ({
        key,
        value,
      }));
    };

    const sortedGroupedOptions = groupOptionsByFirstLetter(getOptions());

    const toggle = () => {
      if (!disabled) {
        if (onSearchDropdownVisibilityToggle) {
          onSearchDropdownVisibilityToggle(!isOpen);
        }
        setIsOpen(!isOpen);
      }
    };

    const onOptionClicked = (key: string) => {
      const newOptions = selectedOptions.includes(key)
        ? [...selectedOptions].filter(option => option !== key)
        : [...selectedOptions, key];
      setSelectedOptions(newOptions);
    };

    const getSelectedOption = () => {
      if (selectedOptions.length === 0) return placeholder || 'Select options';
      return (
        <>
          {placeholder}
          {selectedOptions ? (
            <span className="numberSelected">{selectedOptions.length}</span>
          ) : null}
        </>
      );
    };

    const renderCourseItem = (option: SelectOption) => (
      <li key={option.key}>
        <input
          type="checkbox"
          checked={selectedOptions.includes(option.key)}
          onChange={() => onOptionClicked(option.key)}
          id={option.key}
        />
        <div className="ImageContainer">
          {option.thumb ? (
            <img src={option.thumb} alt="" />
          ) : (
            <span>{getFirstCapitals(option.value)}</span>
          )}
        </div>
        <label htmlFor={option.key}>{option.value}</label>
      </li>
    );

    return (
      <div className={`${className}`}>
        {heading && (
          <p
            className={`SearchDropdownTitle ${
              disabled && 'SearchDropdownDisabled'
            }`}>
            {heading}{' '}
            {isMandatory && !disabled && (
              <span className="MandatoryIndicator">*</span>
            )}
          </p>
        )}
        {subtitle && <p className="SearchDropdownSubtitleText">{subtitle}</p>}
        <div className="SearchDropdownContainer">
          <div
            ref={openerRef}
            className={`SearchDropdownBody ${
              !selectedOptions.length ? 'Placeholder' : ''
            } ${fieldError ? 'FieldError' : ''} ${
              disabled && 'SearchDropdownDisabled'
            }`}
            onClick={toggle}>
            <span className="SearchDropdownBodyPlaceholder">
              {getSelectedOption()}
            </span>
            <span
              className={
                isOpen ? 'icon-chevron-up' : 'icon-chevron-down'
              }></span>
          </div>
          {isOpen && (
            <div className="SearchDropdownList">
              <div className="SearchDropdownListContainer">
                <div className="SearchDropdownListHeader">
                  <SearchInput value={search} onChange={onSearch} />
                </div>
                {selectedOptions.length > 0 && (
                  <div className="SelectedOptionsContainer">
                    <h3 className="SectionLabel">Selected</h3>
                    <ul className="SearchDropdownListContent">
                      {getOptions()
                        .filter(option => selectedOptions.includes(option.key))
                        .map(renderCourseItem)}
                    </ul>
                    <hr />
                  </div>
                )}
                <ul className="SearchDropdownListContent">
                  {specialOption && renderCourseItem(specialOption)}
                  {sortedGroupedOptions.map(group => (
                    <div key={group.key} className="Group">
                      <h3 className="SectionLabel">{group.key}</h3>
                      {group.value.map(renderCourseItem)}
                    </div>
                  ))}
                </ul>
              </div>
              <div className="SearchDropdownListFooter">
                <Button
                  title="Reset"
                  onClick={() => {
                    onSearch('');
                    setSelectedOptions([]);
                  }}
                  uiType="text"
                  icon="icon-typeReload"
                />
                <Button
                  title="Save"
                  onClick={() => setIsOpen(false)}
                  uiType="text"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  },
);

export default SearchDropdown;
