import {getLocalizationBasedOnLang} from '../../../data/models/User/UserInfo';

const english = {
  title: 'Multiple Choice Q&A.',
  questionTitle: 'Question',
  questionPlaceholder: 'Enter your question',
  answerTitle: 'Answer',
  answerPlaceholder: 'Add multiple choice answer',
  isCorrectTitle: 'Correct Answer?',
  addAnswer: 'Add answer',
  cancel: 'Cancel',
  accept: 'Save',
  generateWithAI: 'Generate with AI Assistant',
  assistant: 'AI Assistant',
  topicHeading: 'Specify the question topic for suggestions.',
  topicPlaceholder: 'Questions topic',
  continue: 'Continue',
  select: 'Select',
  Disclamer: '*Maximum 4, with at least 1 answer correct. No duplicate answers',
  chooseQuestion: 'Choose the question you want to proceed with.',
  gradeWeight: 'Score Weight',
  openAIAssistant: 'Open tapybl AI Assistant',
  cantGenerate:
    'AI generation is only available for videos uploaded directly to tapybl.',
  difficultyLevel: 'Question difficulty:',
};

const welsh = {
  title: 'Holi ac Ateb Amlddewis',
  questionTitle: 'Cwestiwn',
  questionPlaceholder: 'Rhowch eich cwestiwn',
  answerTitle: 'Ateb',
  answerPlaceholder: 'Ychwanegwch ateb amlddewis',
  isCorrectTitle: 'Ateb Cywir?',
  addAnswer: 'Ychwanegwch ateb',
  cancel: 'Canslo',
  accept: 'Cadw',
  generateWithAI: 'Cynhyrchwch gyda Cynorthwy-ydd AI',
  assistant: 'Cynorthwy-ydd AI',
  topicHeading: 'Nodwch bwnc y cwestiwn am awgrymiadau',
  topicPlaceholder: 'Pwnc cwestiynau',
  continue: 'Parhewch',
  select: 'Dewiswch',
  Disclamer:
    '* Uchafswm pedwar, gydag o leiaf un ateb yn gywir. Dim atebion dyblyg',
  chooseQuestion: 'Dewiswch y cwestiwn yr ydych am fynd ymlaen ag ef',
  gradeWeight: 'Pwysau Sgôr',
  openAIAssistant: 'Agor Cynorthwyydd tapybl AI',
};

export const addMChoiceWizardText: any = getLocalizationBasedOnLang(
  english,
  welsh,
);
