import React, {useEffect, useState} from 'react';
import TextInput from '../../uiToolkit/Inputs/TextInput';
import ImagePickerInput from '../../uiToolkit/Inputs/ImagePickerInput';
import {orgDataFormText} from './orgDataFormText';
import {OrganizationSettings} from '../../data/models/Oganization/Organization';
import {resizeImage} from '../../utils/resizeImage';
import './styles.css';
import Dropdown from '../../uiToolkit/Inputs/Dropdown';
import Button from '../../uiToolkit/Buttons/Button';
import {HexColorPicker} from 'react-colorful';
import {ORGANIZATION_TYPE_OPTIONS} from './options';
import {
  DEFAULT_PRIMARY_COLOR,
  DEFAULT_SECONDARY_COLOR,
} from '../../data/staticValues/colors';
import {toast} from 'react-toastify';
import {appText} from '../../assets/text/appText';
import {toastConfig} from '../../uiToolkit/Toast/toastConfig';

const ORG_AVATAR_WIDTH = 300;
const ORG_AVATAR_HEIGHT = 300;

interface Props {
  onSubmit: (
    name: string,
    primaryColor: string,
    secondaryColor: string,
    type: number,
    image?: File,
  ) => void;
  onCancel?: () => void;
  submitText: string;
  isLoading: boolean;
  organization?: OrganizationSettings;
  skipColors?: boolean;
  customNameTitle?: string;
  customNameSubtitle?: string;
  customImageTitle?: string;
  customTypeTitle?: string;
  customTypeSubtitle?: string;
}

const OrgDataForm = React.memo(
  ({
    onSubmit,
    submitText,
    onCancel,
    isLoading,
    organization,
    skipColors,
    customImageTitle,
    customNameSubtitle,
    customNameTitle,
    customTypeSubtitle,
    customTypeTitle,
  }: Props) => {
    const [name, setName] = useState('');
    const [image, setImage] = useState<File>();
    const [primaryColor, setPrimaryColor] = useState(
      localStorage.getItem('primaryColor') || DEFAULT_PRIMARY_COLOR,
    );
    const [secondaryColor, setSecondaryColor] = useState(
      localStorage.getItem('secondaryColor') || DEFAULT_SECONDARY_COLOR,
    );
    const [type, setType] = useState('');

    const [dropdownOpen, setDropdownOpen] = useState(false);

    // COLOR PICKER
    const [primaryColorPick, setPrimaryColorPick] = useState(false);
    const [secondaryColorPick, setSecondaryColorPick] = useState(false);

    useEffect(() => {
      if (organization) {
        setName(organization.name);
        setType(organization.type.toString());
      }
    }, []);

    const onChangeImage = async (file?: File) => {
      if (file) {
        const compressed = await resizeImage(
          file,
          ORG_AVATAR_WIDTH,
          ORG_AVATAR_HEIGHT,
        );
        setImage(compressed);
      } else {
        setImage(undefined);
      }
    };

    const primaryHandleColorPickerOn = () => {
      setPrimaryColorPick(true);
    };

    const secondaryHandleColorPickerOn = () => {
      setSecondaryColorPick(true);
    };

    const primaryHandleColorPickerOff = () => {
      setPrimaryColorPick(false);
    };

    const secondaryHandleColorPickerOff = () => {
      setSecondaryColorPick(false);
    };

    const onResetPrimaryColor = () => {
      setPrimaryColor(DEFAULT_PRIMARY_COLOR);
    };

    const onResetSecondaryColor = () => {
      setSecondaryColor(DEFAULT_SECONDARY_COLOR);
    };

    const validatePrimaryColor = () => {
      const pattern = new RegExp(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/);
      if (!pattern.test(primaryColor)) {
        setPrimaryColor(DEFAULT_PRIMARY_COLOR);
        toast.error(appText.invalidHEXValue, toastConfig);
      }
    };

    const validateSecondaryColor = () => {
      const pattern = new RegExp(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/);
      if (!pattern.test(secondaryColor)) {
        setSecondaryColor(DEFAULT_SECONDARY_COLOR);
        toast.error(appText.invalidHEXValue, toastConfig);
      }
    };

    const submitEnabled = () => {
      if (organization) {
        return (
          (name !== organization.name ||
            primaryColor !== organization.primaryColor ||
            secondaryColor !== organization.secondaryColor ||
            image) &&
          name?.trim() !== '' &&
          primaryColor?.trim() !== '' &&
          secondaryColor?.trim() !== ''
        );
      }
      return (
        name?.trim() !== '' &&
        primaryColor?.trim() !== '' &&
        secondaryColor?.trim() !== '' &&
        image &&
        type !== ''
      );
    };

    const onCreate = () => {
      if (submitEnabled()) {
        onSubmit(
          name.trim(),
          primaryColor.trim(),
          secondaryColor.trim(),
          parseInt(type, 10),
          image,
        );
      }
    };

    return (
      <div className="OrgDataForm">
        <TextInput
          value={name}
          onChange={setName}
          placeholder={orgDataFormText.namePlaceholder}
          heading={
            customNameTitle ? customNameTitle : orgDataFormText.nameTitle
          }
          subtitle={customNameSubtitle}
          className="Input"
          maxCharacters={40}
          isMandatory={true}
        />
        <ImagePickerInput
          image={image}
          onChange={onChangeImage}
          heading={
            customImageTitle ? customImageTitle : orgDataFormText.avatarTitle
          }
          placeholder={orgDataFormText.avatarPlaceholder}
          className="Input"
          existingImage={organization?.avatar}
          existingImageName={organization?.avatarName}
          isMandatory={true}
        />
        {/* <TextInput
          value={description}
          onChange={setDescription}
          placeholder={orgDataFormText.desscriptionPlaceholder}
          heading={orgDataFormText.description}
          className="Input"
          isMandatory={true}
        /> */}
        {!skipColors && (
          <>
            <div className="ColorTextInputContainer">
              <TextInput
                value={primaryColor}
                onChange={setPrimaryColor}
                heading={orgDataFormText.primaryColorTitle}
                subtitle={customTypeSubtitle}
                onBlurFunc={validatePrimaryColor}
                placeholder={orgDataFormText.primaryColorPlaceholder}
                className="Input"
                isMandatory={true}
                onFocusFunc={primaryHandleColorPickerOn}
              />
              <Button
                title={orgDataFormText.reset}
                onClick={onResetPrimaryColor}
                className="ColorResetButton"
              />
            </div>
            {primaryColorPick && (
              <div className="colorPickContainer">
                <span
                  className="colorPickClose icon-type-bolder_x"
                  onClick={primaryHandleColorPickerOff}></span>
                <HexColorPicker
                  color={primaryColor}
                  onChange={setPrimaryColor}
                />
              </div>
            )}
            <div className="ColorTextInputContainer">
              <TextInput
                value={secondaryColor}
                onChange={setSecondaryColor}
                heading={orgDataFormText.secondaryColorTitle}
                placeholder={orgDataFormText.secondaryColorPlaceholder}
                className="Input"
                isMandatory={true}
                onFocusFunc={secondaryHandleColorPickerOn}
                onBlurFunc={validateSecondaryColor}
              />
              <Button
                title={orgDataFormText.reset}
                onClick={onResetSecondaryColor}
                className="ColorResetButton"
              />
            </div>
            {secondaryColorPick && (
              <div className="colorPickContainer">
                <span
                  className="colorPickClose icon-type-bolder_x"
                  onClick={secondaryHandleColorPickerOff}></span>
                <HexColorPicker
                  color={secondaryColor}
                  onChange={setSecondaryColor}
                />
              </div>
            )}
          </>
        )}
        <Dropdown
          disabled={organization !== undefined}
          options={ORGANIZATION_TYPE_OPTIONS}
          selectedOption={type}
          placeholder={orgDataFormText.selectType}
          setSelectedOption={setType}
          heading={customTypeTitle ? customTypeTitle : orgDataFormText.type}
          isMandatory={organization === undefined}
          onDropdownVisibilityToggle={setDropdownOpen}
        />
        <div className="Footer">
          {onCancel ? (
            <Button
              title={orgDataFormText.cancel}
              onClick={onCancel}
              uiType="text"
            />
          ) : (
            <div />
          )}

          <Button
            title={submitText}
            onClick={onCreate}
            disabled={!submitEnabled()}
            isLoading={isLoading}
            uiType="action"
          />
        </div>
        <div className={dropdownOpen ? 'ScrollPadding' : ''} />
      </div>
    );
  },
);

export default OrgDataForm;
