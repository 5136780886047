import React, {useRef} from 'react';
import './styles.css';
import Button from '../../../../uiToolkit/Buttons/Button';
import {aiAssistantText} from '../aiAssistantText';
import AiAssistantCard from '../components/AiAssistantCard';
import {Col, Container, Row} from 'react-bootstrap';
import {AiAssistantListItem} from '../../../../data/models/AI/AiAssistantModels';
import AiAssistantEmptyState from '../components/AiAssistantEmptyState';

interface Props {
  isLoading: boolean;
  loadNextPage: () => Promise<any>;
  navigateToGenerate: () => void;
  onPreviewAssistant: (id: string) => void;
  assistants: AiAssistantListItem[];
  toggleAiAssitantVisibility: (
    assistantId: string,
    isCurrentlyVisible: boolean,
  ) => void;
  visibilityLoading: boolean;
  onDeleteAssistant: (id: string) => void;
  onEditAssistant: (id: string) => void;
}

const AiAssistantUI = React.memo(
  ({
    isLoading,
    loadNextPage,
    navigateToGenerate,
    onPreviewAssistant,
    assistants,
    toggleAiAssitantVisibility,
    visibilityLoading,
    onDeleteAssistant,
    onEditAssistant,
  }: Props) => {
    const observer = useRef<any>();

    const overscrollLock = useRef(false);

    const lastPostElementRef = (node: any) => {
      if (isLoading || overscrollLock.current) return;
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting) {
          overscrollLock.current = true;
          loadNextPage().then(() => {
            overscrollLock.current = false;
          });
        }
      });

      if (node) observer.current.observe(node);
    };

    return (
      <>
        <div className="AiAssistantContainer">
          <div className="AiAssistantTitleContainer">
            <div>
              <h1>{aiAssistantText.title}</h1>
              <h4>{aiAssistantText.subtitle}</h4>
            </div>
            <div>
              {assistants.length ? (
                <Button
                  title={aiAssistantText.newAssistant}
                  icon="icon-plus"
                  onClick={navigateToGenerate}
                  className="CreateButton"
                />
              ) : null}
            </div>
          </div>
          {assistants.length ? (
            <Container fluid className="AssistantsList">
              <Row>
                {assistants.map((assistant, index) => (
                  <Col
                    xl={3}
                    lg={4}
                    key={assistant.id}
                    className="AssistantCol"
                    ref={
                      assistants.length === index + 1
                        ? lastPostElementRef
                        : null
                    }>
                    <AiAssistantCard
                      onPreviewAssistant={onPreviewAssistant}
                      assistant={assistant}
                      toggleAiAssitantVisibility={toggleAiAssitantVisibility}
                      visibilityLoading={visibilityLoading}
                      onDeleteAssistant={onDeleteAssistant}
                      onEditAssistant={onEditAssistant}
                    />
                  </Col>
                ))}
              </Row>
            </Container>
          ) : (
            <AiAssistantEmptyState navigateToGenerate={navigateToGenerate} />
          )}
        </div>
      </>
    );
  },
);

export default AiAssistantUI;
