import React, {useEffect} from 'react';
import {Col, Row} from 'react-bootstrap';
import {appText} from '../../../assets/text/appText';
import './styles.css';
import {
  Link,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import PageTitle from '../../../uiToolkit/PageTitle';
import {inject, observer} from 'mobx-react';
import NavigationStore from '../../store/NavigationStore';
import {
  LTI_SUB_FEATURE,
  organizationHasFeature,
  STORAGE_INTEGRATION_SUB_FEATURE,
} from '../../../data/models/Subscription/SubscriptionPermission';
import {getLocalizationBasedOnLang} from '../../../data/models/User/UserInfo';

const tabs = (
  organizationId: string,
  hideLti: boolean,
  hideInegration: boolean,
) => [
  {
    name: getLocalizationBasedOnLang('General', 'Cyffredinol'),
    route: `/manage/${organizationId}/settings/general`,
  },
  {
    name: getLocalizationBasedOnLang('Roles', 'Rolau'),
    route: `/manage/${organizationId}/settings/roles`,
    createRoute: `/manage/${organizationId}/settings/create_role`,
    editRoute: `/manage/${organizationId}/settings/edit_role`,
  },
  {
    name: getLocalizationBasedOnLang('Users', 'Defnyddwyr'),
    route: `/manage/${organizationId}/settings/users`,
    createRoute: `/manage/${organizationId}/settings/invite_users`,
    editRoute: `/manage/${organizationId}/settings/edit_user`,
  },
  {
    name: getLocalizationBasedOnLang('Subscription', 'Tanysgrifiad'),
    route: `/manage/${organizationId}/settings/subscription`,
  },
  !hideLti
    ? {
        name: 'LTI',
        route: `/manage/${organizationId}/settings/lti_settings`,
      }
    : null,
  !hideInegration
    ? {
        name: 'Vimeo Integration',
        route: `/manage/${organizationId}/settings/storage_integration`,
      }
    : null,
];

interface Props {
  navigationStore?: NavigationStore;
}

const DashboardSettingsRoot = inject('navigationStore')(
  observer(({navigationStore}: Props) => {
    const {organizationId} = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
      navigationStore!.tryGetMyOrganization();
    }, []);

    useEffect(() => {
      if (
        location.pathname ===
        `/manage/${navigationStore?.userOrganization?.id}/settings`
      ) {
        navigate('general');
      }
    }, [location]);

    return (
      <div className="SettingsContainer">
        <PageTitle title={appText.dashboardSettingsTitle} />
        <div className="TabsContainer">
          {tabs(
            organizationId || '',
            !organizationHasFeature(
              navigationStore!.subscriptionPermissions,
              LTI_SUB_FEATURE,
            ),
            !organizationHasFeature(
              navigationStore!.subscriptionPermissions,
              STORAGE_INTEGRATION_SUB_FEATURE,
            ),
          ).map(tab =>
            tab !== null ? (
              <Link
                key={tab.name}
                className={
                  location.pathname === tab.route ||
                  location.pathname === tab.createRoute ||
                  (tab.editRoute && location.pathname.includes(tab.editRoute))
                    ? 'ActiveButton'
                    : ''
                }
                to={tab.route}>
                {tab.name}
              </Link>
            ) : null,
          )}
          <div className="FillBorder" />
        </div>
        <Row>
          <Col lg={9} xs={12}>
            <Outlet />
          </Col>
        </Row>
      </div>
    );
  }),
);

export default DashboardSettingsRoot;
