import React from 'react';
import {Link} from 'react-router-dom';

interface Props {
  icon: any;
  title: string;
  route: string;
  activeIcon: any;
  activeRoute: string;
  userPermissions: string[];
  permission?: string;
  collapsed: boolean;
  newTab?: boolean;
}

const NavListItem = React.memo(
  ({
    title,
    route,
    icon,
    activeRoute,
    userPermissions,
    permission,
    collapsed,
    newTab,
  }: Props) => {
    const checkPermission = (permission: string) => {
      return userPermissions.find(up => up === permission);
    };

    if (permission && !checkPermission(permission)) {
      return <div />;
    }

    return (
      <div
        className={`NavListItemContainer ${
          activeRoute.includes(route) ? 'activeRouteLink' : ''
        } ${collapsed ? 'NavListItemCollapsed' : ''}`}>
        <Link
          to={route}
          target={newTab ? '_blank' : ''}
          className={`MainRouteLink ${
            collapsed ? 'MainRouteLinkCollapsed' : ''
          }`}>
          <span className={`${icon} NavIcon`}></span>
          {!collapsed && (
            <span className={activeRoute.includes(route) ? 'Active' : ''}>
              {title}
            </span>
          )}
        </Link>
      </div>
    );
  },
);

export default NavListItem;
