import React from 'react';
import {aiAssistantText} from '../aiAssistantText';
import Button from '../../../../uiToolkit/Buttons/Button';

interface Props {
  navigateToGenerate: () => void;
}

const AiAssistantEmptyState = React.memo(({navigateToGenerate}: Props) => {
  return (
    <div className="AssistantEmptyStateContainer">
      <span className="icon-ai_assistant_ic EmptyStateIcon"></span>
      <p>{aiAssistantText.emptyState1}</p>
      <p className="mb-4">{aiAssistantText.emptyState2}</p>
      <Button
        title={aiAssistantText.newAssistant}
        icon="icon-plus"
        onClick={navigateToGenerate}
        className="CreateButton"
      />
    </div>
  );
});

export default AiAssistantEmptyState;
