import React, {useRef, useState} from 'react';
import {LessonContentImageSource} from '../../../data/models/LessonContent/LessonContentBase';
import {pauseControlsIcon, playControlsIcon} from '../../../assets/images';

interface Props {
  imageSource: LessonContentImageSource;
  onFinished: () => void;
  onWidthCalculated: (width: number) => void;
  started: boolean;
  hideControls?: boolean;
}

const ImagePlayer = React.memo(
  ({
    imageSource,
    onFinished,
    started,
    onWidthCalculated,
    hideControls,
  }: Props) => {
    const [width, setWidth] = useState(0);
    const [playedSeconds, setPlayedSeconds] = useState(0);
    const [currentImageId, setCurrentImageId] = useState('');
    const [currentImageUrl, setCurrentImageUrl] = useState('');
    const [playing, setPlaying] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const containerRef = useRef<any>(null);

    const playerRef = useRef<any>(null);

    React.useEffect(() => {
      window.addEventListener('resize', setImageHeight);
      return () => {
        window.removeEventListener('resize', setImageHeight);
      };
    }, []);

    React.useEffect(() => {
      if (started && currentImageId !== imageSource.internalId) {
        setCurrentImageId(imageSource.internalId);
        if (currentImageUrl === imageSource.contentUrl && !loaded) {
          setLoaded(true);
        }
        setCurrentImageUrl(imageSource.contentUrl || '');
        setPlaying(true);
      }
    }, [imageSource, started]);

    React.useEffect(() => {
      if (playedSeconds >= imageSource.durationInSeconds && playerRef.current) {
        clearInterval(playerRef.current);
        setLoaded(false);
        finishPlayback();
      }
    }, [playedSeconds]);

    React.useEffect(() => {
      console.log('before interval', playerRef.current, playing, loaded);
      if (!playerRef.current && playing && loaded) {
        console.log('starting interval');
        playerRef.current = setInterval(() => {
          setPlayedSeconds(prevState => {
            return prevState + 1;
          });
        }, 1000);
      }
      if (playerRef.current && !playing) {
        clearInterval(playerRef.current);
        playerRef.current = null;
      }
    }, [playing, loaded]);

    const setImageHeight = () => {
      if (containerRef && containerRef.current) {
        setWidth(containerRef.current.offsetWidth);
        onWidthCalculated(containerRef.current.offsetWidth);
      }
    };

    React.useLayoutEffect(setImageHeight, []);

    const finishPlayback = () => {
      setPlaying(false);
      setPlayedSeconds(0);
      playerRef.current = null;
      onFinished();
    };

    const togglePlaying = () => {
      setPlaying(!playing);
    };

    return (
      <div
        className="ImagePlayerContainer"
        ref={containerRef}
        style={{height: width / 1.77}}>
        <img src={imageSource.contentUrl} onLoad={() => setLoaded(true)} />
        <span
          style={{
            backgroundImage: `url(${imageSource.contentUrl})`,
          }}></span>
        {!hideControls && (
          <button
            className="SmallPlayButton"
            onClick={togglePlaying}
            style={{bottom: '10px'}}>
            <img
              src={playing ? pauseControlsIcon : playControlsIcon}
              alt="play"
              className="Icon"
            />
          </button>
        )}
      </div>
    );
  },
);

export default ImagePlayer;
