import {inject, observer} from 'mobx-react';
import React, {useEffect, useState} from 'react';
import GenerateAIAssistantUI from '../view/GenerateAIAssistantUI';
import GenerateAiAssistantStore from '../store/GenerateAiAssistantStore';
import NavigationStore from '../../../../navigation/store/NavigationStore';
import {
  AiAssistantEditModel,
  CreateAiAssistantModel,
  FetchCoursesShortListModel,
} from '../../../../data/models/AI/AiAssistantModels';
import {OrganizationDetails} from '../../../../data/models/Oganization/Organization';
import {useNavigate, useParams} from 'react-router-dom';

interface Props {
  generateAiAssistantStore?: GenerateAiAssistantStore;
  navigationStore?: NavigationStore;
}

const EditAIAssistantContainer = inject(
  'generateAiAssistantStore',
  'navigationStore',
)(
  observer(({generateAiAssistantStore, navigationStore}: Props) => {
    const [search, setSearch] = useState('');
    const [courses, setCourses] = useState<FetchCoursesShortListModel[]>([]);
    const [myOrganization, setMyOrganization] = useState<OrganizationDetails>();
    const [assistant, setAssistant] = useState<AiAssistantEditModel>();

    const {organizationId, assistantId} = useParams();
    const navigate = useNavigate();

    const fetchCourses = async (text: string): Promise<void> => {
      try {
        if (organizationId) {
          const data = await generateAiAssistantStore!.getCoursesList(
            organizationId,
            text,
          );
          setCourses(data);
        }
      } catch (error) {
        console.error('Error fetching courses:', error);
      }
    };

    useEffect(() => {
      fetchCourses(search);
      fetchAssistant();
      if (navigationStore!.userOrganization) {
        setMyOrganization(navigationStore!.userOrganization);
      }
    }, [organizationId]);

    useEffect(() => {
      if (search) {
        fetchCourses(search);
      }
    }, [search]);

    const fetchAssistant = () => {
      if (organizationId && assistantId) {
        generateAiAssistantStore!
          .getAssistantForEditing(organizationId, assistantId)
          .then(setAssistant);
      }
    };

    const onSave = async (model: CreateAiAssistantModel, avatar?: File) => {
      if (organizationId && assistantId) {
        const success = await generateAiAssistantStore!.editAiAssistant(
          organizationId,
          model,
          assistantId,
          avatar,
        );
        if (success) {
          onCancel();
        }
      }
    };

    const onCancel = () => {
      navigate(`/manage/${organizationId}/ai/ai_assistant`, {replace: true});
    };

    return (
      <GenerateAIAssistantUI
        courses={courses}
        onSave={onSave}
        onCancel={onCancel}
        courseSearch={search}
        setSearchText={setSearch}
        isLoading={generateAiAssistantStore!.isLoading}
        organizationAvatar={myOrganization?.avatar}
        editing
        assistant={assistant}
      />
    );
  }),
);

export default EditAIAssistantContainer;
