import React from 'react';
import {
  FILE_ORIGIN_UPLOADED,
  getIconByFileType,
  MediaFile,
} from '../../../../data/models/LibraryFile/MediaFile';
import TooltipQuestionMark from '../../../../uiToolkit/TooltipQuestionMark';
import {generateAiAssistantText} from '../generateAiAssisntantText';

interface Props {
  files: MediaFile[];
  onBrowse: () => void;
  onRemoveFile: (files: MediaFile[]) => void;
  disabled?: boolean;
}

const SpecialLibraryFilesInput = React.memo(
  ({onBrowse, files, onRemoveFile, disabled}: Props) => {
    const getColorByFileType = (file: MediaFile) => {
      if (file.fileType.includes('image')) {
        return '#DDE9FF';
      }
      if (
        file.fileType.includes('video') &&
        file.origin === FILE_ORIGIN_UPLOADED
      ) {
        return '#D9F9E6';
      }
      if (file.fileType.includes('video')) {
        return '#FBF2CB';
      }
      return '#DDE9FF';
    };

    const spliceFileFromArray = (file: MediaFile) => {
      const newFiles = [...files];
      const index = newFiles.findIndex(f => f.id === file.id);
      if (index > -1) {
        newFiles.splice(index, 1);
      }
      if (newFiles.length > 0) {
        onRemoveFile(newFiles);
      } else {
        onRemoveFile([]);
      }
    };

    return (
      <>
        <div className="LibraryFilesButtonContainer">
          <button
            onClick={disabled ? undefined : onBrowse}
            className={disabled ? 'DisabledInput' : ''}>
            Knowledge base configuration.
          </button>
          <span>*</span>
          <TooltipQuestionMark
            text={generateAiAssistantText.knowledgeBaseTooltip}
          />
        </div>
        <div className="LibraryFilesInputContainer">
          <div className="FilesList">
            {files.map(file => (
              <div
                key={file.id}
                className="FileItem"
                style={{backgroundColor: getColorByFileType(file)}}>
                <span
                  className={`${getIconByFileType(file)} FileIndicator`}></span>
                <p>{file.fileName}</p>
                {!disabled && (
                  <span
                    className="icon-x-circle RemoveIndicator"
                    onClick={() => spliceFileFromArray(file)}></span>
                )}
              </div>
            ))}
          </div>
        </div>
      </>
    );
  },
);

export default SpecialLibraryFilesInput;
