import React from 'react';
import {
  FILE_ORIGIN_UPLOADED,
  getIconByFileType,
  MediaFile,
} from '../../../data/models/LibraryFile/MediaFile';
import Button from '../../Buttons/Button';
import {appText} from '../../../assets/text/appText';
import './styles.css';
import Tooltip from '../../Tooltip';

interface Props {
  files: MediaFile[];
  onBrowse: () => void;
  onRemoveFile: (files?: MediaFile[]) => void;
}

const LibraryFilesInput = React.memo(
  ({onBrowse, files, onRemoveFile}: Props) => {
    const getColorByFileType = (file: MediaFile) => {
      if (file.fileType.includes('image')) {
        return '#DDE9FF';
      }
      if (
        file.fileType.includes('video') &&
        file.origin === FILE_ORIGIN_UPLOADED
      ) {
        return '#D9F9E6';
      }
      if (file.fileType.includes('video')) {
        return '#FBF2CB';
      }
      return '#DDE9FF';
    };

    const spliceFileFromArray = (file: MediaFile) => {
      const newFiles = [...files];
      const index = newFiles.findIndex(f => f.id === file.id);
      if (index > -1) {
        newFiles.splice(index, 1);
      }
      if (newFiles.length > 0) {
        onRemoveFile(newFiles);
      } else {
        onRemoveFile(undefined);
      }
    };

    return (
      <>
        {!files.length ? (
          <Button
            title={appText.browse}
            onClick={onBrowse}
            uiType="hollow_box"
          />
        ) : (
          <div className="LibraryFilesInputContainer">
            <div className="FilesList">
              {files.map(file => (
                <Tooltip tooltipText={file.fileName} key={file.id}>
                  <div
                    className="FileItem"
                    style={{backgroundColor: getColorByFileType(file)}}>
                    <span
                      className={`${getIconByFileType(
                        file,
                      )} FileIndicator`}></span>
                    <p>{file.fileName}</p>

                    <span
                      className="icon-x-circle RemoveIndicator"
                      onClick={() => spliceFileFromArray(file)}></span>
                  </div>
                </Tooltip>
              ))}
            </div>
            <div className="BrowseSection">
              <Button
                title={appText.browse}
                onClick={onBrowse}
                uiType="hollow_box"
              />
            </div>
          </div>
        )}
      </>
    );
  },
);

export default LibraryFilesInput;
