import {makeAutoObservable} from 'mobx';
import LoadingModule from '../../LoadingModule/LoadingModule';
import {AIRepository} from '../../../data/repositories/AiRepository';
import {toast} from 'react-toastify';
import {getErrorFromCode} from '../../../data/errorCodes';
import {toastConfig} from '../../../uiToolkit/Toast/toastConfig';
import {aiAssitantChatbotText} from '../aiAssistantChatbotText';

class AiAssistantChatbotStore {
  private aiRepo = new AIRepository();
  private aiAssistantId?: string;

  private loadingModule = new LoadingModule();
  private sendingMessageLoading = new LoadingModule();

  constructor() {
    makeAutoObservable(this);
  }

  public get currentAssistant() {
    return this.aiAssistantId;
  }

  public get shouldShowChatbot() {
    return this.aiAssistantId !== undefined;
  }

  public get isSendingMessage() {
    return this.sendingMessageLoading.isLoading;
  }

  public showChatbot(aiAssistantId: string) {
    this.aiAssistantId = aiAssistantId;
  }

  public closeChatbot() {
    this.aiAssistantId = undefined;
  }

  public get isLoading() {
    return this.loadingModule.isLoading;
  }

  public async getAssistantChat(organizationId: string) {
    if (!this.aiAssistantId) return;
    this.loadingModule.startLoading();
    const result = await this.aiRepo.getAssistantChat(
      organizationId,
      this.aiAssistantId,
    );
    this.loadingModule.endLoading();
    if (result.success && result.data) {
      return result.data;
    }
  }

  public async sendMessage(
    organizationId: string,
    message: string,
    learnerView?: boolean,
  ) {
    if (!this.aiAssistantId) return;
    this.sendingMessageLoading.startLoading();
    const result = await this.aiRepo.sendAssistantChat(
      organizationId,
      this.aiAssistantId,
      message,
    );
    this.sendingMessageLoading.endLoading();
    if (result.success && result.data) {
      return result.data;
    } else if (result.errors && result.errors.length) {
      if (learnerView) {
        toast.error(aiAssitantChatbotText.noCreditsForLearner, toastConfig);
      } else {
        toast.error(getErrorFromCode(result.errors[0]), toastConfig);
      }
    }
  }
}

export default AiAssistantChatbotStore;
