import React from 'react';
import {mapFileStatusToText} from '../../conf';

interface Props {
  status: number;
}

const FileStatusDisplay = React.memo(({status}: Props) => {
  const getStepStateByStatus = (step: number) => {
    switch (step) {
      case 1: {
        if (status <= 3) return 'progress';
        return 'finished';
      }
      case 2: {
        if (status <= 3) return 'not_started';
        if (status === 4 || status === 5) return 'progress';
        return 'finished';
      }
      case 3: {
        if (status <= 5) return 'not_started';
        if (status === 6) return 'progress';
        return 'finished';
      }
      case 4: {
        if (status <= 6) return 'not_started';
        if (status >= 7 && status <= 9) return 'progress';
        return 'finished';
      }
      case 5: {
        if (status <= 9) return 'not_started';
        if (status === 10 || status === 11) return 'progress';
        return 'finished';
      }
      default:
        return 'not_started';
    }
  };

  const renderStepIndicator = (step: number) => {
    let classname = 'StepCircle';
    const state = getStepStateByStatus(step);
    if (state === 'progress') {
      classname += ' StepProgress';
    } else if (state === 'finished') {
      classname += ' StepFinished';
    }
    return <div className={classname} />;
  };

  return (
    <div className="FileStatusDisplayContaier">
      <div className="StepsContainer">
        {renderStepIndicator(1)}
        {renderStepIndicator(2)}
        {renderStepIndicator(3)}
        {renderStepIndicator(4)}
        {renderStepIndicator(5)}
      </div>
      <span className="StatusText">{mapFileStatusToText(status)}</span>
    </div>
  );
});

export default FileStatusDisplay;
