import {getLocalizationBasedOnLang} from '../../data/models/User/UserInfo';

const appEnglish = {
  authInfoModalTitle: 'Interactive Video Learning.',
  autnInfoModelBody:
    'Make Learning Fun. Make it tapybl! Tapybl is an AI-powered video learning platform that transforms your existing content into engaging, sophisticated, interactive video lessons in a matter of minutes.',
  passwordValidationLength: 'at least 8 characters',
  passwordValidationNumber: 'at least 1 number',
  passwordValidationCase: 'at least 1 uppercase and 1 lowercase letter',
  passwordValidationCharacter:
    'at least 1 special character, for example # ? ! $ & @',
  upload: 'Upload',
  browse: 'Browse',
  dashboardSettingsTitle: 'Settings',
  cancel: 'Cancel',
  accept: 'Accept',
  active: 'Active',
  invitationSent: 'Invitation Sent',
  inactive: 'Inactive',
  noFileUploaded: 'No file uploaded',
  createCoursePrompt:
    'Please visit your workspace to manage and oversee all courses.',
  createCourseAction: 'Create new course',
  manageCourse: 'Manage Courses',
  noCourses: 'You are not enrolled in any courses.',
  explore: 'Explore',
  help: 'Help',
  filter: 'Filter',
  logoutConfirm: 'Are you sure you want to log out?',
  invitationDeclined: 'Invitation Declined',
  logout: 'Logout',
  search: 'Search',
  back: 'Back',
  addIcon: 'Add icon',
  replaceIcon: 'Replace icon',
  nextAttempt: (time: number) =>
    `Code not received? Request a new code in ${time} seconds`,
  passwordMustContain: 'Password must contain:',
  noCoursesTitle: 'There are no courses within this workspace yet',
  noCoursesSubtitlte: 'Create your own course and share it with anyone',
  yes: 'Yes',
  no: 'No',
  upgrade: 'Upgrade now!',
  noLibraryTitle: "Oops! It looks like you haven't uploaded any files yet.",
  noLibrarySubtitle: 'Let’s start by uploading your first file here.',
  minimumCharacters: (minCharactes: number) =>
    `*minumum of ${minCharactes} characters`,
  saveChanges: 'Save Changes',
  apply: 'Apply',
  publishCourse: 'Publish Course',
  publishedCourse: 'Published Course',
  clearSearch: 'Clear Search',
  learners: 'Learners',
  generating: 'Generating your results',
  lessonAlreadyPublished: 'Cannot delete items from published lesson',
  copiedToClipboard: 'Copied to clipboard',
  exportAsScorm: 'Export as SCORM',
  deleteThisNode: 'Delete Selected Node Only',
  deleteThisNodeAndChildren: 'Delete Selected Node and Children',
  pleaseWait: 'Please Wait...',
  dontShowAgain: 'Don’t ask me again',
  continue: 'Continue',
  tapyblTips: 'Tapybl Tips',
  replaceSourceTitle:
    'Replacing the current video source will require revising any existing child nodes linked to it.',
  replaceSourceConfirmation:
    'Do you want to continue knowing these changes are necessary?',
  sidebarCourseName: 'Courses',
  usersPerMonth: 'Registered user per month',
  showDetails: 'For a full plan comparison',
  clickHere: ' click here',
  currentPlan: 'Current Plan',
  instructorNoCoursesTitle:
    "You haven't been assigned as the instructor for any courses yet.",
  instructorNoCoursesSubtitle:
    "Once you're assigned to a course, it will appear here.",
  renew: 'Renew Subscription',
  invalidHEXValue: 'Invalid HEX color value',
  sort: 'Sort',
  on: 'On',
  off: 'Off',
};

const appWelsh = {
  authInfoModalTitle: 'Dysgu Fideo Rhyngweithiol',
  autnInfoModelBody:
    "Gan ddarparu'n benodol ar gyfer addysg a DPP, mae tapybl ar genhadaeth i godi canlyniadau dysgu byd-eang trwy ddysgu fideo rhyngweithiol personol sy'n ddeniadol i'r llygad.",
  passwordValidationLength: ' o leiaf wyth cymeriad',
  passwordValidationNumber: 'o leiaf un rhif',
  passwordValidationCase: 'o leiaf un prif lythyren ac un llythyren fach',
  passwordValidationCharacter:
    'o leiaf 1 nod arbennig, er enghraifft # ? ! $ & @',
  upload: 'uwchlwytho',
  browse: 'Pori',
  dashboardSettingsTitle: 'Gosodiadau',
  cancel: 'Canslo',
  accept: 'Derbyn',
  active: 'Gweithredol',
  invitationSent: 'Anfonwyd gwahoddiad',
  inactive: 'Anactif',
  noFileUploaded: "Dim ffeil wedi'i uwchlwytho",
  createCoursePrompt: "Ymwelwch â'ch Gweithle i reoli a goruchwylio pob cwrs",
  createCourseAction: 'Creu cwrs newydd',
  manageCourse: 'Rheoli cyrsiau',
  noCourses: 'Nid ydych yn cofrestru ar unrhyw gyrsiau',
  explore: 'Archwiliwch',
  help: 'Cymorth',
  filter: 'Hidlo',
  logoutConfirm: "Ydych chi'n siŵr eich bod am allgofnodi",
  invitationDeclined: 'Gwrthodwyd y gwahoddiad',
  logout: 'Allgofnodi',
  search: 'Chwiliwch',
  back: 'Yn ol',
  addIcon: 'Ychwanegwch eicon',
  replaceIcon: 'Amnewid yr eicon',
  nextAttempt:
    'Cod heb ei dderbyn? Gofynnwch am god newydd ymhen ${amser} eiliad',
  passwordMustContain: "Rhaid i'r cyfrinair gynnwys",
  noCoursesTitle: 'Nid oes unrhyw gyrsiau yn y Gweithle hwn eto',
  noCoursesSubtitlte: "Creu eich cwrs eich hun a'i rannu ag unrhyw un",
  yes: 'Oes',
  no: 'Nac oes',
  upgrade: 'Uwchraddio nawr',
  noLibraryTitle: "Croeso i'ch llyfrgell",
  noLibrarySubtitle: "Gadewch i ni ddechrau trwy uwchlwytho'ch ffeiliau",
  welcomeTitle: 'Croeso ar fwrdd!',
  welcomeSubtitle: 'Mae eich cyfrif yn dda i fynd',
  minimumCharacters: '*lleiafswm o ${ lleiafswmCymeriadau} cymeriadau',
  saveChanges: 'Cadw newidiadau',
  apply: 'Ymgeisiwch',
  publishCourse: 'Cyhoeddi cwrs',
  publishedCourse: 'Cwrs cyhoeddedig',
  clearSearch: 'I glirio chwiliad',
  learners: 'Dysgwyr',
  generating: 'Cynhyrchu eich canlyniadau',
  lessonAlreadyPublished: "Methu dileu eitemau o'r wers gyhoeddedig",
  copiedToClipboard: "wedi'i gopïo i'r clipfwrdd",
  exportAsScorm: 'Allforio fel SCORM',
  deleteThisNode: "Dileu'r Nod a Ddewiswyd yn Nnig",
  deleteThisNodeAndChildren: 'Dileu Nod a Phlant Dethol',
  pleaseWait: 'Os gwelwch yn dda Aros',
  dontShowAgain: 'Peidiwch â gofyn i mi eto',
  continue: 'Parhau',
  tapyblTips: 'Awgrymiadau tapybl',
  replaceSourceTitle:
    "Bydd angen adolygu unrhyw nodau plentyn presennol sy'n gysylltiedig ag ef er mwyn newid y ffynhonnell fideo gyfredol.",
  replaceSourceConfirmation:
    'Ydych chi am barhau i wybod bod y newidiadau hyn yn angenrheidiol?',
  sidebarCourseName: 'Cyrsiau',
  currentPlan: 'Cynllun Presennol',
  instructorNoCoursesTitle:
    "Nid ydych wedi'ch neilltuo fel hyfforddwr ar gyfer unrhyw gyrsiau eto",
  instructorNoCoursesSubtitle:
    "Unwaith y byddwch wedi'ch neilltuo i gwrs, bydd yn ymddangos yma",
  renew: 'Adnewyddwch danysgrifiad',
  invalidHEXValue: 'Invalid HEX color value',
  sort: 'Sort',
};

export const appText: any = getLocalizationBasedOnLang(appEnglish, appWelsh);
