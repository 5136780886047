import React, {useState} from 'react';
import {Outlet, useLocation} from 'react-router-dom';
import NavBar from '../../../../uiToolkit/NavBar';
import {Col, Container, Row} from 'react-bootstrap';
import './styles.css';
import {helpConfig, navConfig, contactConfig} from '../navConfig';
import NavListItem from '../components/NavListItem';
import {OrganizationDetails} from '../../../../data/models/Oganization/Organization';
import MobileRestriction from '../../../../modules/MobileRestriction';
import {CreateOrganization} from '../../../../modules/CreateOrganization';
import OrganizationTitle from '../components/OrganizationTItle';

interface Props {
  permissions: string[];
  organization?: OrganizationDetails;
  organizationId?: string;
  hasSubscription?: boolean;
  isDefaultOrgData: boolean;
}

const DashboardRootUI = React.memo(
  ({
    permissions,
    organizationId,
    organization,
    hasSubscription,
    isDefaultOrgData,
  }: Props) => {
    const location = useLocation();
    const [navHeight, setNavHeight] = useState(0);

    const [menuIsOpen, setMenuIsOpen] = useState(true);

    return (
      <div className="DashboardRoot">
        <NavBar onHeightCalculated={setNavHeight} />
        <CreateOrganization
          isOpen={isDefaultOrgData}
          onClose={() => {
            //TODO
          }}
        />
        <Container fluid>
          <Row>
            <Col
              lg={menuIsOpen ? 2 : 1}
              className="DashboardRootSideNav d-none d-lg-block"
              style={{height: `calc(100vh - ${navHeight}px)`}}>
              <div className="NavItemsList">
                {organization && (
                  <OrganizationTitle
                    organization={organization}
                    menuIsOpen={menuIsOpen}
                  />
                )}
                <div className="MenuCollapseContainer">
                  <button
                    onClick={() => setMenuIsOpen(!menuIsOpen)}
                    className={`MenuCollapse ${
                      menuIsOpen ? 'MenuOpen' : 'MenuClosed'
                    }`}>
                    <span className="icon-square-arrow"></span>
                  </button>
                </div>
                {navConfig(organizationId).map(item =>
                  !item.reqireSubscription ||
                  (item.reqireSubscription && hasSubscription) ? (
                    <NavListItem
                      {...item}
                      activeRoute={location.pathname}
                      key={item.title}
                      userPermissions={permissions}
                      collapsed={!menuIsOpen}
                    />
                  ) : null,
                )}
              </div>
              <div className="Divider" />
              <NavListItem
                {...helpConfig}
                activeRoute={location.pathname}
                key={helpConfig.title}
                userPermissions={permissions}
                collapsed={!menuIsOpen}
                newTab
              />
              <NavListItem
                {...contactConfig}
                activeRoute={location.pathname}
                key={contactConfig.title}
                userPermissions={permissions}
                collapsed={!menuIsOpen}
                newTab
              />
            </Col>
            <Col
              lg={menuIsOpen ? 10 : 11}
              xs={12}
              className="DashboardRootContent d-none d-lg-block px-4"
              style={{height: `calc(100vh - ${navHeight}px)`}}>
              <Outlet />
            </Col>
            <Col
              xs={12}
              className="DashboardRootContent d-lg-none d-block px-4"
              style={{height: `calc(100vh - ${navHeight}px)`}}>
              <MobileRestriction />
            </Col>
          </Row>
        </Container>
      </div>
    );
  },
);

export default DashboardRootUI;
