import React from 'react';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import DownloadTranscriptButton from './DownloadTranscriptButton';
import {videoSourcesText} from '../../videoSourcesText';
import {VideoTranscriptSegment} from '../../../../data/models/LibraryFile/MediaFile';
import ModalContainer from '../../../../uiToolkit/ModalContainer';

const transcriptTooltip = (
  <Tooltip>{videoSourcesText.downloadTranscript}</Tooltip>
);

interface Props {
  onClose: () => void;
  transcriptions: VideoTranscriptSegment[];
}

const DownloadTranscriptionModal = React.memo(
  ({transcriptions, onClose}: Props) => {
    return (
      <ModalContainer onClose={onClose}>
        <div className="DownloadTranscriptContainer">
          <h2 className="TranscriptTitle">
            {videoSourcesText.downloadVideoTranscription}
          </h2>
          {transcriptions.map((transcription, index) => (
            <div key={index} className="TranscriptItem">
              <span>
                transcript_{transcription.segmentStart}seconds-
                {transcription.segmentEnd}seconds.csv
              </span>
              <OverlayTrigger placement="bottom" overlay={transcriptTooltip}>
                <DownloadTranscriptButton
                  data={transcription.transcript}
                  filename={`transcript_${transcription.segmentStart}seconds-${transcription.segmentEnd}seconds`}
                />
              </OverlayTrigger>
            </div>
          ))}
        </div>
      </ModalContainer>
    );
  },
);

export default DownloadTranscriptionModal;
