export const parseStringArrayToInt = (stringInput: string) => {
  const parts = stringInput.split(',');
  const resultingArray: number[] = [];

  parts.forEach(part => {
    if (part.includes('-')) {
      const startEndRange = part.split('-');
      if (startEndRange.length === 2) {
        for (
          let i = parseInt(startEndRange[0], 10);
          i <= parseInt(startEndRange[1], 10);
          i++
        ) {
          resultingArray.push(i);
        }
      }
    } else {
      resultingArray.push(parseInt(part, 10));
    }
  });
  return resultingArray.filter(item => !isNaN(item) && item !== 0);
};

export function capitalizeFirstLetter(val: string) {
  return String(val).charAt(0).toUpperCase() + String(val).slice(1);
}
