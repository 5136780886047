import React, {useState} from 'react';
import NavBar from '../../../uiToolkit/NavBar';
import {Col, Container, Row} from 'react-bootstrap';
import PlaylistItem from '../components/PlaylistItem';
import VideoPlaybackDetails from '../components/VideoPlaybackDetails';
import {LessonPresentation} from '../../../data/models/Course/LessonPresentation';
import {GradeResult} from '../models';
import PreviewHeader from '../components/PreviewHeader';
import {TapyblPlayer} from '../../../modules/TapyblPlayer';
import {LearnerProgressModel} from '../../../data/models/LessonContent/CreateLearnerLessonModel';
import './styles.css';
import {LearnerAiAssistants} from '../../../modules/LearnerAiAssistants';

interface Props {
  presentation: LessonPresentation;
  currentLessonId: string;
  gradeResult?: GradeResult;
  preview?: boolean;
  onBack: () => void;
  onCourseHome: () => void;
  onContinue: () => void;
  isLoading?: boolean;
  nextLessonId?: string;
  finishedLessons: string[];
  onOpenLesson: (lessonId: string) => void;
  passingScore?: number;
  onFinishedLesson: (result: LearnerProgressModel) => void;
  navigateToLesson: (lessonId: string) => void;
}

const VideoPlaybackUI = React.memo(
  ({
    presentation,
    currentLessonId,
    gradeResult,
    preview,
    onBack,
    onCourseHome,
    onContinue,
    isLoading,
    nextLessonId,
    finishedLessons,
    onOpenLesson,
    passingScore,
    onFinishedLesson,
    navigateToLesson,
  }: Props) => {
    const [navHeight, setNavHeight] = useState(0);

    const getNonSupplementalLessons = () => {
      return presentation.lessons
        .filter(x => !x.isSupplementalLesson)
        .sort((x, y) => (x.order > y.order ? 1 : -1));
    };

    const getLessonIsNextInList = (lessonId: string) => {
      const lessons = getNonSupplementalLessons().sort((x, y) =>
        x.order > y.order ? 1 : -1,
      );
      const lessonIndex = lessons.findIndex(lesson => lesson.id === lessonId);
      if (lessonIndex >= 0 && lessonIndex - 1 >= 0) {
        return finishedLessons.includes(lessons[lessonIndex - 1].id);
      }
      return false;
    };

    return (
      <div className="VideoPlaybackContainer">
        <NavBar onHeightCalculated={setNavHeight} />
        <div
          className="VideoPlaybackScrollingContianer"
          style={{
            height: `calc(100vh - ${navHeight}px)`,
          }}>
          <Container>
            {preview && <PreviewHeader onBack={onBack} />}
            <Row>
              <Col lg={10} md={12} className="pt-4 player_container">
                <TapyblPlayer
                  preview={preview || false}
                  isLoading={isLoading || false}
                  lessonContent={presentation.lessonContent}
                  onContinue={onContinue}
                  onCourseHome={onCourseHome}
                  passingScore={passingScore || 0}
                  onFinishedLesson={onFinishedLesson}
                  isGradable={
                    presentation.lessons.find(
                      item => item.id === currentLessonId,
                    )?.isGradable || false
                  }
                  gradeResult={gradeResult}
                  nextLessonIsTheSame={nextLessonId === currentLessonId}
                  nextLessonId={nextLessonId}
                  navigateToLesson={navigateToLesson}
                />
                <VideoPlaybackDetails
                  lessonPresentation={presentation}
                  currentLessonId={currentLessonId}
                />
              </Col>
              <Col lg={2} md={12} className="pt-4">
                {!preview && (
                  <div className="VideoPlaybabackPlaylist">
                    <h2 className="Title">{presentation.folder.name}</h2>
                    {getNonSupplementalLessons().map((item, index) => (
                      <PlaylistItem
                        item={item}
                        key={item.id}
                        currentlyPlayingId={currentLessonId}
                        isLast={
                          index === getNonSupplementalLessons().length - 1
                        }
                        completed={finishedLessons.includes(item.id)}
                        onClick={() => onOpenLesson(item.id)}
                        isNext={getLessonIsNextInList(item.id)}
                      />
                    ))}
                  </div>
                )}
              </Col>
            </Row>
          </Container>
        </div>
        {!preview && <LearnerAiAssistants courseId={presentation.course.id} />}
      </div>
    );
  },
);

export default VideoPlaybackUI;
