export const getFirstCapitals = (text: string) => {
  if (text) {
    const parts = text.split(' ');
    let result = '';
    if (parts[0]) {
      result += parts[0][0].toUpperCase();
    }
    if (parts[1]) {
      result += parts[1][0].toUpperCase();
    }
    return result;
  }
  return '';
};

export const numberWithCommas = (x: string) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
