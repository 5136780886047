import React, {useRef, useState} from 'react';
import {isImageFile} from '../../data/models/LibraryFile/VideoSourceShort';
import SimplePlayerControlled from '../../uiToolkit/SimplePlayerControlled';
import './styles.css';

interface Props {
  onClose: () => void;
  fileUrl: string;
  fileType: string;
}

const MediaPreview = React.memo(({onClose, fileType, fileUrl}: Props) => {
  const [width, setWidth] = useState(0);
  const containerRef = useRef<any>(null);

  React.useEffect(() => {
    window.addEventListener('resize', setPlayerDimentions);
    return () => {
      window.removeEventListener('resize', setPlayerDimentions);
    };
  }, []);

  React.useLayoutEffect(() => {
    setPlayerDimentions();
  }, []);

  const setPlayerDimentions = () => {
    if (containerRef && containerRef.current) {
      setWidth(containerRef.current.offsetWidth);
    }
  };

  return (
    <div className="PreviewPlayerModal">
      <div className="PreviewPlayerContent">
        {isImageFile(fileType) ? (
          <div className="PreviewImageContainer">
            <img src={fileUrl} />
          </div>
        ) : (
          <div className="PreviewPlayerContainer" ref={containerRef}>
            <SimplePlayerControlled source={fileUrl} width={width} dontLoop />
          </div>
        )}
        <button className="CloseButton" onClick={onClose}>
          <span className="icon-x-close"></span>
        </button>
      </div>
    </div>
  );
});

export default MediaPreview;
