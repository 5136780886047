import React from 'react';
import {TapyblPlayer} from '../../../../modules/TapyblPlayer';
import {LessonContentList} from '../../../../data/models/LessonContent/LessonContentList';
import {GradeResult} from '../../../../modules/TapyblPlayer/models';
import {LearnerProgressModel} from '../../../../data/models/LessonContent/CreateLearnerLessonModel';
import './styles.css';

interface Props {
  isLoading: boolean;
  lessonContent: LessonContentList;
  onFinish: () => void;
  passingScore: number;
  isGradable: boolean;
  gradeResult?: GradeResult;
  onFinishLesson: (progress: LearnerProgressModel) => void;
  navigateToLesson: (lessonId: string) => void;
}

const LTIPlayerUI = React.memo(
  ({
    isLoading,
    lessonContent,
    onFinish,
    passingScore,
    isGradable,
    gradeResult,
    onFinishLesson,
    navigateToLesson,
  }: Props) => {
    const getVideoHeight = () => {
      if (window.innerHeight < window.innerWidth) {
        return window.innerHeight;
      } else if (window.innerHeight > window.innerWidth) {
        return (window.innerWidth * 9) / 16;
      }
    };

    const getVideoWidth = () => {
      if (window.innerHeight < window.innerWidth) {
        return (window.innerHeight * 16) / 9;
      } else if (window.innerHeight > window.innerWidth) {
        return window.innerWidth;
      }
    };

    return (
      <div className="LTIPlayerContainer">
        <div
          className="LTIPlayerSize"
          style={{
            width: getVideoWidth(),
            height: getVideoHeight(),
          }}>
          <TapyblPlayer
            preview={false}
            isLoading={isLoading}
            lessonContent={lessonContent}
            onCourseHome={onFinish}
            onContinue={onFinish}
            passingScore={passingScore}
            isGradable={isGradable}
            nextLessonIsTheSame={false}
            gradeResult={gradeResult}
            onFinishedLesson={onFinishLesson}
            navigateToLesson={navigateToLesson}
            fitHeight
            skipFinishButtons
          />
        </div>
      </div>
    );
  },
);

export default LTIPlayerUI;
