import {
  AiAssistantChatHistory,
  AiAssistantListItem,
  AiAssitantChatMessage,
  CreateAiAssistantModel,
  CreateAiAssistantResponse,
  FetchCoursesShortListModel,
  UploadAIAvatarModel,
} from '../models/AI/AiAssistantModels';
import {
  VideoCostEstimationModel,
  VideoEstimationResponse,
} from '../models/AI/VideoGenerationConfig';
import {ApiResponse, PaginatedApiReponse} from '../models/ApiResponse';
import {
  GeneratedChallengeBranch,
  GeneratedQuestions,
} from '../models/QuestionGeneration/GeneratedQuestions';
import {apiClient, withAuth} from './baseApiClient';

class QuestionGenerationAPI {
  public async generateQuestions(
    organizationId: string,
    videoNodeId: string,
    topic: string,
    lessonId: string,
    difficulty: string,
  ): Promise<ApiResponse<GeneratedQuestions>> {
    const headers = await withAuth({});
    return apiClient.post(
      `/videoQuestions/${organizationId}/generate`,
      {videoNodeId, topics: [topic], lessonId, qaDifficultyLevel: difficulty},
      {headers},
    );
  }

  public async generateChallangeBranchAI(
    organizationId: string,
    lessonId: string,
    videoNodeId: string,
  ): Promise<ApiResponse<GeneratedChallengeBranch>> {
    const headers = await withAuth({});
    return apiClient.post(
      `/videoQuestions/${organizationId}/lesson/${lessonId}/videoNode/${videoNodeId}/generate_masks_and_questions`,
      {},
      {headers},
    );
  }

  public async getVideoCostEstimation(
    organizationId: string,
    model: VideoCostEstimationModel,
  ): Promise<ApiResponse<VideoEstimationResponse>> {
    return apiClient.post(
      `/videoQuestions/${organizationId}/get_ai_credits_estimation`,
      model,
    );
  }

  public async createAiAssistant(
    organizationId: string,
    model: CreateAiAssistantModel,
  ): Promise<ApiResponse<CreateAiAssistantResponse>> {
    return apiClient.post(
      `/videoQuestions/${organizationId}/ai_assistant/create`,
      model,
    );
  }

  public async editAiAssistant(
    organizationId: string,
    model: CreateAiAssistantModel,
    assistantId: string,
  ): Promise<ApiResponse<CreateAiAssistantResponse>> {
    return apiClient.post(
      `/videoQuestions/${organizationId}/ai_assistant/update/${assistantId}`,
      model,
    );
  }

  public async fetchCoursesShortList(
    organizationId: string,
    search: string,
  ): Promise<ApiResponse<FetchCoursesShortListModel[]>> {
    return apiClient.get(
      `/course/${organizationId}/simple_list?search=${search}`,
    );
  }

  public async uploadAIAvatar(
    organizationId: string,
    avatar: File,
    assistantId: string,
  ): Promise<ApiResponse<UploadAIAvatarModel[]>> {
    const form = new FormData();
    form.append('avatar', avatar);
    form.append('assistantId', assistantId);
    return apiClient.post(
      `/videoQuestions/${organizationId}/ai_assistant/upload_avatar`,
      form,
    );
  }

  public async getAiAssistantsList(
    organizationId: string,
    page: number,
    pageSize: number,
  ): Promise<PaginatedApiReponse<AiAssistantListItem>> {
    return apiClient.post(
      `/videoQuestions/${organizationId}/ai_assistant/list`,
      {page, pageSize},
    );
  }

  public async toggleAiAssitantVisibility(
    organizationId: string,
    assistantId: string,
  ): Promise<ApiResponse<any>> {
    return apiClient.post(
      `/videoQuestions/${organizationId}/ai_assistant/${assistantId}/toggle_visibility`,
    );
  }

  public async deleteAiAssistnat(
    organizationId: string,
    assistantId: string,
  ): Promise<ApiResponse<any>> {
    return apiClient.delete(
      `/videoQuestions/${organizationId}/ai_assistant/${assistantId}`,
    );
  }

  public async getAssistantChat(
    organizationId: string,
    assistantId: string,
  ): Promise<ApiResponse<AiAssistantChatHistory>> {
    return apiClient.get(
      `/videoQuestions/${organizationId}/ai_assistant/${assistantId}/history`,
    );
  }

  public async sendAssistantChat(
    organizationId: string,
    assistantId: string,
    question: string,
  ): Promise<
    ApiResponse<{
      chat_history: AiAssitantChatMessage[];
    }>
  > {
    return apiClient.post(
      `/videoQuestions/${organizationId}/ai_assistant/chat`,
      {question, assistantId},
    );
  }
}

export default QuestionGenerationAPI;
