import React, {useRef} from 'react';
import './styles.css';
import {videoSourcesText} from '../../videoSourcesText';
import UploadVideoButton from '../../../../uiToolkit/Inputs/UploadVideoButton';
import PageTitle from '../../../../uiToolkit/PageTitle';
import {
  ALL_MEIDAFILE_FILTERS,
  getMediaFilterOptions,
  MEDIA_FILES_ALL,
  MediaFile,
} from '../../../../data/models/LibraryFile/MediaFile';
import SearchInput from '../../../../uiToolkit/Inputs/SearchInput';
import FilterPill from '../../../../uiToolkit/FilterPill';
import {Col, Container, Row} from 'react-bootstrap';
import LibraryFileCard from '../components/LibraryFileCard';
import SortPill from '../../../../uiToolkit/SortPill/SortPill';
import {SortOption} from '../../../../data/models/UI/SelectOption';
import LibraryEmptyState from '../../../../uiToolkit/EmptyStates/LibraryEmptyState';
import {LIBRARY_SORT_OPTIONS} from '../../conf';

interface Props {
  onUpload: (video: File) => void;
  isLoading: boolean;
  storageLimit: number;
  files: MediaFile[];
  organizationId: string;
  search: string;
  setSearch: (search: string) => void;
  filter: number;
  setFilter: (filter: number) => void;
  setSort: (option: SortOption) => void;
  sortOptions: SortOption;
  loadNextPage: () => Promise<any>;
  onPreview: (file: MediaFile) => void;
  onEditVideo: (file: MediaFile) => void;
  onDownloadTranscript: (id: string) => Promise<any>;
  onDownloadVideo: (file: MediaFile) => Promise<any>;
  onDelete: (id: string) => void;
}

const LibraryUI = React.memo(
  ({
    onUpload,
    isLoading,
    storageLimit,
    search,
    setSearch,
    filter,
    setFilter,
    files,
    sortOptions,
    setSort,
    loadNextPage,
    onPreview,
    onEditVideo,
    onDownloadTranscript,
    onDownloadVideo,
    onDelete,
  }: Props) => {
    const observer = useRef<any>();

    const overscrollLock = useRef(false);

    const lastPostElementRef = (node: any) => {
      if (isLoading || overscrollLock.current) return;
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting) {
          overscrollLock.current = true;
          loadNextPage().then(() => {
            overscrollLock.current = false;
          });
        }
      });

      if (node) observer.current.observe(node);
    };

    return (
      <div className="VideoSourcesContainer">
        <div className="HeaderContainer">
          <PageTitle title={videoSourcesText.title} />
          <div className="SearchContainer">
            <SearchInput
              value={search}
              onChange={setSearch}
              className="Search"
            />
            <div className="UploadContainer">
              <UploadVideoButton
                onVideoSelected={onUpload}
                type={MEDIA_FILES_ALL}
                maxFileSize={storageLimit}
              />
            </div>
          </div>
        </div>
        <div className="FiltersContainer">
          {ALL_MEIDAFILE_FILTERS.map(option => (
            <FilterPill
              {...getMediaFilterOptions(option)}
              key={option}
              onSelect={setFilter}
              selected={filter === option}
            />
          ))}
          <SortPill
            sortDirection={sortOptions.order}
            sortField={sortOptions.field}
            options={LIBRARY_SORT_OPTIONS}
            onSetSort={(field: string, order: 'asc' | 'desc') =>
              setSort({field, order})
            }
          />
        </div>
        {files.length ? (
          <Container fluid className="LibraryList">
            <Row>
              {files?.map((file, index) => (
                <Col
                  xl={3}
                  lg={4}
                  key={file.id}
                  className="LibraryCol"
                  ref={files.length === index + 1 ? lastPostElementRef : null}>
                  <LibraryFileCard
                    file={file}
                    onPreview={onPreview}
                    onEditVideo={onEditVideo}
                    onDownloadTranscript={onDownloadTranscript}
                    onDownloadVideo={onDownloadVideo}
                    onDelete={onDelete}
                  />
                </Col>
              ))}
            </Row>
          </Container>
        ) : !isLoading ? (
          <LibraryEmptyState onVideoUpload={onUpload} />
        ) : null}
      </div>
    );
  },
);

export default LibraryUI;
