import React, {useEffect} from 'react';
import DashboardRootUI from '../view/DashboardRootUI';
import {inject, observer} from 'mobx-react';
import NavigationStore from '../../../store/NavigationStore';
import {useParams} from 'react-router-dom';
import {ORGANIZATION_SUBSCRIPTION_ACTIVE} from '../../../../data/models/Oganization/Organization';
import UserInfoStore from '../../../../stores/UserInfoStore';

interface Props {
  navigationStore?: NavigationStore;
  userInfoStore?: UserInfoStore;
}

const DashboardRootContainer = inject(
  'navigationStore',
  'userInfoStore',
)(
  observer(({navigationStore, userInfoStore}: Props) => {
    const {organizationId} = useParams();

    useEffect(() => {
      userInfoStore?.fetchUserInfo();
    }, []);

    if (!navigationStore?.userOrganization || !userInfoStore?.userInfo)
      return null;

    return (
      <DashboardRootUI
        permissions={navigationStore!.userPermissions}
        organization={navigationStore!.userOrganization}
        organizationId={organizationId}
        hasSubscription={
          navigationStore!.subscriptionStatus ===
          ORGANIZATION_SUBSCRIPTION_ACTIVE
        }
        isDefaultOrgData={
          navigationStore!.userOrganization?.name ===
          `Organization ${userInfoStore?.userInfo?.name}`
        }
      />
    );
  }),
);

export default DashboardRootContainer;
