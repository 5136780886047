import React, {useEffect, useState} from 'react';
import {myLibraryModalText} from '../myLibraryModalText';
import {
  getMediaFilterOptions,
  MEDIA_FILES_AI_VIDEO,
  MEDIA_FILES_ALL,
  MEDIA_FILES_ALL_VIDEO,
  MEDIA_FILES_DOCUMENT,
  MEDIA_FILES_IMAGE,
  MEDIA_FILES_VIDEO,
  MediaFile,
} from '../../../data/models/LibraryFile/MediaFile';
import UploadVideoButton from '../../../uiToolkit/Inputs/UploadVideoButton';
import LibraryEmptyState from '../../../uiToolkit/EmptyStates/LibraryEmptyState';
import './styles.css';
import TablePagination from '../../../uiToolkit/TablePagination';
import {toast} from 'react-toastify';
import {toastConfig} from '../../../uiToolkit/Toast/toastConfig';
import FileUploadModalBodyContainer from '../../FileUploadModalBody/container/FileUploadModalBodyContainer';
import SearchInput from '../../../uiToolkit/Inputs/SearchInput';
import FilterPill from '../../../uiToolkit/FilterPill';
import {Col, Container, Row} from 'react-bootstrap';
import MediaLibraryCard from '../componenets/MediaLibraryCard';
import Button from '../../../uiToolkit/Buttons/Button';
import SortPill from '../../../uiToolkit/SortPill/SortPill';
import {MEDIA_FILES_PAGE_SIZE, MEDIA_FILES_SORT_OPTIONS} from '../conf';

interface Props {
  onCancel: () => void;
  type: number;
  isLoading: boolean;
  files: MediaFile[];
  onFileSelect: (files: MediaFile[]) => void;
  pages: number;
  onPageChange: (page: number) => void;
  currentPage: number;
  storageLimit: number;
  onSort: (selectedColumn: any, sortOrder: any) => void;
  refreshFiles: () => void;
  search: string;
  setSearch: (search: string) => void;
  selectedFilter: number;
  setSelectedFilter: (filter: number) => void;
  sortOptions: any;
  selectMultiple?: boolean;
  preSelectedFiles: MediaFile[];
}

const MyLibraryModalUI = React.memo(
  ({
    onCancel,
    type,
    isLoading,
    files,
    onFileSelect,
    pages,
    onPageChange,
    currentPage,
    storageLimit,
    onSort,
    refreshFiles,
    search,
    setSearch,
    setSelectedFilter,
    selectedFilter,
    sortOptions,
    selectMultiple,
    preSelectedFiles,
  }: Props) => {
    const [selectedFiles, setSelectedFiles] = useState<MediaFile[]>([]);
    const [uploadedFile, setUploadedFile] = useState<File>();

    useEffect(() => {
      setSelectedFiles(preSelectedFiles);
    }, []);

    const getFilterOptions = () => {
      switch (type) {
        case MEDIA_FILES_ALL:
          return [
            MEDIA_FILES_IMAGE,
            MEDIA_FILES_VIDEO,
            MEDIA_FILES_AI_VIDEO,
            MEDIA_FILES_DOCUMENT,
          ];
        case MEDIA_FILES_ALL_VIDEO:
          return [
            MEDIA_FILES_ALL_VIDEO,
            MEDIA_FILES_VIDEO,
            MEDIA_FILES_AI_VIDEO,
          ];
        default:
          return [type];
      }
    };

    const onSelectFile = (file: MediaFile) => {
      if (file.fileStatus !== 0) {
        toast.info(myLibraryModalText.fileNotReady, toastConfig);
      } else if (selectMultiple) {
        const fileIndex = selectedFiles.findIndex(f => f.id === file.id);
        if (fileIndex >= 0) {
          const updatedFiles = [...selectedFiles];
          updatedFiles.splice(fileIndex, 1);
          setSelectedFiles(updatedFiles);
        } else {
          setSelectedFiles([...selectedFiles, file]);
        }
      } else {
        setSelectedFiles([file]);
      }
    };

    const onConfirmSelection = () => {
      if (selectedFiles.length) {
        onFileSelect(selectedFiles);
      }
    };

    const onFileUploaded = () => {
      setUploadedFile(undefined);
      refreshFiles();
    };

    if (uploadedFile) {
      return (
        <FileUploadModalBodyContainer
          file={uploadedFile}
          onSuccess={onFileUploaded}
          onClose={onFileUploaded}
        />
      );
    }

    return (
      <div className="MyLibraryModal">
        <Container fluid>
          <Row>
            <Col xl={{offset: 3, span: 6}} lg={{offset: 2, span: 8}}>
              <div className="MyLibraryModalContainer">
                <div className="Header">
                  <h1>{myLibraryModalText.title}</h1>
                  <button className="CloseButton" onClick={onCancel}>
                    <span className="icon-x-close"></span>
                  </button>
                </div>
                <div className="SearchContainer">
                  <SearchInput value={search} onChange={setSearch} />
                  <UploadVideoButton
                    onVideoSelected={setUploadedFile}
                    type={type}
                    maxFileSize={storageLimit}
                  />
                </div>
                <div className="d-flex flex-row justify-content-between align-items-center">
                  <div className="FiltersContainer">
                    {getFilterOptions().map(option => (
                      <FilterPill
                        {...getMediaFilterOptions(option)}
                        key={option}
                        onSelect={setSelectedFilter}
                        selected={selectedFilter === option}
                      />
                    ))}
                  </div>
                  <SortPill
                    sortField={sortOptions.column}
                    sortDirection={sortOptions.order}
                    onSetSort={onSort}
                    options={MEDIA_FILES_SORT_OPTIONS}
                  />
                </div>

                <Container className="FilesContainer">
                  <Row>
                    {files.length ? (
                      files.map(file => (
                        <Col xs={4} key={file.id} className="d-flex">
                          <MediaLibraryCard
                            file={file}
                            onSelectFile={onSelectFile}
                            selected={
                              selectedFiles.find(f => f.id === file.id) !==
                              undefined
                            }
                          />
                        </Col>
                      ))
                    ) : (
                      <Col xs={12}>
                        <LibraryEmptyState isLoading={isLoading} type={type} />
                      </Col>
                    )}
                  </Row>
                </Container>
                {files.length ? (
                  <Container>
                    <Row>
                      <Col xs={12}>
                        <TablePagination
                          currentPage={currentPage}
                          rowCount={pages * MEDIA_FILES_PAGE_SIZE}
                          rowsPerPage={9}
                          onChangePage={onPageChange}
                        />
                      </Col>
                    </Row>
                  </Container>
                ) : null}
                {files.length ? (
                  <div className="d-flex flex-row justify-content-center mt-3">
                    <Button
                      uiType="hollow"
                      title={myLibraryModalText.select}
                      disabled={!selectedFiles.length}
                      onClick={onConfirmSelection}
                    />
                  </div>
                ) : null}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  },
);

export default MyLibraryModalUI;
