export interface MediaFile {
  id: string;
  fileName: string;
  fileType: string;
  fileUrl?: string;
  placeholderUrl?: string;
  dateCreated: Date;
  storageFileName: string;
  fileSize: number;
  isUsed: boolean;
  origin?: number;
  status?: number;
  fileStatus?: number;
  hasTranscript?: boolean;
}

export interface VimeoMediaFile {
  url: string;
  placeholderUrl: string;
  duration: number;
  title: string;
}

export interface VideoTranscript {
  start: number;
  end: number;
  text: string;
}

export interface VideoTranscriptSegment {
  segmentStart: number;
  segmentEnd: number;
  transcript: VideoTranscript[];
}

export interface FileOrientation {
  orientation: string;
}

export const MEDIA_FILES_ALL = 0;
export const MEDIA_FILES_VIDEO = 1;
export const MEDIA_FILES_IMAGE = 2;
export const MEDIA_FILES_DOCUMENT = 3;
export const MEDIA_FILES_AI_VIDEO = 4;
export const MEDIA_FILES_AI_IMAGE = 5;
export const MEDIA_FILES_ALL_VIDEO = 6;
export const MEDIA_FILES_ALL_IMAGE = 7;

export const FILE_ORIGIN_UPLOADED = 0;
export const FILE_ORIGIN_GENERATED = 1;
export const FILE_ORIGIN_CONCATENATED = 2;

export const ALL_MEIDAFILE_FILTERS = [
  MEDIA_FILES_ALL,
  MEDIA_FILES_DOCUMENT,
  MEDIA_FILES_IMAGE,
  MEDIA_FILES_VIDEO,
  MEDIA_FILES_AI_VIDEO,
];

export const getMediaFilterOptions = (filter: number) => {
  switch (filter) {
    case MEDIA_FILES_ALL:
      return {
        icon: '',
        id: MEDIA_FILES_ALL,
        title: 'All',
      };
    case MEDIA_FILES_ALL_VIDEO:
      return {
        icon: '',
        id: MEDIA_FILES_ALL_VIDEO,
        title: 'All Videos',
      };
    case MEDIA_FILES_VIDEO:
      return {
        icon: 'icon-icon-video',
        id: MEDIA_FILES_VIDEO,
        title: 'Videos',
      };
    case MEDIA_FILES_IMAGE:
      return {
        icon: 'icon-image',
        id: MEDIA_FILES_IMAGE,
        title: 'Images',
      };
    case MEDIA_FILES_DOCUMENT:
      return {
        icon: 'icon-file',
        id: MEDIA_FILES_DOCUMENT,
        title: 'Documents',
      };
    case MEDIA_FILES_AI_VIDEO:
      return {
        icon: 'icon-ai-video',
        id: MEDIA_FILES_AI_VIDEO,
        title: 'AI-Generated Videos',
      };
    case MEDIA_FILES_AI_IMAGE:
      return {
        icon: 'icon-ai-image',
        id: MEDIA_FILES_AI_IMAGE,
        title: 'AI-Generated Images',
      };
    default:
      return {
        icon: '',
        id: MEDIA_FILES_ALL,
        title: 'All',
      };
  }
};

export const mapUIKeyToResolutionKey = (uiKey: string) => {
  switch (uiKey) {
    case 'SD':
      return 0;
    case 'HD':
      return 1;
    case 'FHD':
      return 2;
    case 'UHD':
      return 3;
    default:
      return 0;
  }
};

export const FILE_SD_OPTION = {
  key: 'SD',
  value: '720 x 480',
  internalKey: 0,
};
export const FILE_HD = {
  key: 'HD',
  value: '1280 x 720',
  internalKey: 1,
};
export const FILE_FHD = {
  key: 'FHD',
  value: '1920 x 1080',
  internalKey: 2,
};
export const FILE_UHD = {
  key: 'UHD',
  value: '3480 x 2160',
  internalKey: 3,
};

export const getIconByFileType = (file: MediaFile) => {
  if (file.fileType.includes('image')) {
    return 'icon-image';
  }
  if (file.fileType.includes('video') && file.origin === FILE_ORIGIN_UPLOADED) {
    return 'icon-icon-video';
  }
  if (file.fileType.includes('video')) {
    return 'icon-ai-video';
  }
  return 'icon-file';
};

export const getFileExtensionByFileType = (file: MediaFile) => {
  switch (file.fileType) {
    case 'image/png':
      return 'png';
    case 'image/jpg':
      return 'jpg';
    case 'image/jpeg':
      return 'jpeg';
    case 'image/svg':
      return 'svg';
    case 'video/mp4':
      return 'mp4';
    case 'application/pdf':
      return 'pdf';
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return 'docx';
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      return 'pptx';
  }
};
