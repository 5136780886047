import React, {useState} from 'react';
import {lessonEditorText} from '../../../lessonEditorText';
import {
  LessonContentChoiceImageOption,
  LessonContentFreeformImageChoice,
} from '../../../../../data/models/LessonContent/LessonContentBase';
import BoxImagePreview from '../../../../../uiToolkit/BoxImagePreview';
import EditorOptionsFooter from '../../../../../uiToolkit/Editor/EditorOptionsFooter';
import TextInput from '../../../../../uiToolkit/Inputs/TextInput';
import YesNoOption from '../../../../../uiToolkit/Inputs/YesNoOption';
import ReplaceNode from '../../ReplaceNode';
import Button from '../../../../../uiToolkit/Buttons/Button';

interface Props {
  entityToEdit: LessonContentChoiceImageOption;
  onSave: (title: string, isCorrect: boolean | null) => void;
  isLoading: boolean;
  onDelete: () => void;
  isPublished?: boolean;
  openEditOverlay: () => void;
  onReplaceImageSource: () => void;
}

const FreeformImageOptionsUI = React.memo(
  ({
    entityToEdit,
    onSave,
    isLoading,
    onDelete,
    isPublished,
    openEditOverlay,
    onReplaceImageSource,
  }: Props) => {
    const [title, setTitle] = useState(
      (entityToEdit as LessonContentFreeformImageChoice).title,
    );
    const [isCorrect, setIsCorrect] = useState(
      (entityToEdit as LessonContentFreeformImageChoice).isCorrect,
    );
    return (
      <div>
        <TextInput
          heading={lessonEditorText.title}
          value={title}
          onChange={setTitle}
          placeholder={lessonEditorText.titlePlaceholder}
          uiType="box"
          className="OptionsInput"
          maxCharacters={100}
        />
        {isCorrect !== null && (
          <YesNoOption
            value={isCorrect}
            onSelect={setIsCorrect}
            title={lessonEditorText.isCorrect}
            uiType="box"
            className="mb-3"
          />
        )}
        <BoxImagePreview
          heading={lessonEditorText.source}
          source={entityToEdit.contentUrl}
          actionTitle={lessonEditorText.editOverlay}
          action={openEditOverlay}
        />
        <ReplaceNode currentNode={entityToEdit} />
        <Button
          title={lessonEditorText.editSource}
          onClick={onReplaceImageSource}
          uiType="box"
          className="w-100 mt-2"
        />
        <EditorOptionsFooter
          onSave={() => onSave(title, isCorrect)}
          saveEnabled={title.trim().length > 0}
          onDelete={onDelete}
          isLoading={isLoading}
          deleteDisabled={isPublished}
        />
      </div>
    );
  },
);

export default FreeformImageOptionsUI;
