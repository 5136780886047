import {getLocalizationBasedOnLang} from '../../../data/models/User/UserInfo';

const english = {
  title: 'Login to your tapybl account',
  forgotPassword: 'forgot password?',
  submit: 'Sign In',
  dontHaveAccount: 'Don’t have an account? ',
  signup: 'Sign up',
  email: 'Email',
  password: 'Password',
  loginWithGoogle: 'Login with Google',
};

const welsh = {
  title: 'Mewngofnodwch i’ch cyfrif tapybl',
  forgotPassword: "Wedi anghofio'ch cyfrinair?",
  submit: 'Mewngofnodwch',
  dontHaveAccount: 'Nid oes gennych gyfrif?',
  signup: 'Cofrestrwch',
  email: 'e-bost',
  password: 'Cyfrinair',
  loginWithGoogle: 'Mewngofnodwch gyda Google',
};

export const loginTextES = {
  title: 'Login to your tapybl account',
  forgotPassword: 'forgot password?',
  submit: 'Sign In',
  dontHaveAccount: 'Don’t have an account? ',
  signup: 'Sign up',
  email: 'Email',
  password: 'Password',
  infoModalTitle: 'Interactive Video Learning.',
  infoModalText:
    'Make Learning Fun. Make it tapybl! Tapybl is an AI-powered video learning platform that transforms your existing content into engaging, sophisticated, interactive video lessons in a matter of minutes.',
};

export const loginText: any = getLocalizationBasedOnLang(english, welsh);
