import React from 'react';
import './styles.css';

interface Props {
  id: any;
  icon: string;
  title: string;
  onSelect: (id: any) => void;
  selected: boolean;
}

const FilterPill = React.memo(
  ({id, icon, title, selected, onSelect}: Props) => {
    return (
      <button
        className={`FilterPill ${selected ? 'FilterPillActive' : ''} ${
          !icon ? 'FilterAll' : ''
        }`}
        onClick={() => onSelect(id)}>
        {icon && <span className={icon}></span>}
        <p>{title}</p>
      </button>
    );
  },
);

export default FilterPill;
