import {getLocalizationBasedOnLang} from '../../data/models/User/UserInfo';

const english = {
  selectHeader: 'Select your preferred resolution:',
  tip: 'Some resolutions may not be available based on the quality of your uploaded video.',
  tip2: 'To access higher resolutions, the original video needs to be of higher quality',
  videoUploaded: 'File Uploaded Successfully',
  fileName: 'Selected File: ',
  upload: 'Start upload',
  uploadingFile: 'Review your upload',
};

const welsh = {
  selectHeader: 'Dewiswch resolfa darged ar gyfer eich fideo',
  tip: 'Efallai na fydd rhai resolfeydd ar gael yn seiliedig ar y fideo a lwythwyd gennych.',
  tip2: "I gael gafael ar resolfeydd uwch, mae angen i'r fideo gwreiddiol fod o ansawdd uwch",
  videoUploaded: "Mae'r ffeil wedi'i llwytho i fyny'n llwyddiannus",
  fileName: 'Ffeil a ddewiswyd',
  upload: 'Llwythwch i fyny',
};

export const fileUploadText = getLocalizationBasedOnLang(english, welsh);
