import React from 'react';
import {loadingLottie} from '../../../assets/lottie';
import Lottie from 'lottie-react';
import './styles.css';
import Tooltip from '../../Tooltip';

type ButtonUIType =
  | 'action'
  | 'secondary'
  | 'text'
  | 'box'
  | 'action_box'
  | 'text_danger'
  | 'action_danger'
  | 'auth_action'
  | 'hollow'
  | 'hollow_box'
  | 'tab'
  | 'tab_active';

interface Props {
  title: string;
  onClick: (e?: any) => void;
  className?: string;
  disabled?: boolean;
  isLoading?: boolean;
  uiType?: ButtonUIType;
  icon?: string;
  infoText?: string;
  fullWidth?: boolean;
  tooltip?: string;
}

const Button = React.memo(
  ({
    title,
    onClick,
    className,
    disabled,
    isLoading,
    uiType,
    icon,
    infoText,
    fullWidth,
    tooltip,
  }: Props) => {
    const getRootClass = () => {
      switch (uiType) {
        case 'action':
          return 'ActionButton';
        case 'box':
          return 'BoxButton';
        case 'action_box':
          return 'ActionBoxButton';
        case 'text':
          return 'TextButton';
        case 'text_danger':
          return 'TextDangerButton';
        case 'secondary':
          return 'ActionButton';
        case 'auth_action':
          return 'AuthActionButton';
        case 'hollow':
          return 'ButtonHollow';
        case 'hollow_box':
          return 'ButtonBoxHollow';
        case 'action_danger':
          return 'DangerButton';
        case 'tab':
          return 'TabButton';
        case 'tab_active':
          return 'TabButtonActive';
        default:
          return 'ActionButton';
      }
    };

    const renderContent = () => (
      <div className="ButtonStyle">
        <button
          onClick={onClick}
          style={{width: fullWidth ? '100%' : ''}}
          className={`${className} Button ${getRootClass()}`}
          disabled={disabled || isLoading}>
          {isLoading ? (
            <div className="LoadingContianer">
              <Lottie
                animationData={loadingLottie}
                loop={true}
                style={{width: '14px', height: '14px'}}
              />
            </div>
          ) : (
            <span>
              {icon && <span className={`${icon} Icon`}> </span>}
              <span>{title}</span>
            </span>
          )}
        </button>
        {infoText && <p className="ButtonInfoText">{infoText}</p>}
      </div>
    );

    return tooltip ? (
      <Tooltip tooltipText={tooltip}>{renderContent()}</Tooltip>
    ) : (
      renderContent()
    );
  },
);

export default Button;
