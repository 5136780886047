import React from 'react';
import TooltipQuestionMark from '../../../../uiToolkit/TooltipQuestionMark';

interface FormFieldProps {
  label: string;
  tooltip?: string;
  children: React.ReactElement;
}

const FormField: React.FC<FormFieldProps> = ({label, tooltip, children}) => {
  return (
    <div className="GenerateAIAssistant_FieldContainer">
      <div className="GenerateAIAssistant_Label">
        <p>{label}</p>
        {tooltip && <TooltipQuestionMark text={tooltip} />}
      </div>
      {children}
    </div>
  );
};

export default FormField;
