import {
  editorVideoIcon,
  imageNodeIc,
  choiceBranchIc,
  textIc,
  multipleChoiceListIc,
  editorAddAudio,
  editorAddGradableBranch,
  jumpToIc,
  freeformShapeIc,
  acknowledgmentNodeIc,
} from '../../assets/images';
import {
  ACKNOWLEDGMENT_NODE,
  AUDIO_NODE,
  BRANCH_BACKGROUND_FREEFORM,
  BRANCH_IMAGE_FREEFORM,
  BRANCH_SHAPE_FREEFORM,
  BRANCH_TEXT_FREEFORM,
  CHALLENGE_BRANCH_AI,
  CHALLENGE_BRANCH_HEADER,
  CHALLENGE_BRANCH_IMAGE_OPTION,
  CHALLENGE_BRANCH_QUESTION,
  CHALLENGE_BRANCH_TEXT_OPTION,
  CHOICE_HEADER_NODE_TYPE,
  CHOICE_IMAGE_OPTION,
  CHOICE_TEXT_OPTION,
  CHOICE_TEXT_QUESTION,
  IMAGE_NODE_TYPE,
  JUMP_TO_NODE,
  MULTIPLE_CHOICE,
  OVERLAY_VIDEO_TYPE,
  VIDEO_NODE_TYPE,
} from '../../data/models/LessonContent/LessonContentBase';
import CustomEdge from './edges/CustomEdge';
import {lessonEditorText} from './lessonEditorText';
import * as NodeUI from './nodes';

export const getIconByType = (type: number) => {
  switch (type) {
    case VIDEO_NODE_TYPE:
    case BRANCH_BACKGROUND_FREEFORM:
      return editorVideoIcon;
    case IMAGE_NODE_TYPE:
    case BRANCH_IMAGE_FREEFORM:
    case CHALLENGE_BRANCH_IMAGE_OPTION:
    case CHOICE_IMAGE_OPTION:
      return imageNodeIc;
    case CHOICE_HEADER_NODE_TYPE:
      return choiceBranchIc;
    case CHALLENGE_BRANCH_QUESTION:
    case CHOICE_TEXT_QUESTION:
    case BRANCH_TEXT_FREEFORM:
    case CHOICE_TEXT_OPTION:
    case CHALLENGE_BRANCH_TEXT_OPTION:
      return textIc;
    case MULTIPLE_CHOICE:
      return multipleChoiceListIc;
    case AUDIO_NODE:
      return editorAddAudio;
    case CHALLENGE_BRANCH_HEADER:
    case CHALLENGE_BRANCH_AI:
      return editorAddGradableBranch;
    case JUMP_TO_NODE:
      return jumpToIc;
    case BRANCH_SHAPE_FREEFORM:
      return freeformShapeIc;
    case ACKNOWLEDGMENT_NODE:
      return acknowledgmentNodeIc;
    default:
      return editorVideoIcon;
  }
};

export const getTooltipByType = (type: number) => {
  switch (type) {
    case VIDEO_NODE_TYPE:
      return lessonEditorText.videoNodeTooltip;
    case IMAGE_NODE_TYPE:
      return 'Image Source';
    case BRANCH_IMAGE_FREEFORM:
    case CHOICE_IMAGE_OPTION:
      return lessonEditorText.choiceImageNodeTooltip;
    case BRANCH_TEXT_FREEFORM:
    case CHOICE_TEXT_OPTION:
      return lessonEditorText.choiceTextNodeTooltip;
    case BRANCH_SHAPE_FREEFORM:
      return lessonEditorText.choiceShapeNodeTooltip;
    case CHOICE_HEADER_NODE_TYPE:
      return lessonEditorText.choiceNodeTooltip;
    case OVERLAY_VIDEO_TYPE:
      return lessonEditorText.videoOverlay;
    case CHOICE_TEXT_QUESTION:
      return lessonEditorText.choiceQuestionNodeTooltip;
    case MULTIPLE_CHOICE:
      return lessonEditorText.mChoiceNodeTooltip;
    case CHALLENGE_BRANCH_AI:
      return lessonEditorText.challengeBranchAITooltip;
    case AUDIO_NODE:
      return 'Audio Source';
    case CHALLENGE_BRANCH_HEADER:
      return 'Challenge Branch';
    case CHALLENGE_BRANCH_QUESTION:
      return lessonEditorText.challengeBranchQuestionTooltip;
    case CHALLENGE_BRANCH_TEXT_OPTION:
      return lessonEditorText.challengeBranchTextOption;
    case CHALLENGE_BRANCH_IMAGE_OPTION:
      return lessonEditorText.challengeBranchImageOption;
    case JUMP_TO_NODE:
      return lessonEditorText.jumpToNodeTooltip;
    case BRANCH_BACKGROUND_FREEFORM:
      return lessonEditorText.videoBackground;
    case ACKNOWLEDGMENT_NODE:
      return lessonEditorText.acknowledgmentNode;
    default:
      return '';
  }
};

export const edgeTypes = {
  buttonedge: CustomEdge,
};

export const nodeTypes = {
  elementCard: NodeUI.CardNode,
  entryNode: NodeUI.EntryNode,
  addNewSourceNode: NodeUI.AddNewSourceNode,
  choiceHeader: NodeUI.ChoiceHeader,
  choiceQuestion: NodeUI.ChoiceQuestion,
  choiceTextOption: NodeUI.ChoiceTextOption,
  choiceImageNode: NodeUI.ChoiceImageNode,
  mChoiceNode: NodeUI.MChoiceNode,
  challangeBranchHeader: NodeUI.ChallengeBranchHeaderNode,
  challengeBranchQuestionNode: NodeUI.ChallengeBranchQuestionNode,
  challengeBranchTextNode: NodeUI.ChallengeBranchTextNode,
  challengeBranchImageNode: NodeUI.ChallengeBranchImageNode,
  challengeBranchAINode: NodeUI.ChallengeBranchAINode,
  challengeBranchAICorrectNode: NodeUI.ChallengeBranchAICorrectNode,
  challengeBranchAIInorrectNode: NodeUI.ChallengeBranchAIInorrectNode,
  choiceFreeformImage: NodeUI.ChoiceFreeformImage,
  choiceFreeformText: NodeUI.ChoiceFreeformText,
  choiceFreeformShape: NodeUI.ChoiceFreeformShape,
  jumpToNode: NodeUI.JumpToNode,
  choiceVideoBackground: NodeUI.ChoiceVideoBackground,
  acknowledgmentNode: NodeUI.AcknowledgmentNode,
  imageSourceNode: NodeUI.ImageSourceNode,
};
