import {inject, observer} from 'mobx-react';
import React, {useState} from 'react';
import LessonEditorStore from '../../store/LessonEditorStore';
import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {getErrorFromCode} from '../../../../data/errorCodes';
import {toastConfig} from '../../../../uiToolkit/Toast/toastConfig';
import ConfirmationModal from '../../../../uiToolkit/ConfirmationModal';
import {lessonEditorText} from '../../lessonEditorText';
import {
  LessonContentBase,
  nodeIsASourceNode,
} from '../../../../data/models/LessonContent/LessonContentBase';
import {LessonContentList} from '../../../../data/models/LessonContent/LessonContentList';
import {MediaFile} from '../../../../data/models/LibraryFile/MediaFile';
import MyLibraryModalStore from '../../../../modules/MyLibraryModal/store/MyLibraryModalStore';
import {sourceHasBranchChild} from '../../nodeTreeUtils';
import ModalProviderStore from '../../../../modules/ModalProvider/store/ModalProviderStore';
import {BRANCH_PARENT_DELETION_MODAL} from '../../../../modules/ModalProvider/globalModals';

export interface BaseContainerProps {
  contentForTab: string;
  optionsForType: string;
  entityToEdit?: LessonContentBase;
  lessonEditorStore?: LessonEditorStore;
  myLibraryModalStore?: MyLibraryModalStore;
  onChangesMade: () => void;
  isPublished?: boolean;
  rootId: string;
  lessonContent?: LessonContentList;
  children?: (props: BaseContainerForwardProps) => React.ReactElement;
  deleteMessage?: string;
  softDeleteMessage?: string;
  modalProviderStore?: ModalProviderStore;
  isGradable: boolean;
}

export interface BaseContainerForwardProps {
  entityToEdit?: LessonContentBase;
  onDelete: () => void;
  onSoftDelete?: () => void;
  onEdit: (node: LessonContentBase) => void;
  isLoading: boolean;
  isPublished: boolean;
  lessonContent?: LessonContentList;
  onOpenLibrary: (
    type: number,
    onFileCallback: (files: MediaFile[]) => void,
    sourceType?: number,
  ) => void;
  onChangesMade: () => void;
  isGradable: boolean;
}

const BaseOptionsContainer = inject(
  'lessonEditorStore',
  'myLibraryModalStore',
  'modalProviderStore',
)(
  observer(
    ({
      entityToEdit,
      isPublished,
      lessonEditorStore,
      onChangesMade,
      rootId,
      children,
      lessonContent,
      myLibraryModalStore,
      deleteMessage,
      softDeleteMessage,
      modalProviderStore,
      isGradable,
    }: BaseContainerProps) => {
      const [showDelete, setShowDelete] = useState(false);
      const [showSoftDelete, setShowSoftDelete] = useState(false);
      const {organizationId, lessonId} = useParams();

      const onEdit = (node: LessonContentBase) => {
        if (organizationId && lessonId && entityToEdit) {
          lessonEditorStore
            ?.editContentNode(organizationId, lessonId, node)
            .then(onChangesMade);
        }
      };

      const onDelete = () => {
        if (organizationId && lessonId && entityToEdit) {
          setShowDelete(false);
          lessonEditorStore
            ?.deleteContentNode(
              organizationId,
              lessonId,
              entityToEdit.internalId,
            )
            .then(processDeleteResult);
        }
      };

      const onSoftDelete = () => {
        if (organizationId && lessonId && entityToEdit) {
          setShowSoftDelete(false);
          lessonEditorStore
            ?.softDeleteContentNode(
              organizationId,
              lessonId,
              entityToEdit.internalId,
            )
            .then(processDeleteResult);
        }
      };

      const processDeleteResult = (success: boolean) => {
        if (success) {
          onChangesMade();
        } else {
          toast.error(getErrorFromCode(''), toastConfig);
        }
      };

      const onOpenLibrary = (
        sourceType: number,
        onFileCallback: (files: MediaFile[]) => void,
      ) => {
        if (
          sourceHasBranchChild(lessonContent, entityToEdit?.internalId) &&
          nodeIsASourceNode(entityToEdit ? entityToEdit.ivNodeType : -1)
        ) {
          modalProviderStore!.openModal(
            BRANCH_PARENT_DELETION_MODAL,
            (accepted: boolean) => {
              if (accepted === true) {
                myLibraryModalStore!.showLibrary(sourceType, onFileCallback);
              }
            },
          );
        } else {
          myLibraryModalStore!.showLibrary(sourceType, onFileCallback);
        }
      };

      return entityToEdit ? (
        <>
          {children &&
            children({
              entityToEdit,
              onDelete: () => setShowDelete(true),
              onSoftDelete:
                entityToEdit.internalId === rootId
                  ? undefined
                  : () => setShowSoftDelete(true),
              onEdit: onEdit,
              isLoading: lessonEditorStore!.isLoading,
              isPublished: isPublished,
              lessonContent,
              onOpenLibrary: onOpenLibrary,
              onChangesMade: onChangesMade,
              isGradable: isGradable,
            } as BaseContainerForwardProps)}
          <ConfirmationModal
            title={deleteMessage || ''}
            onAccept={onDelete}
            onCancel={() => setShowDelete(false)}
            shown={showDelete}
            acceptText={lessonEditorText.delete}
          />
          <ConfirmationModal
            title={
              sourceHasBranchChild(lessonContent, entityToEdit?.internalId)
                ? lessonEditorText.areYouSureDeleteVideoSoftBranchParent
                : softDeleteMessage || ''
            }
            onAccept={onSoftDelete}
            onCancel={() => setShowSoftDelete(false)}
            shown={showSoftDelete}
            acceptText={lessonEditorText.delete}
          />
        </>
      ) : null;
    },
  ),
);

export default BaseOptionsContainer;
