import React from 'react';
import {generateAIVideosText} from '../generateAIVideosText';
import {VIDEO_LENGTH_OPTIONS} from '../../../../data/models/AI/VideoGenerationOptions';
import LibraryFilesInput from '../../../../uiToolkit/Inputs/LibraryFilesInput';
import {inject, observer} from 'mobx-react';
import MyLibraryModalStore from '../../../../modules/MyLibraryModal/store/MyLibraryModalStore';
import {
  MEDIA_FILES_DOCUMENT,
  MediaFile,
} from '../../../../data/models/LibraryFile/MediaFile';
import ToggleSwitch from '../../../../uiToolkit/Inputs/ToggleSwitch';

interface Props {
  videoDuration?: string;
  setVideoDuration: (value: string) => void;
  onContextFilesSelected: (files: MediaFile[]) => void;
  myLibraryModalStore?: MyLibraryModalStore;
  files: MediaFile[];
}

const VideoDuratonStep = inject('myLibraryModalStore')(
  observer(
    ({
      setVideoDuration,
      videoDuration,
      myLibraryModalStore,
      onContextFilesSelected,
      files,
    }: Props) => {
      const onOpenLibrary = () => {
        myLibraryModalStore!.showLibrary(
          MEDIA_FILES_DOCUMENT,
          onContextFilesSelected,
          true,
          files,
        );
      };

      return (
        <div className="StepContainer">
          <h5 className="StepTitle">
            {generateAIVideosText.videoDurationTitle}
          </h5>
          <h5 className="SectionTitle mt-4">
            {generateAIVideosText.videoDurationLabel}
            <h5 className="MandatoryIndicator">*</h5>
          </h5>
          <div className="DurationCheckboxGroup">
            <ToggleSwitch
              options={VIDEO_LENGTH_OPTIONS}
              onChange={setVideoDuration}
              selectedKey={videoDuration}
            />
          </div>
          <h5 className="SectionTitle">{generateAIVideosText.contextLabel}</h5>
          <div className="mt-4">
            <LibraryFilesInput
              onBrowse={onOpenLibrary}
              files={files}
              onRemoveFile={(files?: MediaFile[]) =>
                onContextFilesSelected(files ? files : [])
              }
            />
          </div>
        </div>
      );
    },
  ),
);

export default VideoDuratonStep;
