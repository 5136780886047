export const generateAiAssistantText = {
  createTitle: 'Create new AI assistant',
  editTitle: 'Edit AI assistant',
  titlePlaceholder: 'Enter assistant name',
  descriptionPlaceholder: 'Describe your assistant',
  dropdownPlaceholder: 'Select Tone',
  searchDropDownPlaceholder: 'Choose where your AI assistant is available.',
  assistantCreated: 'AI Assistant Created',
  assistantEdited: 'AI Assistant edit was successful',
  knowledgeBaseTooltip:
    'Choose documents to use for additional narration. If you do not select your own documents, the narration will use open-source content to supplement the audio portion of your generated video.',
  responseStyleTooltip:
    "A direct response will give straightforward responses to learners' questions with answers from your course materials. An indirect response will ask questions and guide the learners to the answers, but will not provide the answers to them.",
  visibilityTooltip:
    'You can select where learners can access this assistant. If you would like them to access this assistant from within a specific course, please select it here.',
};
