import {SortOption} from '../../data/models/UI/SelectOption';

export const LIBRARY_ITEMS_PER_PAGE = 12;

export const LIBRARY_SORT_OPTIONS: SortOption[] = [
  {
    field: 'file_name',
    order: 'asc',
    title: 'Name (A-Z)',
  },
  {
    field: 'file_name',
    order: 'desc',
    title: 'Name (Z-A)',
  },
  {
    field: 'date_created',
    order: 'desc',
    title: 'Uploaded date (Newest)',
  },
  {
    field: 'date_created',
    order: 'asc',
    title: 'Uploaded date (Oldest)',
  },
  {
    field: 'size',
    order: 'asc',
    title: 'Size (Smallest)',
  },
  {
    field: 'size',
    order: 'desc',
    title: 'Size (Largest)',
  },
];

export const mapFileStatusToText = (status: number) => {
  switch (status) {
    case 1:
    case 2:
    case 3:
      return 'Initializing';
    case 4:
    case 5:
      return 'Reading Files';
    case 6:
      return 'Integrating AI Elements';
    case 7:
    case 8:
    case 9:
      return 'Adding Voiceover';
    case 10:
    case 11:
      return 'Generating Video';
  }
};
