import React from 'react';
import {
  VOICE_GENDER_FEMALE,
  VOICE_GENDER_MALE,
} from '../../../data/models/AI/VideoGenerationOptions';
import {
  voiceFemaleActive,
  voiceFemaleInctive,
  voiceMaleActive,
  voiceMaleInactive,
} from '../../../assets/images';
import './styles.css';

interface Props {
  selectedOption: string;
  onSelectOption: (selected: string) => void;
}

const MaleFemaleSelect = React.memo(
  ({selectedOption, onSelectOption}: Props) => {
    return (
      <div className="MaleFemaleVoiceContainer">
        <img
          onClick={() => onSelectOption(VOICE_GENDER_FEMALE)}
          src={
            selectedOption === VOICE_GENDER_FEMALE
              ? voiceFemaleActive
              : voiceFemaleInctive
          }
          className="FemaleVoice"
        />
        <img
          onClick={() => onSelectOption(VOICE_GENDER_MALE)}
          src={
            selectedOption === VOICE_GENDER_MALE
              ? voiceMaleActive
              : voiceMaleInactive
          }
        />
      </div>
    );
  },
);

export default MaleFemaleSelect;
