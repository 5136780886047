import React, {useState} from 'react';
import {closeFilterIc} from '../../../../assets/images';
import {listLearnersText} from '../listLearnersText';
import {Col, Row} from 'react-bootstrap';
import {LearnerListItem} from '../../../../data/models/Learners/Learner';
import LearnersTable from './LearnersTable';
import './styles.css';
import SearchInput from '../../../../uiToolkit/Inputs/SearchInput';
import FilterButton from '../../../../uiToolkit/Buttons/FilterButton';
import FilterCourses from '../component/FilterCourses';
import Button from '../../../../uiToolkit/Buttons/Button';
import {isSandbox} from '../../../../utils/envHelper';
import {Course} from '../../../../data/models/Course/Course';
import PageTitle from '../../../../uiToolkit/PageTitle';

interface Props {
  learners: LearnerListItem[];
  onAddNewLearners: () => void;
  onDelete: (learner: string) => void;
  pages: number;
  onPageChange: (page: number) => void;
  courses: Course[];
  filters: string[];
  applyFiltersButton: (filters: string[]) => void;
  onSearchKeyChange: (search: string) => void;
  onDeleteFromCourse: (learnerId: string, courseId: string) => void;
  onActivateLearner: (learner: string) => void;
  onReEnroll: (courseId: string, learnerId: string) => void;
  onCopyEmail: (email: string) => void;
}

const ListLearnersUI = React.memo(
  ({
    learners,
    onAddNewLearners,
    onDelete,
    pages,
    onPageChange,
    courses,
    applyFiltersButton,
    onSearchKeyChange,
    onDeleteFromCourse,
    filters,
    onActivateLearner,
    onReEnroll,
    onCopyEmail,
  }: Props) => {
    const [search, setSearch] = useState('');
    const [showFilterDropdown, setShowFilterDropdown] = useState(false);

    const handleAcceptButtonPressed = (filtersArg: string[]) => {
      applyFiltersButton(filtersArg);
      setShowFilterDropdown(false);
    };

    const handleSearchChange = (text: string) => {
      setSearch(text);
      onSearchKeyChange(text);
    };

    return (
      <div className="ListLearnersContainer">
        <PageTitle title={listLearnersText.title} />
        <Row>
          <Col lg={12} md={12}>
            <div className="ControlPanel">
              <div className="HeaderActionsContainer">
                <div className="FilterButtonWizard">
                  <FilterButton
                    onClick={() => setShowFilterDropdown(!showFilterDropdown)}
                    className={
                      showFilterDropdown
                        ? 'active-filter FilterButton'
                        : 'inactive-filter FilterButton'
                    }
                  />
                  {showFilterDropdown && (
                    <FilterCourses
                      courses={courses.sort((x, y) =>
                        x.title.toLowerCase() > y.title.toLowerCase() ? 1 : -1,
                      )}
                      applyFiltersButton={handleAcceptButtonPressed}
                      selectedFilters={filters}
                    />
                  )}
                </div>
                <SearchInput
                  value={search}
                  onChange={handleSearchChange}
                  className="Input"
                />
                <div className="AddButtonContainer">
                  <Button
                    title={listLearnersText.invite}
                    onClick={onAddNewLearners}
                    uiType="action"
                    disabled={isSandbox}
                  />
                </div>
              </div>
            </div>
            <div className="FilterBoxContainer">
              {filters.map(filterId => {
                const course = courses.find(course => course.id === filterId);
                return (
                  <div key={filterId} className="FilterNames">
                    <h3>{course?.title}</h3>
                    <img
                      src={closeFilterIc}
                      alt="close"
                      onClick={() => {
                        const newSelectedFilters = filters.filter(
                          id => id !== filterId,
                        );
                        applyFiltersButton(newSelectedFilters);
                      }}
                    />
                  </div>
                );
              })}
            </div>
            <div>
              <LearnersTable
                learners={learners}
                onDelete={onDelete}
                pages={pages}
                onPageChange={onPageChange}
                onDeleteFromCourse={onDeleteFromCourse}
                courseFilterSelected={filters.length !== 0}
                onActivateLearner={onActivateLearner}
                onReEnroll={onReEnroll}
                onCopyEmail={onCopyEmail}
              />
            </div>
          </Col>
        </Row>
      </div>
    );
  },
);

export default ListLearnersUI;
