import {getLocalizationBasedOnLang} from '../../../data/models/User/UserInfo';

const english = {
  courses: 'Courses',
  inThisCourse: 'In this course',
  lessons: 'Lessons',
  invalidFolder:
    'This will not be visible to learners as there is no actual lesson content within',
  invalidLesson:
    'This lesson not be visible to learners as there is no actual content within',
  coursePreview: 'Course Preview',
  lessonsAmount: (amountOfLessons: number) =>
    `${amountOfLessons === 1 ? 'Lesson' : 'Lessons'}`,
  publishCourse: 'Publish Course',
  republishCourse: 'Republish Course',
  publishingCourse: 'Course Is Publishing',
  datePublished: 'Date published',
  publishSuccessful: 'Course publish started successfully',
  republishSuccessful: 'Course republish started successfully',
  contactAdmin: 'Contact your admin to publish this course',
  minApprox: 'min. Approx.',
};

const welsh = {
  courses: 'Cyrsiau',
  inThisCourse: 'Yn y cwrs hwn',
  lessons: 'Gwersi',
  invalidFolder:
    'Nid fydd hyn yn weladwy i ddysgwyr gan nad oes unrhyw gynnwys gwersi gwirioneddol o fewn',
  invalidLesson:
    'This lesson not be visible to learners as there is no actual content within',
  coursePreview: 'Gweddol y Cwrs',
  lessonsAmount: (amountOfLessons: number) =>
    `${amountOfLessons === 1 ? 'Gwers' : 'Gwersi'}`,
  publishCourse: "Cyhoeddi'r Cwrs",
  minApprox: 'isaf. Amcangyfrif.',
  republishCourse: "Ailgyhoeddi'r Cwrs",
  publishingCourse: "Mae'r Cwrs yn Cael ei Gyhoeddi",
  datePublished: 'Dyddiad Cyhoeddi',
  publishSuccessful: "Mae cyhoeddi'r cwrs wedi dechrau'n llwyddiannus",
  republishSuccessful: "Mae ailgyhoeddi'r cwrs wedi dechrau'n llwyddiannus",
};

export const coursePreviewText: any = getLocalizationBasedOnLang(
  english,
  welsh,
);
