import React, {useEffect} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {Outlet} from 'react-router-dom';
import {loginBackground} from '../../../assets/images';
import {appText} from '../../../assets/text/appText';
import './styles.css';

const AuthRoot = React.memo(() => {
  useEffect(() => {
    document.body.classList.add('authPage');

    return () => {
      document.body.classList.remove('authPage');
    };
  }, []);

  return (
    <Container fluid>
      <Row>
        <Col lg={5} className="AuthOutletContainer">
          <Outlet />
        </Col>
        <Col
          lg={7}
          style={{backgroundImage: `url(${loginBackground})`}}
          className="AuthImage">
          <div className="AuthImageContainer">
            <div className="AuthInfoModal">
              <h2 className="AuthInfoModalTitle">
                {appText.authInfoModalTitle}
              </h2>
              <h4 className="AuthInfoModalText">{appText.autnInfoModelBody}</h4>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
});

export default AuthRoot;
