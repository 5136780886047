import React, {useEffect, useMemo, useRef, useState} from 'react';
import {fileUploadText} from '../fileUploadText';
import TipBox from '../../../uiToolkit/TipBox';
import {
  FILE_FHD,
  FILE_HD,
  FILE_SD_OPTION,
  FILE_UHD,
  mapUIKeyToResolutionKey,
} from '../../../data/models/LibraryFile/MediaFile';
import {getVideoDimentions} from '../../../utils/getVideoDimentions';
import Button from '../../../uiToolkit/Buttons/Button';
import {loadingLottie} from '../../../assets/lottie';
import Lottie from 'lottie-react';
import ModalContainer from '../../../uiToolkit/ModalContainer';
import CardOptionsInput from '../../../uiToolkit/Inputs/CardOptionsInput';
import './styles.css';

interface Props {
  file: File;
  onFileUpload: (duration: number, targetRes: number) => void;
  isLoading: boolean;
  nonVideoLoading: boolean;
  maxAllowedRes: number;
  onClose: () => void;
}

const HD_HEIGHT = 720;
const FHD_HEIGHT = 1080;
const UHD_HEIGHT = 2160;

const FileUploadModalBodyUI = React.memo(
  ({
    file,
    onFileUpload,
    isLoading,
    nonVideoLoading,
    maxAllowedRes,
    onClose,
  }: Props) => {
    const [duration, setDuration] = useState(0);
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const [targetRes, setTargetRes] = useState('SD');

    const videoEl = useRef<HTMLVideoElement>(null);

    useEffect(() => {
      if (file.type.includes('video')) {
        getVideoDimentions(file).then(res => {
          setWidth(res.width);
          setHeight(res.height);
        });
      }
    }, [file]);

    const handleLoadedMetadata = () => {
      const video = videoEl.current;
      if (!video) return;
      if (video.duration && !duration) {
        setDuration(Math.floor(video.duration));
      }
    };

    const getDropdownOptions = () => {
      return [FILE_SD_OPTION, FILE_HD, FILE_FHD, FILE_UHD];
    };

    const getDropdownBlockedOptions = () => {
      const result = [];
      if (height < HD_HEIGHT || maxAllowedRes < FILE_HD.internalKey) {
        result.push(FILE_HD);
      }
      if (height < FHD_HEIGHT || maxAllowedRes < FILE_FHD.internalKey) {
        result.push(FILE_FHD);
      }
      if (height < UHD_HEIGHT || maxAllowedRes < FILE_UHD.internalKey) {
        result.push(FILE_UHD);
      }
      return result;
    };

    const onUpload = () => {
      onFileUpload(duration, mapUIKeyToResolutionKey(targetRes));
    };

    const renderVideo = useMemo(() => {
      return (
        <video
          src={URL.createObjectURL(file)}
          ref={videoEl}
          onLoadedMetadata={handleLoadedMetadata}
        />
      );
    }, []);

    return (
      <ModalContainer onClose={onClose}>
        {nonVideoLoading ? (
          <div className="d-flex flex-row justify-content-center">
            <Lottie
              animationData={loadingLottie}
              loop={true}
              style={{
                width: '40px',
                height: '40px',
                marginBottom: '10px',
              }}
            />
          </div>
        ) : (
          <div className="FileUploadModalBody">
            <h3 className="UploadingTitle">{fileUploadText.uploadingFile}</h3>
            <div className="PreviewContainer">
              <div className="VideoFileContainer">
                {renderVideo}
                <p className="FileTitle">{file?.name}</p>
                <p className="FileResolution">
                  {width}x{height}
                </p>
              </div>
              <div className="FileUploadTip">
                <TipBox
                  tip={
                    <p>
                      <p className="mb-2">{fileUploadText.tip}</p>
                      <p>{fileUploadText.tip2}</p>
                    </p>
                  }
                />
              </div>
            </div>
            <h4>{fileUploadText.selectHeader}</h4>
            <CardOptionsInput
              options={getDropdownOptions()}
              selectedOption={targetRes}
              onSelect={setTargetRes}
              disabledOptions={getDropdownBlockedOptions()}
            />
            <div className="Footer">
              <Button
                title={fileUploadText.upload}
                onClick={onUpload}
                isLoading={isLoading}
              />
            </div>
          </div>
        )}
      </ModalContainer>
    );
  },
);

export default FileUploadModalBodyUI;
