import {generateColorPalette} from '../../utils/generatePallete';
import {
  DEFAULT_PRIMARY_COLOR,
  DEFAULT_SECONDARY_COLOR,
} from '../staticValues/colors';

class LocalStorage {
  public static Keys = {
    USER_KEY: 'key_user',
    USER_INFO: 'user_info',
  };

  public saveToStorage(key: string, data: string | number | boolean | object) {
    localStorage.setItem(key, JSON.stringify(data));
  }

  public getItemFromStorage(key: string) {
    const valueRaw = localStorage.getItem(key);
    if (valueRaw) {
      try {
        const valueObj = JSON.parse(valueRaw);
        return valueObj;
      } catch {
        return undefined;
      }
    }
    return undefined;
  }

  public removeFromStorage(key: string) {
    localStorage.removeItem(key);
  }

  public wipeLocalStorage() {
    localStorage.clear();
  }

  public setThemeColors(primaryColor: string, secondaryColor: string) {
    this.applyThemeColors(primaryColor, secondaryColor);
    localStorage.setItem('primaryColor', primaryColor);
    localStorage.setItem('secondaryColor', secondaryColor);
  }

  public resetThemeColors() {
    this.applyThemeColors(DEFAULT_PRIMARY_COLOR, DEFAULT_SECONDARY_COLOR);
    localStorage.setItem('primaryColor', DEFAULT_PRIMARY_COLOR);
    localStorage.setItem('secondaryColor', DEFAULT_SECONDARY_COLOR);
  }

  private applyThemeColors(primaryColor: string, secondaryColor: string) {
    if (
      primaryColor === DEFAULT_PRIMARY_COLOR &&
      secondaryColor === DEFAULT_SECONDARY_COLOR
    ) {
      for (let i = 1; i <= 10; i++) {
        document.documentElement.style.setProperty(
          `--primary-color-${i}`,
          `var(--default-primary-color-${i})`,
        );

        document.documentElement.style.setProperty(
          `--secondary-color-${i}`,
          `var(--default-secondary-color-${i})`,
        );
      }
      document.documentElement.style.setProperty(
        `--gradient-background`,
        `var(--default-gradient-background)`,
      );
      document.documentElement.style.setProperty(
        `--accent-color`,
        `var(--default-accent-color)`,
      );
    } else {
      const colorPalettePrimary = generateColorPalette(primaryColor);

      // Iterate over the color palette and set the corresponding CSS variables
      for (let i = 1; i <= 10; i++) {
        document.documentElement.style.setProperty(
          `--primary-color-${i}`,
          colorPalettePrimary[i - 1],
        );

        document.documentElement.style.setProperty(
          `--secondary-color-${i}`,
          secondaryColor,
        );
      }
      document.documentElement.style.setProperty(
        `--gradient-background`,
        colorPalettePrimary[0],
      );
      document.documentElement.style.setProperty(
        `--accent-color`,
        secondaryColor,
      );
    }
  }
}

export default LocalStorage;
