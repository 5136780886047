import {makeAutoObservable} from 'mobx';
import {toast} from 'react-toastify';
import {generateAIVideosText} from '../generateAIVideosText';
import LoadingModule from '../../../../modules/LoadingModule/LoadingModule';
import {toastConfig} from '../../../../uiToolkit/Toast/toastConfig';
import {getErrorFromCode} from '../../../../data/errorCodes';
import LibraryRepository from '../../../../data/repositories/LibraryRepository';
import {
  VideoCostEstimationModel,
  VideoGenerationConfig,
} from '../../../../data/models/AI/VideoGenerationConfig';
import {AIRepository} from '../../../../data/repositories/AiRepository';

class GenerateAIVideosStore {
  private libraryRepository = new LibraryRepository();
  private aiRepository = new AIRepository();
  private videoGenerationStatus = 'idle';

  private loadingModule = new LoadingModule();
  private aiEstimationLoading = new LoadingModule();

  constructor() {
    makeAutoObservable(this);
  }

  public get generationStatus() {
    return this.videoGenerationStatus;
  }

  public get isEstimationLoading() {
    return this.aiEstimationLoading.isLoading;
  }

  public get isLoading() {
    return this.loadingModule.isLoading;
  }

  public async generateAiVideosFromSlides(
    organizationId: string,
    config: VideoGenerationConfig,
  ) {
    this.loadingModule.startLoading();
    this.videoGenerationStatus = 'pending';
    const result = await this.libraryRepository.generateAiVideosFromSlides(
      organizationId,
      config,
    );
    this.loadingModule.endLoading();
    if (result.errors && result.errors.length) {
      toast.error(getErrorFromCode(result.errors[0]), toastConfig);
      this.videoGenerationStatus = 'error';
    } else {
      this.videoGenerationStatus = 'idle';
      toast.success(generateAIVideosText.successMessage, toastConfig);
    }
    return result.success;
  }

  public async getVideoGenerationCost(
    organizationId: string,
    model: VideoCostEstimationModel,
  ) {
    this.aiEstimationLoading.startLoading();
    const result = await this.aiRepository.getVideoCostEstimation(
      organizationId,
      model,
    );
    this.aiEstimationLoading.endLoading();
    if (result.success && result.data) {
      return result.data;
    }
    return {
      availableCredits: 0,
      estimatedCost: 1,
    };
  }

  public async getFileOrientation(organizationId: string, fileId: string) {
    const result = await this.libraryRepository.getFileOrientation(
      organizationId,
      fileId,
    );
    if (result.success && result.data) {
      return result.data.orientation;
    }
    return 'horizontal';
  }
}

export default GenerateAIVideosStore;
