import {inject, observer} from 'mobx-react';
import React, {useEffect, useState} from 'react';
import LearnerAiAssistantsUI from '../view/LearnerAiAssistantsUI';
import LearnerAiAssistantsStore from '../store/LearnerAiAssistantsStore';
import {MyAssitantListItem} from '../../../data/models/AI/AiAssistantModels';
import AiAssistantChatbotStore from '../../AiAssistantChatbot/store/AiAssistantChatbotStore';

interface Props {
  learnerAiAssistantsStore?: LearnerAiAssistantsStore;
  aiAssistantChatbotStore?: AiAssistantChatbotStore;
  courseId?: string;
}

const LearnerAiAssistantsContainer = inject(
  'learnerAiAssistantsStore',
  'aiAssistantChatbotStore',
)(
  observer(
    ({learnerAiAssistantsStore, aiAssistantChatbotStore, courseId}: Props) => {
      const [assistants, setAssistants] = useState<MyAssitantListItem[]>([]);

      useEffect(() => {
        learnerAiAssistantsStore!
          .getMyAiAssistants(courseId)
          .then(setAssistants);
      }, [courseId]);

      const onOpenAssistant = (id: string) => {
        aiAssistantChatbotStore!.showChatbot(id);
      };

      const onCloseAssistant = () => {
        aiAssistantChatbotStore!.closeChatbot();
      };

      return (
        <LearnerAiAssistantsUI
          assistants={assistants}
          openAssistant={onOpenAssistant}
          closeAssistant={onCloseAssistant}
        />
      );
    },
  ),
);

export default LearnerAiAssistantsContainer;
