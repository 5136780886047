import React from 'react';
import Tooltip from '../../../../uiToolkit/Tooltip';
import {OrganizationDetails} from '../../../../data/models/Oganization/Organization';

interface Props {
  organization: OrganizationDetails;
  menuIsOpen: boolean;
}

const OrganizationTitle = React.memo(({organization, menuIsOpen}: Props) => {
  return (
    <Tooltip tooltipText={organization.name}>
      <div className={`Header ${!menuIsOpen ? 'HeaderCollapsed' : ''}`}>
        {organization.avatar ? (
          <img src={organization.avatar} />
        ) : (
          <div className="OrgAvatarPlaceholder">
            <p>{organization.name[0].toUpperCase()}</p>
          </div>
        )}
        {menuIsOpen && <h2>{organization.name}</h2>}
      </div>
    </Tooltip>
  );
});

export default OrganizationTitle;
