import React from 'react';
import {generateAIVideosText} from '../generateAIVideosText';
import CardOptionsInput from '../../../../uiToolkit/Inputs/CardOptionsInput';
import {VIDEO_IMAGE_STYLES} from '../../../../data/models/AI/VideoGenerationOptions';

interface Props {
  sliderValue: number;
  setSliderValue: (value: number) => void;
  videoImageStyle: string;
  setVideoImageStyle: (style: string) => void;
  fileOrientation: string;
}

const IMAGE_RATIO_OPTIONS = [
  {
    key: '0%',
    value: generateAIVideosText.aiImageNone,
  },
  {
    key: '25%',
    value: generateAIVideosText.aiImageFew,
  },
  {
    key: '50%',
    value: generateAIVideosText.aiImageSome,
  },
  {
    key: '75%',
    value: generateAIVideosText.aiImageMostly,
  },
  {
    key: '100%',
    value: generateAIVideosText.aiImageEntirely,
  },
];

const ImageGenerationStep = React.memo(
  ({
    sliderValue,
    setSliderValue,
    videoImageStyle,
    setVideoImageStyle,
    fileOrientation,
  }: Props) => {
    const onOptionSelected = (option: string) => {
      setSliderValue(parseInt(option.replace('%', ''), 10));
    };

    return (
      <div className="StepContainer">
        <h5 className="StepTitle">
          {generateAIVideosText.generateImagesTitle}
        </h5>
        <h5 className="SectionTitle mt-4">
          {generateAIVideosText.generateImagesLabel}
        </h5>
        {fileOrientation === 'horizontal' ? (
          <>
            <CardOptionsInput
              options={IMAGE_RATIO_OPTIONS}
              className="AiMediaOptions"
              selectedOption={`${sliderValue}%`}
              onSelect={onOptionSelected}
            />
            {sliderValue > 0 && (
              <>
                <h5 className="SectionTitle mt-4">
                  {generateAIVideosText.imageStyleLabel}
                </h5>
                <CardOptionsInput
                  options={VIDEO_IMAGE_STYLES}
                  className="AiMediaOptions"
                  selectedOption={videoImageStyle}
                  onSelect={setVideoImageStyle}
                  showTooltip
                />
              </>
            )}
          </>
        ) : (
          <div className="FileOrientationError">
            <span className="icon-alert-circle"></span>
            <p>{generateAIVideosText.fileOrientationWarning}</p>
          </div>
        )}
      </div>
    );
  },
);

export default ImageGenerationStep;
