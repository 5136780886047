import React, {useCallback, useEffect, useRef, useState} from 'react';
import {MediaFile} from '../../../../data/models/LibraryFile/MediaFile';
import {
  isImageFile,
  isVideoFile,
} from '../../../../data/models/LibraryFile/VideoSourceShort';
import {loadingLottie} from '../../../../assets/lottie';
import Lottie from 'lottie-react';
import {videoSourcesText} from '../../videoSourcesText';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';

const tooltip = <Tooltip>{videoSourcesText.fileIsUsed}</Tooltip>;

interface Props {
  file: MediaFile;
  onPreview: (file: MediaFile) => void;
  onEditVideo: (file: MediaFile) => void;
  onDownloadTranscript: (id: string) => Promise<any>;
  onDownloadVideo: (file: MediaFile) => Promise<any>;
  onDelete: (id: string) => void;
}

const LibraryFileOptions = React.memo(
  ({
    file,
    onPreview,
    onEditVideo,
    onDownloadTranscript,
    onDownloadVideo,
    onDelete,
  }: Props) => {
    const [showOptions, setShowOptions] = useState(false);
    const [fileIsDownloading, setFileIsDownloading] = useState(false);

    const openerRef = useRef<any>(null);
    const openerArrowRef = useRef<any>(null);

    const onPreviewOption = () => {
      onPreview(file);
      setShowOptions(false);
    };

    const onDownloadTranscriptOption = () => {
      setFileIsDownloading(true);
      onDownloadTranscript(file.id).then(() => {
        setFileIsDownloading(false);
      });
    };

    const onDownloadVideoOption = () => {
      setFileIsDownloading(true);
      onDownloadVideo(file).then(() => {
        setFileIsDownloading(false);
      });
    };

    useEffect(() => {
      window.addEventListener('click', handleBodyClick);
      return () => {
        window.removeEventListener('click', handleBodyClick);
      };
    }, [showOptions]);

    const handleBodyClick = useCallback(
      (ev: any) => {
        if (
          showOptions &&
          openerRef?.current !== ev.target &&
          openerArrowRef?.current !== ev.target
        ) {
          setShowOptions(false);
        }
      },
      [showOptions],
    );

    const canShowPreview = () =>
      isImageFile(file.fileType) || isVideoFile(file.fileType);

    const canShowEditVideo = () =>
      file.fileUrl &&
      (file.fileStatus === 0 || file.fileStatus === 2) &&
      !file.isUsed &&
      isVideoFile(file.fileType);

    return (
      <div className="LibraryFileOptions">
        <button
          className="Opener"
          disabled={fileIsDownloading}
          onClick={() => setShowOptions(!showOptions)}
          ref={openerRef}>
          {fileIsDownloading ? (
            <div className="LoadingContainer">
              <Lottie
                animationData={loadingLottie}
                loop={true}
                style={{
                  width: '16px',
                  height: '16px',
                }}
              />
            </div>
          ) : (
            <span className="icon-dots-horizontal" ref={openerArrowRef}></span>
          )}
        </button>
        {showOptions && (
          <div className="Options">
            {canShowPreview() && (
              <div className={`Option`} onClick={onPreviewOption}>
                {videoSourcesText.preview}
              </div>
            )}
            {canShowEditVideo() && (
              <div className={`Option`} onClick={() => onEditVideo(file)}>
                {videoSourcesText.editVideo}
              </div>
            )}
            {file.fileUrl && (
              <div className={`Option`} onClick={onDownloadVideoOption}>
                {videoSourcesText.downloadFile}
              </div>
            )}
            {file.hasTranscript && (
              <div className={`Option`} onClick={onDownloadTranscriptOption}>
                {videoSourcesText.downloadTranscript}
              </div>
            )}
            {file.isUsed ? (
              <OverlayTrigger placement="bottom" overlay={tooltip}>
                <div>
                  <div className={`Option DeleteOptionDisabled`}>
                    {videoSourcesText.delete}
                  </div>
                </div>
              </OverlayTrigger>
            ) : (
              <div
                className={`Option DeleteOption`}
                onClick={() => onDelete(file.id)}>
                {videoSourcesText.delete}
              </div>
            )}
          </div>
        )}
      </div>
    );
  },
);

export default LibraryFileOptions;
