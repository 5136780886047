import React from 'react';
import {coursesListText} from '../coursesListText';
import {copyIc} from '../../../../assets/images';
import DataTable from 'react-data-table-component';
import {CourseListItem} from '../../../../data/models/Course/Course';
import TablePagination from '../../../../uiToolkit/TablePagination';
import './styles.css';
import '../../../../uiToolkit/DataTable/styles.css';
import PageTitle from '../../../../uiToolkit/PageTitle';
import IconButton from '../../../../uiToolkit/Buttons/IconButton';
import {getCourseTypeFromKey} from '../../../../data/staticValues/courseStructure';
import SearchInput from '../../../../uiToolkit/Inputs/SearchInput';
import CoursesEmptyState from '../../../../uiToolkit/EmptyStates/CoursesEmptyState';
import Tooltip from '../../../../uiToolkit/Tooltip';
import Button from '../../../../uiToolkit/Buttons/Button';
import {APP_PERMISSIONS} from '../../../../data/permissions';
import {COURSES_PAGE_SIZE} from '../store/ListCoursesStore';
import {getLocalizationBasedOnLang} from '../../../../data/models/User/UserInfo';

interface Props {
  onCreateNewCourse: () => void;
  courses: CourseListItem[];
  onDelete: (courseId: string) => void;
  onEdit: (id: string) => void;
  onOpenFolders: (courseId: string) => void;
  onOpenLearners: (courseId: string) => void;
  onPreview: (courseId: string) => void;
  onOpenGradebook: (courseId: string) => void;
  onCopyCourse: (courseId: string) => void;
  isLoading?: boolean;
  onSearchChange: (search: string) => void;
  search: string;
  permissions: string[];
  pagesCount: number;
  onChangePage: (page: number) => void;
}

const coursesTableColumns = (
  permissions: string[],
  onDelete: (id: string) => void,
  onEdit: (id: string) => void,
  onOpenFolders: (courseId: string) => void,
  onOpenLearners: (courseId: string) => void,
  onPreview: (courseId: string) => void,
  onCopyCourse: (courseId: string) => void,
) => [
  {
    name: getLocalizationBasedOnLang('Name', 'Enw'),
    selector: (row: CourseListItem) => row.title,
    cell: (row: CourseListItem) => (
      <div
        className="DataTableSquareImageNameContainer"
        data-tag="allowRowEvents">
        {row.image ? (
          <div
            style={{backgroundImage: `url(${row.image})`}}
            data-tag="allowRowEvents"
            className="DataTableSquareImageNameContainer_thumb"></div>
        ) : (
          <div className="CourseAvatarPlaceholder">
            <span>{row.title[0].toUpperCase()}</span>
          </div>
        )}
        <Tooltip
          tooltipText={row.title}
          className="DataTableSquareImageNameContainer_tooltip">
          <h5
            className="DataTableText"
            data-tag="allowRowEvents"
            style={{cursor: 'pointer'}}
            onClick={() => onPreview(row.id || '')}>
            {row.title}
          </h5>
        </Tooltip>
      </div>
    ),
  },
  {
    name: getLocalizationBasedOnLang('Category', 'Categori'),
    selector: (row: CourseListItem) => row.subjectTitle || '-',
    cell: (row: CourseListItem) => (
      <Tooltip
        tooltipText={row.subjectTitle}
        className="CourseCategoryContainer">
        <h5 className="DataTableText" data-tag="allowRowEvents">
          {row.subjectTitle || '-'}
        </h5>
      </Tooltip>
    ),
    id: 'course_category',
    grow: 0.3,
    style: {
      padding: '0',
    },
  },
  {
    name: getLocalizationBasedOnLang('Type', 'Math'),
    selector: (row: CourseListItem) => row.description || '-',
    cell: (row: CourseListItem) => (
      <Tooltip tooltipText={getCourseTypeFromKey(row.type.toString()) || ''}>
        <h5 className={'DataTableText'} data-tag="allowRowEvents">
          {row.type === 1 ? 'NA' : 'A'}
        </h5>
      </Tooltip>
    ),
    id: 'course_type',
    grow: 0.3,
    style: {
      padding: '0',
    },
  },
  {
    name: getLocalizationBasedOnLang('Enrolled', 'Cofrestredig'),
    selector: (row: CourseListItem) => row.enrollNumber,
    cell: (row: CourseListItem) =>
      permissions.includes(APP_PERMISSIONS.ORG_LIST_LEARNERS) ? (
        <h5
          onClick={() => onOpenLearners(row.title || '')}
          className="DataTableLink">
          {row.enrollNumber}
        </h5>
      ) : (
        <h5 className={'DataTableText'} data-tag="allowRowEvents">
          {row.enrollNumber}
        </h5>
      ),
    id: 'course_enrolled',
    grow: 0.1,
    width: '50px',
    style: {
      padding: '0',
    },
  },
  // {
  //   name: 'Completed',
  //   selector: (row: CourseListItem) => row.completedNumber,
  //   cell: (row: CourseListItem) => (
  //     <h5 className={'DataTableText'} data-tag="allowRowEvents">
  //       {row.completedNumber}
  //     </h5>
  //   ),
  //   id: 'course_completed',
  //   grow: 0.3,
  // },
  {
    name: getLocalizationBasedOnLang('Avg. Score', 'Sgôr Cyfartalog'),
    selector: (row: CourseListItem) => row.averageGrade,
    cell: (row: CourseListItem) =>
      permissions.includes(APP_PERMISSIONS.ORG_LIST_LEARNERS) ? (
        <h5 className="DataTableText">
          {row.averageGrade == 0 ? 'N/A' : Math.floor(row.averageGrade)}
        </h5>
      ) : (
        <h5 className={'DataTableText'} data-tag="allowRowEvents">
          {row.averageGrade == 0 ? 'N/A' : Math.floor(row.averageGrade)}
        </h5>
      ),
    id: 'course_avg',
    grow: 0.3,
    style: {
      padding: '0',
    },
  },
  {
    name: getLocalizationBasedOnLang('Published', 'Cyhoeddwyd'),
    selector: (row: CourseListItem) => row.published,
    cell: (row: CourseListItem) =>
      row.published ? (
        <Tooltip tooltipText={coursesListText.published}>
          <h5 className={'DataTableText'} data-tag="allowRowEvents">
            {coursesListText.yes}
          </h5>
        </Tooltip>
      ) : (
        <Tooltip tooltipText={coursesListText.unpublished}>
          <h5 className={'DataTableText'} data-tag="allowRowEvents">
            {coursesListText.no}
          </h5>
        </Tooltip>
      ),
    id: 'course_published',
    grow: 0.1,
    width: '60px',
    style: {
      padding: '0',
    },
  },
  {
    name: getLocalizationBasedOnLang('Public', 'Cyhoeddus'),
    selector: (row: CourseListItem) => row.isPublic,
    cell: (row: CourseListItem) =>
      row.isPublic ? (
        <Tooltip tooltipText={coursesListText.public}>
          <h5 className={'DataTableText'} data-tag="allowRowEvents">
            {coursesListText.yes}
          </h5>
        </Tooltip>
      ) : (
        <Tooltip tooltipText={coursesListText.private}>
          <h5 className={'DataTableText'} data-tag="allowRowEvents">
            {coursesListText.no}
          </h5>
        </Tooltip>
      ),
    id: 'course_public',
    grow: 0.1,
    width: '60px',
    style: {
      padding: '0',
    },
  },
  {
    name: coursesListText.actions,
    cell: (row: CourseListItem) => (
      <div className="CourseActionsContainer">
        <div>
          <IconButton
            onClick={() => onEdit(row.id || '')}
            icon="icon-typeEdit-with-square"
            tooltip={coursesListText.edit}
          />
          <IconButton
            onClick={() => onOpenFolders(row.id || '')}
            icon="icon-typePage"
            tooltip={coursesListText.open}
          />
          {permissions.includes(APP_PERMISSIONS.ORG_DELETE_COURSE) && (
            <IconButton
              onClick={() => onDelete(row.id || '')}
              icon="icon-typeTrash"
              tooltip={
                row.published && row.enrollNumber !== 0
                  ? coursesListText.cantDelete
                  : coursesListText.delete
              }
              disabled={row.published && row.enrollNumber !== 0}
            />
          )}
        </div>
        <div>
          {permissions.includes(APP_PERMISSIONS.ORG_CREATE_COURSES) && (
            <IconButton
              onClick={() => onCopyCourse(row.id || '')}
              icon={copyIc}
              tooltip={coursesListText.copyCourse}
            />
          )}
        </div>
      </div>
    ),
    id: 'course_actions',
    style: {
      padding: '0',
    },
  },
];

const CoursesListUI = React.memo(
  ({
    onCreateNewCourse,
    courses,
    onDelete,
    onEdit,
    onOpenFolders,
    isLoading,
    onOpenLearners,
    onSearchChange,
    search,
    onPreview,
    permissions,
    onCopyCourse,
    pagesCount,
    onChangePage,
  }: Props) => {
    return (
      <div className="CoursesListContainer">
        <div className="CoursesListHeader">
          <PageTitle title={coursesListText.title} />
          <div className="CourseFiltersContainer">
            <SearchInput
              value={search}
              onChange={onSearchChange}
              className="Input"
            />
            {permissions.includes(APP_PERMISSIONS.ORG_CREATE_COURSES) && (
              <Button
                title={coursesListText.create}
                onClick={onCreateNewCourse}
                uiType="action"
              />
            )}
          </div>
        </div>
        <DataTable
          data={courses}
          columns={coursesTableColumns(
            permissions,
            onDelete,
            onEdit,
            onOpenFolders,
            onOpenLearners,
            onPreview,
            onCopyCourse,
          )}
          className="DataTable"
          pagination
          paginationPerPage={COURSES_PAGE_SIZE}
          paginationComponent={TablePagination}
          paginationTotalRows={pagesCount * COURSES_PAGE_SIZE}
          onChangePage={onChangePage}
          paginationServer
          highlightOnHover
          striped
          responsive={false}
          dense
          theme="custom-stripes"
          noDataComponent={
            !isLoading ? (
              <CoursesEmptyState
                onCreate={onCreateNewCourse}
                canCreateCourses={permissions.includes(
                  APP_PERMISSIONS.ORG_CREATE_COURSES,
                )}
              />
            ) : (
              <div />
            )
          }
        />
      </div>
    );
  },
);

export default CoursesListUI;
