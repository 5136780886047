import {makeAutoObservable} from 'mobx';
import LoadingModule from '../../../../modules/LoadingModule/LoadingModule';
import {AIRepository} from '../../../../data/repositories/AiRepository';
import {toast} from 'react-toastify';
import {getErrorFromCode} from '../../../../data/errorCodes';
import {toastConfig} from '../../../../uiToolkit/Toast/toastConfig';
import {aiAssistantText} from '../aiAssistantText';

class AiAssistantStore {
  private aiRepo = new AIRepository();
  private loadingModule = new LoadingModule();
  private visibilityLoading = new LoadingModule();

  constructor() {
    makeAutoObservable(this);
  }

  public get isLoading() {
    return this.loadingModule.isLoading;
  }

  public get isLoadingVisiblity() {
    return this.visibilityLoading.isLoading;
  }

  public async getAiAssistants(
    organizationId: string,
    page: number,
    pageSize: number,
  ) {
    this.loadingModule.startLoading();
    const result = await this.aiRepo.getAiAssistantsList(
      organizationId,
      page,
      pageSize,
    );
    this.loadingModule.endLoading();

    if (result.success && result.data) {
      return result.data;
    } else {
      return {
        data: [],
        pagesCount: 0,
      };
    }
  }

  public async toggleAiAssitantVisibility(
    organizationId: string,
    assistantId: string,
    isCurrentlyVisible: boolean,
  ) {
    this.visibilityLoading.startLoading();
    const result = await this.aiRepo.toggleAiAssitantVisibility(
      organizationId,
      assistantId,
    );
    this.visibilityLoading.endLoading();
    if (!result.success && result.errors && result.errors.length) {
      toast.error(getErrorFromCode(result.errors[0]), toastConfig);
    }
    if (result.success) {
      if (isCurrentlyVisible) {
        toast.success(aiAssistantText.visibilityOFF, toastConfig);
      } else {
        toast.success(aiAssistantText.visibilityON, toastConfig);
      }
    }
    return result.success;
  }

  public async deleteAiAssitant(organizationId: string, assistantId: string) {
    this.loadingModule.startLoading();
    const result = await this.aiRepo.deleteAiAssistnat(
      organizationId,
      assistantId,
    );
    this.loadingModule.endLoading();
    if (!result.success && result.errors && result.errors.length) {
      toast.error(getErrorFromCode(result.errors[0]), toastConfig);
    }
    if (result.success) {
      toast.success(aiAssistantText.assistantDeleted, toastConfig);
    }
    return result.success;
  }
}

export default AiAssistantStore;
