import React, {useEffect, useRef, useState} from 'react';
import {SelectOption} from '../../../data/models/UI/SelectOption';
import {NA_OPTION} from '../../../data/staticValues/selectOptions';
import './styles.css';

type DropdownType = 'standart' | 'box';

interface Props {
  heading?: string;
  options: SelectOption[];
  placeholder?: string;
  selectedOption?: string | null;
  setSelectedOption: (option: string) => void;
  className?: string;
  withNA?: boolean;
  fieldError?: boolean;
  disabled?: boolean;
  isMandatory?: boolean;
  type?: DropdownType;
  subtitle?: string;
  onDropdownVisibilityToggle?: (isVisible: boolean) => void;
}

const Dropdown = React.memo(
  ({
    heading,
    options,
    placeholder,
    selectedOption,
    setSelectedOption,
    className,
    withNA,
    fieldError,
    disabled,
    isMandatory,
    type,
    subtitle,
    onDropdownVisibilityToggle,
  }: Props) => {
    const [isOpen, setIsOpen] = useState(false);

    const openerRef = useRef<any>(null);
    const openerTextRef = useRef<any>(null);
    const openerArrowRef = useRef<any>(null);

    useEffect(() => {
      window.addEventListener('click', handleBodyClick);
      return () => {
        window.removeEventListener('click', handleBodyClick);
      };
    }, [isOpen]);

    const handleBodyClick = (ev: any) => {
      if (
        isOpen &&
        openerRef?.current !== ev.target &&
        openerTextRef?.current !== ev.target &&
        openerArrowRef?.current !== ev.target
      ) {
        toggle();
      }
    };

    const getOptions = () => {
      if (withNA) {
        return [NA_OPTION, ...options];
      }
      return options;
    };

    const toggle = () => {
      if (!disabled) {
        if (onDropdownVisibilityToggle) {
          onDropdownVisibilityToggle(!isOpen);
        }
        setIsOpen(!isOpen);
      }
    };

    const onOptionClicked = (value: string) => () => {
      setSelectedOption(value);
      setIsOpen(false);
      if (onDropdownVisibilityToggle) {
        onDropdownVisibilityToggle(false);
      }
    };

    const getSelectedOption = () => {
      return getOptions().find(option => option.key === selectedOption)?.value;
    };

    const getRootClassByType = () => {
      switch (type) {
        case 'box':
          return 'DropdownBodyBox';
        default:
          return 'DropdownBody';
      }
    };

    return (
      <div className={`${className}`}>
        {heading && (
          <p className={`DropdownTitle ${disabled && 'DropdownDisabled'}`}>
            {heading}
            {isMandatory && !disabled ? (
              <span className="MandatoryIndicator">*</span>
            ) : (
              ''
            )}
          </p>
        )}
        {subtitle && <p className="DropdownSubtitleText">{subtitle}</p>}
        <div className="DropdownContainer">
          <div
            ref={openerRef}
            className={`${getRootClassByType()} ${
              !selectedOption ? 'Placeholder' : ''
            } ${fieldError ? 'FieldError' : ''} ${
              disabled && 'DropdownDisabled'
            }`}
            onClick={toggle}>
            <span ref={openerTextRef}>
              {getSelectedOption() || placeholder}
            </span>
            <span
              ref={openerArrowRef}
              className={
                isOpen ? 'icon-chevron-up' : 'icon-chevron-down'
              }></span>
          </div>
          {isOpen && (
            <ul className="DropdownList">
              {getOptions().map(option => (
                <li
                  className="ListItem"
                  onClick={onOptionClicked(option.key)}
                  key={option.key}>
                  {option.value}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    );
  },
);

export default Dropdown;
