import React, {useEffect, useState} from 'react';
import LessonsUI from '../view/LessonsUI';
import {toast} from 'react-toastify';
import {Folder} from '../../../data/models/Course/Folder';
import {useNavigate, useParams} from 'react-router-dom';
import LessonsStore from '../store/LessonsStore';
import {inject, observer} from 'mobx-react';
import {Course} from '../../../data/models/Course/Course';
import {Lesson} from '../../../data/models/Course/Lesson';
import {lessonsText} from '../lessonsText';
import {toastConfig} from '../../../uiToolkit/Toast/toastConfig';
import {ALERT_SUPPLEMENTAL} from '../../../data/staticValues/supplementalTypes';
import {GRADABLE_COURSE_TYPE} from '../../../data/staticValues/courseStructure';
import AILessonGeneratorStore from '../../../modules/AILessonGenerator/store/AILessonGeneratorStore';
import {AILessonGenerator} from '../../../modules/AILessonGenerator';
import MyLibraryModalStore from '../../../modules/MyLibraryModal/store/MyLibraryModalStore';
import {MEDIA_FILES_ALL_VIDEO} from '../../../data/models/LibraryFile/MediaFile';
import {MyLibraryModal} from '../../../modules/MyLibraryModal';
import SidebarNavigationStore from '../../../modules/Editor/SidebarNavigation/store/SidebarNavigationStore';
import NavigationStore from '../../../navigation/store/NavigationStore';
import {
  organizationHasFeature,
  SCORM_RUNTIME_SUB_FEATURE,
} from '../../../data/models/Subscription/SubscriptionPermission';

interface Props {
  lessonsStore?: LessonsStore;
  aiLessonGeneratorStore?: AILessonGeneratorStore;
  myLibraryModalStore?: MyLibraryModalStore;
  sidebarNavigationStore?: SidebarNavigationStore;
  navigationStore?: NavigationStore;
}

const LessonsContainer = inject(
  'lessonsStore',
  'aiLessonGeneratorStore',
  'myLibraryModalStore',
  'sidebarNavigationStore',
  'navigationStore',
)(
  observer(
    ({
      lessonsStore,
      aiLessonGeneratorStore,
      myLibraryModalStore,
      sidebarNavigationStore,
      navigationStore,
    }: Props) => {
      const [folder, setFolder] = useState<Folder>();
      const [course, setCourse] = useState<Course>();
      const [lessons, setLessons] = useState<Lesson[]>([]);
      const {organizationId, folderId, courseId} = useParams();
      const navigate = useNavigate();

      useEffect(() => {
        if (folder && folder.id !== folderId) {
          window.location.reload();
        }
      }, [folderId]);

      useEffect(() => {
        if (organizationId && folderId && courseId) {
          fetchFolder();
          lessonsStore?.getCourse(organizationId, courseId).then(setCourse);
          fetchLessons();
        }
      }, []);

      const fetchFolder = () => {
        if (organizationId && folderId) {
          lessonsStore
            ?.getLessonFolder(organizationId, folderId)
            .then(setFolder);
        }
      };

      const fetchLessons = () => {
        if (organizationId && folderId && courseId) {
          lessonsStore!.getLessons(organizationId, folderId).then(res => {
            setLessons(res.sort((x, y) => (x.order > y.order ? 1 : -1)));
          });
          sidebarNavigationStore!.fetchInitialPosition(
            organizationId,
            courseId,
          );
        }
      };

      const onBack = () => {
        navigate(`/editor/${organizationId}/courses/${courseId}/folders`, {
          replace: true,
        });
      };

      const onNext = () => {
        const firstLesson = lessons[0];
        if (firstLesson) {
          navigate(
            `/editor/${organizationId}/courses/${courseId}/folders/${folderId}/lessons/${firstLesson.id}/edit`,
          );
        }
      };

      const onOpen = (lesson: Lesson) => {
        navigate(
          `/editor/${organizationId}/courses/${courseId}/folders/${folderId}/lessons/${lesson.id}/edit`,
        );
      };

      const onPublish = () => {
        navigate(`/preview/${organizationId}/course/${courseId}`);
      };

      const onReorder = async (orderedLessons: Lesson[]) => {
        orderedLessons = orderedLessons.map((folder, index) => ({
          ...folder,
          order: index,
        }));
        setLessons(orderedLessons);
        if (organizationId && folderId && courseId) {
          const success = await lessonsStore?.reorderLessons(
            organizationId,
            folderId,
            orderedLessons,
          );
          if (success) {
            toast.success(lessonsText.changesSaved, toastConfig);
          }
          fetchLessons();
        }
      };

      const onRetriesChange = async (lesson: Lesson, retries: number) => {
        if (!lessonsStore?.isLoading) {
          lesson.amountOfRetries = retries;
          await onEdit(lesson);
        }
      };

      const onCreateLesson = () => {
        if (organizationId && folderId && course) {
          lessonsStore
            ?.createLesson(organizationId, {
              folderId,
              title: lessonsText.newLessonTitle,
              description: lessonsText.newLessonDescription,
              organizationId: organizationId,
              order: lessons.length,
              isGradable: course.type.toString() === GRADABLE_COURSE_TYPE,
              libraryMediaFiles: [],
            })
            .then(() => {
              fetchLessons();
            });
        }
      };

      const onDelete = async (lessonId: string) => {
        if (organizationId && courseId) {
          const success = await lessonsStore!.deleteLesson(
            organizationId,
            lessonId,
          );
          if (success) {
            const newLessons = [...lessons];
            const deletedLessonIndex = newLessons.findIndex(
              x => x.id === lessonId,
            );
            if (deletedLessonIndex > 0) {
              newLessons.splice(deletedLessonIndex, 1);
              onReorder(newLessons).then(fetchLessons);
            } else {
              fetchLessons();
            }
          } else {
            toast.error(lessonsText.operationFailed, toastConfig);
            fetchLessons();
          }
        }
      };

      const onEdit = (lesson: Lesson) => {
        if (organizationId && courseId) {
          return lessonsStore!.editLesson(organizationId, lesson).then(() => {
            fetchLessons();
          });
        }
      };

      const onAddSupplementalLesson = (lesson: Lesson) => {
        if (organizationId) {
          lessonsStore!
            .addSupplementalLesson(organizationId, lesson)
            .then(fetchLessons);
        }
      };

      const onAddSupplementalAlert = (lesson: Lesson) => {
        if (organizationId) {
          lesson.supplementalType = ALERT_SUPPLEMENTAL;
          lessonsStore!.editLesson(organizationId, lesson).then(fetchLessons);
        }
      };

      const getBreadcrumbs = () => [
        {
          title: 'Courses',
          link: `/manage/${organizationId}/courses`,
        },
        {
          title: course!.title,
          link: `/editor/${organizationId}/courses/${courseId}/folders`,
        },
        {
          title: folder!.name,
          link: `/editor/${organizationId}/courses/${courseId}/folders/${folderId}/lessons`,
        },
      ];

      const exportAsScorm = () => {
        if (organizationId && course && course.id) {
          lessonsStore!.exportAsScorm(organizationId, course.id, course.title);
        }
      };

      const onGenerateLesson = () => {
        myLibraryModalStore!.showLibrary(MEDIA_FILES_ALL_VIDEO, files => {
          if (files.length) {
            aiLessonGeneratorStore!.openWizard(
              files[0],
              lessons.length,
              folderId || '',
              courseId || '',
              course?.type.toString() === '0' || false,
              fetchLessons,
            );
          }
        });
      };

      return folder && course ? (
        <>
          <LessonsUI
            onNext={onNext}
            onBack={onBack}
            folder={folder}
            course={course}
            lessons={lessons}
            onCreate={onCreateLesson}
            onExportScorm={exportAsScorm}
            onDelete={onDelete}
            onEdit={onEdit}
            onReorder={onReorder}
            isLoading={lessonsStore!.isLoading}
            onRetriesChange={onRetriesChange}
            onAddSupplementalLesson={onAddSupplementalLesson}
            onAddSupplementalAlert={onAddSupplementalAlert}
            onOpen={onOpen}
            onPublish={onPublish}
            breadcrumbs={getBreadcrumbs()}
            onGenerateLesson={onGenerateLesson}
            hideScorm={
              !organizationHasFeature(
                navigationStore!.subscriptionPermissions,
                SCORM_RUNTIME_SUB_FEATURE,
              )
            }
          />
          <AILessonGenerator />
          <MyLibraryModal />
        </>
      ) : (
        <div />
      );
    },
  ),
);

export default LessonsContainer;
