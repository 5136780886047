import React from 'react';
import {generateAIVideosText} from '../generateAIVideosText';
import {
  LANGUAGE,
  VIDEO_LENGTH_OPTIONS,
  VOICE_GENDER_MALE,
} from '../../../../data/models/AI/VideoGenerationOptions';
import {capitalizeFirstLetter} from '../../../../utils/parseStringArrayToInt';
import {numberWithCommas} from '../../../../utils/getFirstCapitals';

interface Props {
  filename: string;
  excludeSlides?: string;
  muteDuration?: string;
  muteSlides?: string;
  excludePhrases?: string;
  duration: string;
  generateImages: boolean;
  imageRatio?: number;
  language: string;
  dialect: string;
  voiceGender: string;
  estimatedCost: number;
  availableCredits: number;
  responseStyle: string;
  responseTone: string;
  imageStyle?: string;
}

const SummaryStep = React.memo(
  ({
    filename,
    excludePhrases,
    muteDuration,
    muteSlides,
    excludeSlides,
    duration,
    generateImages,
    imageRatio,
    language,
    dialect,
    voiceGender,
    estimatedCost,
    availableCredits,
    responseStyle,
    responseTone,
    imageStyle,
  }: Props) => {
    const getExceptions = () => {
      const exceptions = [];
      if (excludeSlides) {
        exceptions.push(generateAIVideosText.summaryExclude + excludeSlides);
      }
      if (muteSlides && muteDuration) {
        exceptions.push(
          generateAIVideosText.summaryMute +
            muteSlides +
            generateAIVideosText.summaryFor +
            muteDuration,
        );
      }
      if (excludePhrases) {
        exceptions.push(
          generateAIVideosText.summaryExcludePhrases + ': ' + excludePhrases,
        );
      }
      if (exceptions.length) {
        return exceptions.reduce((prev, curr) => (prev += ', ' + curr));
      }
      return '';
    };

    return (
      <div className="StepContainer SummaryStep">
        <h5 className="StepTitle">{generateAIVideosText.summary}</h5>
        <h3>{generateAIVideosText.summaryReady}</h3>
        <p>{generateAIVideosText.summaryInfo}</p>
        <h3>{generateAIVideosText.summary}</h3>
        <ul>
          <li>
            {generateAIVideosText.summaryFile}
            {filename}
          </li>
          {getExceptions() !== '' && (
            <li>
              {generateAIVideosText.summaryExceptions}
              {getExceptions()}
            </li>
          )}
          <li>
            {generateAIVideosText.summaryVideoDuration}
            {VIDEO_LENGTH_OPTIONS.find(x => x.key === duration)?.value}
          </li>
          <li>
            {generateAIVideosText.summaryMedia}
            {generateImages
              ? `${imageRatio}%${imageStyle ? ', ' + imageStyle : ''}`
              : generateAIVideosText.summaryNoImages}
          </li>
          <li>
            {generateAIVideosText.summaryNarration}
            {LANGUAGE.find(x => x.key === language)?.value}, {dialect},{' '}
            {voiceGender === VOICE_GENDER_MALE
              ? generateAIVideosText.summaryMale
              : generateAIVideosText.summaryFemale}
          </li>
          <li>
            {generateAIVideosText.summaryTone}
            {capitalizeFirstLetter(responseStyle)} &{' '}
            {capitalizeFirstLetter(responseTone)}
          </li>
          <li>
            <br />
          </li>
          <li>
            {generateAIVideosText.summaryAvailableCredits}
            <span
              className={`CreditsCost ${
                availableCredits < estimatedCost ? 'CreditError' : ''
              }`}>
              {numberWithCommas(availableCredits.toString())}
            </span>
          </li>
          <li>
            {generateAIVideosText.summaryEstimatedCost}
            <span className="CreditsCost">
              {numberWithCommas(estimatedCost.toString())}
            </span>
          </li>
        </ul>
      </div>
    );
  },
);

export default SummaryStep;
