import React, {useEffect, useRef, useState} from 'react';
import {assistantPlaceholderIc} from '../../../../assets/images';
import {aiAssistantText} from '../aiAssistantText';
import Switch from '../../../../uiToolkit/Inputs/Switch';
import AiAssistantOptions from './AiAssistantOptions';
import {AiAssistantListItem} from '../../../../data/models/AI/AiAssistantModels';

interface Props {
  onPreviewAssistant: (id: string) => void;
  assistant: AiAssistantListItem;
  toggleAiAssitantVisibility: (
    assistantId: string,
    isCurrentlyVisible: boolean,
  ) => void;
  visibilityLoading: boolean;
  onDeleteAssistant: (id: string) => void;
  onEditAssistant: (id: string) => void;
}

const AiAssistantCard = React.memo(
  ({
    onPreviewAssistant,
    assistant,
    toggleAiAssitantVisibility,
    visibilityLoading,
    onDeleteAssistant,
    onEditAssistant,
  }: Props) => {
    const [showAssigned, setShowAssigned] = useState(false);
    const [enabled, setEnabled] = useState(false);

    const openerRef = useRef<any>(null);
    const openerTextRef = useRef<any>(null);
    const openerArrowRef = useRef<any>(null);

    useEffect(() => {
      setEnabled(assistant.enabled);
    }, [assistant]);

    const onToggleEnabled = (value: boolean) => {
      toggleAiAssitantVisibility(assistant.id, assistant.enabled);
      setEnabled(value);
    };

    useEffect(() => {
      window.addEventListener('click', handleBodyClick);
      return () => {
        window.removeEventListener('click', handleBodyClick);
      };
    }, [showAssigned]);

    const handleBodyClick = (ev: any) => {
      if (
        showAssigned &&
        openerRef?.current !== ev.target &&
        openerTextRef?.current !== ev.target &&
        openerArrowRef?.current !== ev.target
      ) {
        setShowAssigned(false);
      }
    };

    return (
      <div className="AssistantCardContainer">
        <div className="AssistantCard">
          <div className="ImageContainer">
            {assistant.avatarUrl ? (
              <img src={assistant.avatarUrl} />
            ) : (
              <img src={assistantPlaceholderIc} className="PlaceholderLogo" />
            )}
          </div>
          <div className="CardBody">
            <div className="InfoContainer">
              <p>{assistant.title}</p>
              <span className="Description">
                {assistant.status === 0
                  ? assistant.description
                  : aiAssistantText.assistantIsProcessing}
              </span>
            </div>
            {assistant.status === 0 ? (
              <div className="Footer">
                <div
                  ref={openerRef}
                  onClick={() => setShowAssigned(!showAssigned)}
                  className={`AssignedOpener ${
                    showAssigned ? 'AssignedOpenerOpen' : ''
                  }`}>
                  <span ref={openerArrowRef}>{aiAssistantText.assigned}</span>
                  <span className="icon-chevron-up" ref={openerTextRef}></span>
                </div>
                <Switch
                  value={enabled}
                  disabled={visibilityLoading}
                  onToggle={() => onToggleEnabled(!enabled)}
                />
              </div>
            ) : null}
          </div>
        </div>
        {showAssigned && (
          <div className="AssignmentsPopup">
            {assistant.assignedCourses.map(course => (
              <p key={course}>{course}</p>
            ))}
          </div>
        )}
        {assistant.status === 0 ? (
          <div className="AssistantOptionsPosition">
            <AiAssistantOptions
              onPreviewAssistant={onPreviewAssistant}
              onDeleteAssistant={onDeleteAssistant}
              assistant={assistant}
              onEditAssistant={onEditAssistant}
            />
          </div>
        ) : null}
      </div>
    );
  },
);

export default AiAssistantCard;
