import {makeAutoObservable} from 'mobx';
import {AIRepository} from '../../../../data/repositories/AiRepository';
import LoadingModule from '../../../../modules/LoadingModule/LoadingModule';
import {CreateAiAssistantModel} from '../../../../data/models/AI/AiAssistantModels';
import {toast} from 'react-toastify';
import {toastConfig} from '../../../../uiToolkit/Toast/toastConfig';
import {generateAiAssistantText} from '../generateAiAssisntantText';
import {getErrorFromCode} from '../../../../data/errorCodes';

class GenerateAiAssistantStore {
  private aiRepository = new AIRepository();
  private aiAssistantLoading = new LoadingModule();

  constructor() {
    makeAutoObservable(this);
  }

  public get isLoading() {
    return this.aiAssistantLoading!.isLoading;
  }

  public async getCoursesList(organizationId: string, search: string) {
    this.aiAssistantLoading.startLoading();
    const result = await this.aiRepository.fetchCoursesShortList(
      organizationId,
      search,
    );
    this.aiAssistantLoading.endLoading();
    if (result.success && result.data) {
      return result.data;
    }
    return [];
  }

  public async getAssistantForEditing(
    organizationId: string,
    assistantId: string,
  ) {
    this.aiAssistantLoading.startLoading();
    const result = await this.aiRepository.getAssitantForEditing(
      organizationId,
      assistantId,
    );
    if (result.success && result.data) {
      return result.data;
    }
  }

  public async createAiAssistant(
    organizationId: string,
    model: CreateAiAssistantModel,
    avatar?: File,
  ) {
    this.aiAssistantLoading.startLoading();
    const result = await this.aiRepository.createAiAssistant(
      organizationId,
      model,
      avatar,
    );
    this.aiAssistantLoading.endLoading();
    if (result.success) {
      toast.success(generateAiAssistantText.assistantCreated, toastConfig);
    } else if (result.errors && result.errors.length) {
      toast.error(getErrorFromCode(result.errors[0]), toastConfig);
    }
    return result.success;
  }

  public async editAiAssistant(
    organizationId: string,
    model: CreateAiAssistantModel,
    assistantId: string,
    avatar?: File,
  ) {
    this.aiAssistantLoading.startLoading();
    const result = await this.aiRepository.editAiAssistant(
      organizationId,
      model,
      assistantId,
      avatar,
    );
    this.aiAssistantLoading.endLoading();
    if (result.success) {
      toast.success(generateAiAssistantText.assistantEdited, toastConfig);
    } else if (result.errors && result.errors.length) {
      toast.error(getErrorFromCode(result.errors[0]), toastConfig);
    }
    return result.success;
  }
}

export default GenerateAiAssistantStore;
