import React, {useCallback, useEffect, useState} from 'react';
import AiAssistantUI from '../view/AiAssistantUI';
import {inject, observer} from 'mobx-react';
import {useNavigate, useParams} from 'react-router-dom';
import {AiAssistantChatbot} from '../../../../modules/AiAssistantChatbot';
import AiAssistantChatbotStore from '../../../../modules/AiAssistantChatbot/store/AiAssistantChatbotStore';
import AiAssistantStore from '../store/AiAssistnatStore';
import {AiAssistantListItem} from '../../../../data/models/AI/AiAssistantModels';
import ConfirmationModal from '../../../../uiToolkit/ConfirmationModal';
import {aiAssistantText} from '../aiAssistantText';

interface Props {
  aiAssistantChatbotStore?: AiAssistantChatbotStore;
  aiAssistantStore?: AiAssistantStore;
}

const AiAssistantContainer = inject(
  'aiAssistantChatbotStore',
  'aiAssistantStore',
)(
  observer(({aiAssistantChatbotStore, aiAssistantStore}: Props) => {
    const [page, setPage] = useState(0);
    const [pagesCount, setPagesCount] = useState(1);
    const [assistants, setAssistnats] = useState<AiAssistantListItem[]>([]);

    const [assistantToDeleteId, setAssistantToDeleteId] = useState<string>();

    const {organizationId} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
      loadAssistants();
    }, []);

    const loadAssistants = () => {
      if (organizationId) {
        aiAssistantStore!.getAiAssistants(organizationId, 0, 8).then(res => {
          setAssistnats(res.data);
          setPagesCount(res.pagesCount);
          setPage(0);
        });
      }
    };

    const navigateToGenerate = () => {
      navigate('generate_ai_assistant');
    };

    const loadNextPage = useCallback(async () => {
      if (organizationId && page + 1 <= pagesCount) {
        const result = await aiAssistantStore!.getAiAssistants(
          organizationId,
          page + 1,
          8,
        );
        setPagesCount(result.pagesCount);
        setAssistnats(oldAssitants => [...oldAssitants, ...result.data]);
        setPage(page + 1);
      }
      return new Promise(res => {
        res(null);
      });
    }, [page, pagesCount]);

    const onPreviewAssistant = (id: string) => {
      aiAssistantChatbotStore!.showChatbot(id);
    };

    const onToggleAssistantVisibility = (
      assistantId: string,
      isCurrentlyVisible: boolean,
    ) => {
      if (organizationId) {
        aiAssistantStore!
          .toggleAiAssitantVisibility(
            organizationId,
            assistantId,
            isCurrentlyVisible,
          )
          .then(loadAssistants);
      }
    };

    const onDeleteAssistant = () => {
      if (organizationId && assistantToDeleteId) {
        aiAssistantStore!
          .deleteAiAssitant(organizationId, assistantToDeleteId)
          .then(success => {
            setAssistantToDeleteId(undefined);
            if (success) {
              loadAssistants();
            }
          });
      }
    };

    const onEditAssistant = (id: string) => {
      navigate(`edit_ai_assistant/${id}`);
    };

    return (
      <>
        <AiAssistantUI
          loadNextPage={loadNextPage}
          navigateToGenerate={navigateToGenerate}
          isLoading={false}
          onPreviewAssistant={onPreviewAssistant}
          assistants={assistants}
          visibilityLoading={aiAssistantStore!.isLoadingVisiblity}
          toggleAiAssitantVisibility={onToggleAssistantVisibility}
          onDeleteAssistant={setAssistantToDeleteId}
          onEditAssistant={onEditAssistant}
        />
        {assistantToDeleteId && (
          <ConfirmationModal
            shown={assistantToDeleteId !== undefined}
            onAccept={onDeleteAssistant}
            onCancel={() => setAssistantToDeleteId(undefined)}
            acceptText={aiAssistantText.delete}
            title={aiAssistantText.confirmDelete}
          />
        )}
        <AiAssistantChatbot />
      </>
    );
  }),
);

export default AiAssistantContainer;
