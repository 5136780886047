import {getVideoDimentions} from '../../utils/getVideoDimentions';
import {getImageDimentions} from '../../utils/resizeImage';
import LibraryApi from '../api/LibraryAPI';
import {VideoGenerationConfig} from '../models/AI/VideoGenerationConfig';
import {GeneratedVideoChunk} from '../models/LibraryFile/GeneratedVideoChunk';
import {MEDIA_FILES_ALL} from '../models/LibraryFile/MediaFile';

class LibraryRepository {
  private libraryApi = new LibraryApi();

  public async uploadVideo(
    organizationId: string,
    file: File,
    duration: number,
    targetRes: number,
  ) {
    const urlResult = await this.libraryApi.generateVideoUploadLink(
      organizationId,
      file.type,
      Math.floor(file.size / 1024 / 1024),
    );
    if (!urlResult.success || !urlResult.data) {
      return urlResult;
    }
    try {
      const uploadResult = await this.libraryApi.uploadFileToStorage(
        file,
        urlResult.data.uploadUrl,
      );
      if (uploadResult.ok) {
        if (file.type.includes('image')) {
          const dimentions = await getImageDimentions(file);
          return await this.libraryApi.createMediaLibraryFile(
            organizationId,
            urlResult.data.storageFileName,
            file.type,
            file.name,
            Math.floor(file.size / 1024 / 1024),
            duration,
            dimentions?.width || 0,
            dimentions?.height || 0,
            null,
          );
        } else if (file.type.includes('video')) {
          const dimentions = await getVideoDimentions(file);
          return await this.libraryApi.createMediaLibraryFile(
            organizationId,
            urlResult.data.storageFileName,
            file.type,
            file.name,
            Math.floor(file.size / 1024 / 1024),
            duration,
            dimentions?.width || 0,
            dimentions?.height || 0,
            targetRes,
          );
        } else {
          return await this.libraryApi.createMediaLibraryFile(
            organizationId,
            urlResult.data.storageFileName,
            file.type,
            file.name,
            Math.floor(file.size / 1024 / 1024),
            duration,
            0,
            0,
            null,
          );
        }
      }
    } catch {
      return Promise.resolve({
        success: false,
        errors: ['unknown'],
      });
    }

    return urlResult;
  }

  public getMediaFiles(
    page: number,
    organizationId: string,
    pageSize: number,
    orderBy: string,
    typeFilter?: number,
    search?: string,
    orderAsk?: boolean,
  ) {
    return this.libraryApi.getLibraryFiles(
      page,
      organizationId,
      pageSize,
      orderBy,
      typeFilter !== undefined ? typeFilter : MEDIA_FILES_ALL,
      search,
      orderAsk,
    );
  }

  public getByContentId(organizationId: string, contentId: string) {
    return this.libraryApi.getFileByContentId(organizationId, contentId);
  }

  public deleteMediaFile(organizationId: string, mediaFileId: string) {
    return this.libraryApi.deleteMediaFile(organizationId, mediaFileId);
  }

  public downloadTranscript(organizationId: string, mediaFileId: string) {
    return this.libraryApi.downloadTranscript(organizationId, mediaFileId);
  }

  public async getVimeoVideos(
    organizationId: string,
    page: number,
    pageSize: number,
  ) {
    return this.libraryApi.getVimeoVideos(organizationId, page, pageSize);
  }

  public async generateAiVideosFromSlides(
    organizationId: string,
    config: VideoGenerationConfig,
  ) {
    const result = await this.libraryApi.generateAiVideosFromSlides(
      organizationId,
      config,
    );
    return result;
  }

  public async getFileOrientation(organizationId: string, fileId: string) {
    return this.libraryApi.getFileOrientation(organizationId, fileId);
  }

  public getVideoChunksForGeneratedContent(
    organizationId: string,
    mediaFileId: string,
  ) {
    return this.libraryApi.getVideoChunksForGeneratedContent(
      organizationId,
      mediaFileId,
    );
  }

  public async updateVideoChunksForGeneratedContent(
    organizationId: string,
    mediaFileId: string,
    chunks: GeneratedVideoChunk[],
  ) {
    return this.libraryApi.updateVideoChunksForGeneratedContent(
      organizationId,
      mediaFileId,
      chunks,
    );
  }

  public async compileVideoFile(organizationId: string, mediaFileId: string) {
    return this.libraryApi.compileVideoFile(organizationId, mediaFileId);
  }
}

export default LibraryRepository;
