import React, {useEffect, useState} from 'react';
import CoursesListUI from '../view/CoursesListUI';
import {useNavigate, useParams} from 'react-router-dom';
import {inject, observer} from 'mobx-react';
import ListCoursesStore from '../store/ListCoursesStore';
import ConfirmationModal from '../../../../uiToolkit/ConfirmationModal';
import {coursesListText} from '../coursesListText';
import {toast} from 'react-toastify';
import {toastConfig} from '../../../../uiToolkit/Toast/toastConfig';
import NavigationStore from '../../../../navigation/store/NavigationStore';

interface Props {
  listCoursesStore?: ListCoursesStore;
  navigationStore?: NavigationStore;
}

const CoursesListContainer = inject(
  'listCoursesStore',
  'navigationStore',
)(
  observer(({listCoursesStore, navigationStore}: Props) => {
    const [courseToDelete, setCourseToDelete] = useState<string>();
    const [courseToCopy, setCourseToCopy] = useState<string>();
    const [search, setSearch] = useState('');
    const navigate = useNavigate();
    const {organizationId} = useParams();

    useEffect(() => {
      listCoursesStore!.setCurrentPage(0);
      fetchCourses();
    }, [listCoursesStore, search]);

    const onCreateNewCourse = () => {
      navigate(`/manage/${organizationId}/courses/create`);
    };

    const onEdit = (id: string) => {
      navigate(`/manage/${organizationId}/courses/${id}/general`);
    };

    const fetchCourses = () => {
      if (organizationId) {
        listCoursesStore!.getOrganizationCourses(organizationId, search);
      }
    };

    const onStartDelete = (courseId: string) => {
      setCourseToDelete(courseId);
    };

    const onDelete = () => {
      if (organizationId && courseToDelete && !listCoursesStore!.isLoading) {
        listCoursesStore!
          .deleteCourse(organizationId, courseToDelete)
          .then(success => {
            if (success) {
              toast.success(coursesListText.courseDelted, toastConfig);
            }
            fetchCourses();
            setCourseToDelete(undefined);
          });
      }
    };

    const onOpenFolders = (courseId: string) => {
      navigate(`/editor/${organizationId}/courses/${courseId}/folders`);
    };

    const onOpenLearners = (courseName: string) => {
      navigate(`/manage/${organizationId}/learners`, {
        state: {courseName},
      });
    };

    const onPreview = (courseId: string) => {
      navigate(`/preview/${organizationId}/course/${courseId}`);
    };

    const onOpenGradebook = (courseId: string) => {
      navigate(`/manage/${organizationId}/gradebook/${courseId}`);
    };

    const onCopyCourse = (courseId: string) => {
      setCourseToCopy(courseId);
    };

    const copyCourse = () => {
      if (organizationId && courseToCopy) {
        listCoursesStore!.copyCourse(organizationId, courseToCopy).then(() => {
          fetchCourses();
          setCourseToCopy(undefined);
        });
      }
    };

    const getFilteredCourses = () => {
      if (search) {
        return listCoursesStore!.courses.filter(item =>
          item.title.toLowerCase().includes(search.toLowerCase()),
        );
      }
      return listCoursesStore!.courses;
    };

    const onChangePage = (page: number) => {
      listCoursesStore!.setCurrentPage(page - 1);
      fetchCourses();
    };

    return (
      <div>
        <CoursesListUI
          onCreateNewCourse={onCreateNewCourse}
          courses={getFilteredCourses()}
          onEdit={onEdit}
          onDelete={onStartDelete}
          onOpenFolders={onOpenFolders}
          isLoading={listCoursesStore!.isLoading}
          onOpenLearners={onOpenLearners}
          search={search}
          onCopyCourse={onCopyCourse}
          onSearchChange={setSearch}
          onPreview={onPreview}
          permissions={navigationStore?.userPermissions || []}
          onOpenGradebook={onOpenGradebook}
          pagesCount={listCoursesStore!.pagesCount}
          onChangePage={onChangePage}
        />
        <ConfirmationModal
          shown={courseToDelete !== undefined}
          onAccept={onDelete}
          onCancel={() => setCourseToDelete(undefined)}
          title={coursesListText.deleteConfirm}
        />
        <ConfirmationModal
          shown={courseToCopy !== undefined}
          onAccept={copyCourse}
          onCancel={() => setCourseToCopy(undefined)}
          title={coursesListText.copyConfirm}
        />
      </div>
    );
  }),
);

export default CoursesListContainer;
