import React, {useState} from 'react';
import './styles.css';
import {AiAssitantChatMessage} from '../../../data/models/AI/AiAssistantModels';
import Lottie from 'lottie-react';
import {loadingLottie, typingLoadingLottie} from '../../../assets/lottie';

interface Props {
  onClose: () => void;
  chat: AiAssitantChatMessage[];
  onSendMessage: (message: string) => void;
  isLoading: boolean;
  isSendingMessage: boolean;
  assistantTitle: string;
}

const AiAssistnatChatbotUI = React.memo(
  ({
    onClose,
    chat,
    onSendMessage,
    isLoading,
    isSendingMessage,
    assistantTitle,
  }: Props) => {
    const [currentMessage, setCurrentMessage] = useState('');

    const onSend = () => {
      if (currentMessage && !isSendingMessage) {
        onSendMessage(currentMessage);
        setCurrentMessage('');
      }
    };

    const listenForEnterInput = (e: any) => {
      if (e.key == 'Enter') {
        onSend();
      }
    };

    const renderUserMessage = (chat: AiAssitantChatMessage) => (
      <>
        {chat.assistant_response ? (
          <div className="Chatbox-conversation Chatbox-ai">
            <div className="AiAvatarContainer">
              <span className="icon-ai_assistant_ic"></span>
            </div>
            <div>
              <p>{chat.assistant_response}</p>
            </div>
          </div>
        ) : (
          <div className="Chatbox-conversation Chatbox-ai">
            <div className="AiAvatarContainer">
              <Lottie
                animationData={typingLoadingLottie}
                loop={true}
                style={{transform: 'scale(2.8)'}}
              />
            </div>
            <div>
              <h5>Thinking...</h5>
            </div>
          </div>
        )}
        <div className="Chatbox-conversation Chatbox-user">
          <div>
            <p>{chat.learner_question}</p>
          </div>
        </div>
      </>
    );

    return (
      <div className="AiAssistantChatboxContainer">
        <div className="AiAssistantChatboxHeader">
          <span className="icon-ai_assistant_ic"></span>
          <span>{assistantTitle}</span>
          <a href="#" onClick={onClose}>
            <span className="icon-x-close"></span>
          </a>
        </div>
        <div className="AiAssistantChatboxBody">
          {isLoading ? (
            <div className="LoaderContainer">
              <Lottie
                animationData={loadingLottie}
                loop={true}
                style={{width: '34px', height: '34px'}}
              />
            </div>
          ) : chat.length ? (
            [...chat].reverse().map(renderUserMessage)
          ) : (
            <span className="empty">
              You can start by typing a message below
            </span>
          )}
        </div>

        <div className="AiAssistantChatboxFooter">
          <button onClick={() => setCurrentMessage('')}>
            <span className="icon-eraser"></span>
          </button>
          <input
            type="text"
            className="chat-input"
            placeholder="Write your message"
            value={currentMessage}
            onKeyDown={listenForEnterInput}
            onChange={e => setCurrentMessage(e.target.value)}
            maxLength={1000}
          />
          <button onClick={onSend} disabled={isLoading}>
            <span className="icon-send"></span>
          </button>
        </div>
      </div>
    );
  },
);

export default AiAssistnatChatbotUI;
