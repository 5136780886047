import * as React from 'react';
import NavBar from '../../../uiToolkit/NavBar';
import {Col, Container, Row} from 'react-bootstrap';
import {homeText} from '../homeText';
import {UserInfo} from '../../../data/models/User/UserInfo';
import {LearningCourseModel} from '../../../data/models/Course/CourseList';
import UserEmptyState from '../components/UserEmptyState';
import OrganizationEmptyState from '../components/OrganizationEmptyState';
import CoursesCard from '../components/CoursesCard';
import './styles.css';
import {
  ORGANIZATION_USER_LEARNER,
  ORGANIZATION_USER_STAFF,
  OrganizationDetails,
} from '../../../data/models/Oganization/Organization';
import LearnerEmptyState from '../components/LearnerEmptyState';
import {LearnerAiAssistants} from '../../../modules/LearnerAiAssistants';

interface Props {
  userInfo?: UserInfo;
  userOrganizationDetails?: OrganizationDetails;
  isLoading: boolean;
  onExplore: () => void;
  onCreateOrg: () => void;
  onCreateCourse: () => void;
  learningCourses: LearningCourseModel[];
  onOpenCourse: (courseId: string, teaching?: boolean) => void;
  loadNextPage: () => Promise<any>;
}

const HomeUI = React.memo(
  ({
    userInfo,
    isLoading,
    onCreateOrg,
    onExplore,
    onCreateCourse,
    learningCourses,
    onOpenCourse,
    userOrganizationDetails,
    loadNextPage,
  }: Props) => {
    const [navHeight, setNavHeight] = React.useState(0);
    const observer = React.useRef<any>();

    const overscrollLock = React.useRef(false);

    const lastPostElementRef = (node: any) => {
      if (isLoading || overscrollLock.current) return;
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting) {
          overscrollLock.current = true;
          loadNextPage().then(() => {
            overscrollLock.current = false;
          });
        }
      });

      if (node) observer.current.observe(node);
    };

    return (
      <div className="HomeContainer">
        <NavBar onHeightCalculated={setNavHeight} />
        <div
          className="HomeScrollingContainer"
          style={{
            height: `calc(100vh - ${navHeight}px)`,
          }}>
          <Container fluid>
            <Row className="HomeStatisticsContainer">
              <Col xs={12}>
                <div className="StatisticsRow">
                  <h2 className="HomeWelcomeText">
                    {homeText.welcome} {userInfo?.name}!
                  </h2>
                  {userOrganizationDetails &&
                  userOrganizationDetails?.userType ===
                    ORGANIZATION_USER_LEARNER ? (
                    <div className="LearnerOrgDetails">
                      {userOrganizationDetails.avatar && (
                        <img src={userOrganizationDetails.avatar} />
                      )}
                      <h2>{userOrganizationDetails.name}</h2>
                    </div>
                  ) : (
                    <div />
                  )}
                </div>
              </Col>
            </Row>
          </Container>

          <Container className="HomeCoursesContainer">
            {learningCourses?.length ? (
              <Row className="HomeCoursesContent">
                <h2 className="HomeCourseTitle">{homeText.learningTitle}</h2>
                {learningCourses.map(course => (
                  <Col
                    lg={{span: 3}}
                    xs={12}
                    md={6}
                    key={course.id}
                    className="courses-card_container">
                    <CoursesCard
                      course={course}
                      onOpenCourse={() => onOpenCourse(course.id)}
                    />
                  </Col>
                ))}
              </Row>
            ) : null}

            {!learningCourses.length ? (
              isLoading ? (
                <div />
              ) : userOrganizationDetails?.userType ===
                ORGANIZATION_USER_STAFF ? (
                <OrganizationEmptyState onCreate={onCreateCourse} />
              ) : userOrganizationDetails?.userType ===
                ORGANIZATION_USER_LEARNER ? (
                <LearnerEmptyState organization={userOrganizationDetails} />
              ) : (
                <UserEmptyState onExplore={onExplore} onCreate={onCreateOrg} />
              )
            ) : null}
          </Container>
          <div ref={lastPostElementRef} />
        </div>

        <LearnerAiAssistants />
      </div>
    );
  },
);

export default HomeUI;
