import React, {useEffect} from 'react';
import CreateOrganizationUI from '../view/CreateOrganizationUI';
import CreateOrganizationStore from '../store/CreateOrganizationStore';
import {inject, observer} from 'mobx-react';
import NavigationStore from '../../../navigation/store/NavigationStore';
import {useParams} from 'react-router-dom';

interface Props {
  isOpen: boolean;
  onClose: (payload?: any) => void;
  createOrganizationStore?: CreateOrganizationStore;
  navigationStore?: NavigationStore;
}

const CreateOrganizationContainer = inject(
  'createOrganizationStore',
  'navigationStore',
)(
  observer(
    ({isOpen, onClose, createOrganizationStore, navigationStore}: Props) => {
      const {organizationId} = useParams();
      useEffect(() => {
        return () => {
          createOrganizationStore!.clearErrors();
        };
      }, []);

      const onCreateOrganization = async (
        name: string,
        primaryColor: string,
        secondaryColor: string,
        type: number,
        image?: File,
      ) => {
        if (!organizationId) return;
        const result = await createOrganizationStore!.createOrganization(
          organizationId,
          name,
          type,
          image,
        );
        if (result) {
          await navigationStore!.tryGetMyOrganization();
          onClose(result.id);
        }
      };

      const clearErrors = () => {
        createOrganizationStore!.clearErrors();
      };

      return isOpen ? (
        <CreateOrganizationUI
          onCreateOrganization={onCreateOrganization}
          isLoading={createOrganizationStore!.isLoading}
          errors={createOrganizationStore!.errors}
          clearErrors={clearErrors}
        />
      ) : (
        <div />
      );
    },
  ),
);

export default CreateOrganizationContainer;
