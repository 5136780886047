import React, {useState} from 'react';
import ReactPlayer from 'react-player';
import {OnProgressProps} from 'react-player/base';
import {truncateDurationTo3Decimals} from '../../../utils/getTimeTextFromSeconds';
import {StartEndPlayerRef} from '../models';

interface Props {
  source: string;
  startTime: number;
  endTime: number;
  onFinished: () => void;
  onLoaded: () => void;
  reference?: (ref: StartEndPlayerRef) => void;
  width: number;
  height: number;
  visible: boolean;
  id: string;
}

const StartEndPlayer = React.memo(
  ({
    startTime,
    endTime,
    onFinished,
    reference,
    width,
    height,
    source,
    visible,
    onLoaded,
    id,
  }: Props) => {
    const [playing, setPlaying] = React.useState(false);
    const [duration, setDuration] = React.useState(-1);
    const [currentTime, setCurrentTime] = React.useState(0);
    const [loaded, setLoaded] = React.useState(false);

    const [finishedPlaying, setFinishedPlaying] = useState(false);
    const playerRef = React.useRef<ReactPlayer>(null);

    React.useEffect(() => {
      if (reference) {
        reference({
          togglePlaying,
          seekTo,
          duration,
          isPlaying: playing,
          setPlaying,
          currentTime: currentTime,
        });
      }
    }, [reference, duration, currentTime, loaded, visible]);

    React.useEffect(() => {
      if (loaded && visible) {
        setPlaying(true);
      }
    }, [visible, loaded]);

    React.useEffect(() => {
      setLoaded(false);
      seekTo(startTime);
      setFinishedPlaying(false);
      setDuration(playerRef.current?.getInternalPlayer()?.duration);
    }, [source, startTime, endTime]);

    React.useEffect(() => {
      if (finishedPlaying) {
        setPlaying(false);
        setDuration(0);
        onFinished();
      }
    }, [finishedPlaying]);

    const startEndProgressHandler = (state: OnProgressProps) => {
      //video is not intialized
      if (duration < 1) return;

      const playedSeconds = truncateDurationTo3Decimals(state.playedSeconds);
      //video is within start end timframe
      if (playedSeconds >= startTime && playedSeconds <= endTime) {
        if (!loaded) {
          onLoaded();
          setLoaded(true);
        }
        setCurrentTime(playedSeconds);
        return;
      }

      //video time is before the specified start time
      if (playedSeconds < startTime) {
        playerRef.current?.seekTo(startTime, 'seconds');
        return;
      }

      //video time is over the endTime or over the duration (this callback often goes over the video total time)
      if (playedSeconds >= endTime || playedSeconds >= duration) {
        setFinishedPlaying(true);
        //snap the progress to end time (sometimes video goes over the end time is this callback is slow)
        if (playedSeconds > endTime) {
          playerRef.current?.seekTo(endTime, 'seconds');
        }
        if (playedSeconds >= duration) {
          playerRef.current?.seekTo(duration, 'seconds');
        }
      }
    };

    const onDuration = (seconds: number) => {
      const truncatedDuration = truncateDurationTo3Decimals(seconds);
      setDuration(truncatedDuration);
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const pause = () => {
      setPlaying(false);
    };

    const togglePlaying = () => {
      if (!finishedPlaying) {
        setPlaying(!playing);
      }
    };

    const seekTo = (time: number) => {
      if (time < startTime) {
        playerRef.current?.seekTo(startTime, 'seconds');
      } else if (time > endTime) {
        playerRef.current?.seekTo(endTime, 'seconds');
      } else {
        playerRef.current?.seekTo(time, 'seconds');
      }
    };

    //UI Handlers
    const onInnerPlayerReady = (player: ReactPlayer) => {
      if (playerRef && playerRef.current) {
        const innerPlayer = player.getInternalPlayer();
        innerPlayer.style.borderRadius = '20px';
        innerPlayer.style.backgroundColor = 'transparent';
        innerPlayer.disablePictureInPicture = true;
      }
    };

    return (
      <ReactPlayer
        url={source}
        playing={playing}
        ref={playerRef}
        width={width}
        height={height}
        onReady={onInnerPlayerReady}
        onProgress={startEndProgressHandler}
        onDuration={onDuration}
        progressInterval={1}
        style={{display: loaded && visible ? 'block' : 'none'}}
        playsinline
        loop={false}
        onError={e => {
          console.log(id, ' ', e);
        }}
        pip={false}
        // onPause={() => {
        //   if (playing && playerRef) {
        //     playerRef.current?.getInternalPlayer().play();
        //   }
        // }}
      />
    );
  },
);

export default StartEndPlayer;
