import React, {useEffect, useRef, useState} from 'react';
import './styles.css';
import {appText} from '../../assets/text/appText';
import {SortOption} from '../../data/models/UI/SelectOption';

interface Props {
  sortField: string;
  sortDirection: string;
  options: SortOption[];
  onSetSort: (field: string, direction: 'asc' | 'desc') => void;
}

const SortPill = React.memo(
  ({sortField, sortDirection, options, onSetSort}: Props) => {
    const [openOptions, setOpenOptions] = useState(false);

    const openerRef = useRef<any>(null);
    const openerArrowRef = useRef<any>(null);

    useEffect(() => {
      window.addEventListener('click', handleBodyClick);
      return () => {
        window.removeEventListener('click', handleBodyClick);
      };
    }, [openOptions]);

    const handleBodyClick = (ev: any) => {
      if (
        openOptions &&
        openerRef?.current !== ev.target &&
        openerArrowRef?.current !== ev.target
      ) {
        setOpenOptions(false);
      }
    };

    const onClickOption = (option: SortOption) => {
      if (option.field === sortField) {
        onSetSort(option.field, getOppositeOrder(sortDirection));
      } else {
        onSetSort(option.field, option.order);
      }
    };

    const getOppositeOrder = (order: string) => {
      return order === 'asc' ? 'desc' : 'asc';
    };

    return (
      <div className="SortPillContainer">
        <button
          className={'SortPillOpener'}
          ref={openerRef}
          onClick={() => setOpenOptions(!openOptions)}>
          <span className="icon-sort-arrows" ref={openerArrowRef}></span>
          {appText.sort}
        </button>
        {openOptions && (
          <div className="Options">
            {options.map(option => (
              <div
                onClick={() => onClickOption(option)}
                key={option.field + option.order}
                className={`Option ${
                  sortField === option.field && sortDirection === option.order
                    ? 'OptionActive'
                    : ''
                }`}>
                {option.title}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  },
);

export default SortPill;
