import React, {useEffect, useRef, useState} from 'react';
import ModalContainer from '../../../uiToolkit/ModalContainer';
import SimplePlayerControlled from '../../../uiToolkit/SimplePlayerControlled';
import {GeneratedVideoChunk} from '../../../data/models/LibraryFile/GeneratedVideoChunk';

interface Props {
  onClose: () => void;
  chunks: GeneratedVideoChunk[];
  show: boolean;
}

const ChunksFullPreview = React.memo(({onClose, chunks, show}: Props) => {
  const [currentChunk, setCurrentChunk] = useState<GeneratedVideoChunk>();
  const [width, setWidth] = useState(0);

  const containerRef = useRef<any>(null);

  React.useEffect(() => {
    window.addEventListener('resize', setPlayerDimentions);
    return () => {
      window.removeEventListener('resize', setPlayerDimentions);
    };
  }, []);

  React.useLayoutEffect(() => {
    setPlayerDimentions();
  }, [show, currentChunk]);

  const setPlayerDimentions = () => {
    if (containerRef && containerRef.current) {
      setWidth(containerRef.current.offsetWidth);
    }
  };

  useEffect(() => {
    if (show) {
      const firstChunk = chunks
        .filter(x => x.include)
        .sort((x, y) => (x.order > y.order ? 1 : -1))[0];
      setCurrentChunk(firstChunk);
    }
  }, [show]);

  const getNextChunk = () => {
    if (currentChunk) {
      const nextChunk = chunks
        .filter(x => x.include)
        .sort((x, y) => (x.order > y.order ? 1 : -1))
        .find(chunk => chunk.order > currentChunk.order);
      if (nextChunk) {
        setCurrentChunk(nextChunk);
      }
    }
  };

  return show ? (
    <ModalContainer onClose={onClose}>
      {currentChunk ? (
        <div className="FullChunksPreviewPlayerContainer" ref={containerRef}>
          <SimplePlayerControlled
            source={currentChunk.uri}
            width={width}
            onFinished={getNextChunk}
            dontLoop
          />
        </div>
      ) : (
        <div />
      )}
    </ModalContainer>
  ) : null;
});

export default ChunksFullPreview;
