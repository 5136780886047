import React, {useEffect, useState} from 'react';
import {inject, observer} from 'mobx-react';
import MyLibraryModalUI from '../view/MyLibraryModalUI';
import MyLibraryModalStore from '../store/MyLibraryModalStore';
import {useParams} from 'react-router-dom';
import {MediaFile} from '../../../data/models/LibraryFile/MediaFile';

interface Props {
  myLibraryModalStore?: MyLibraryModalStore;
}

const PAGE_SIZE = 9;

const MyLibraryModalContainer = inject('myLibraryModalStore')(
  observer(({myLibraryModalStore}: Props) => {
    const [files, setFiles] = useState<MediaFile[]>([]);
    const [pages, setPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [storageLimit, setStorageLimit] = useState(0);
    const [sortOptions, setSortOptions] = useState({
      column: 'date_created',
      order: 'desc',
    });
    const [selectedFilter, setSelectedFilter] = useState(
      myLibraryModalStore!.getSourceType(),
    );
    const [search, setSearch] = useState('');
    const {organizationId} = useParams();

    useEffect(() => {
      if (organizationId && myLibraryModalStore!.libraryShown) {
        setSelectedFilter(myLibraryModalStore!.getSourceType());
        myLibraryModalStore!
          .getStorageLimit(organizationId)
          .then(setStorageLimit);
      }
      if (!myLibraryModalStore!.libraryShown) {
        setFiles([]);
        setCurrentPage(1);
        setPages(0);
        setSearch('');
        setSelectedFilter(-1);
      }
    }, [myLibraryModalStore!.libraryShown]);

    useEffect(() => {
      if (myLibraryModalStore!.libraryShown) {
        fetchTapyblVideos();
      }
    }, [search, selectedFilter, currentPage, sortOptions]);

    const onSearch = (value: string) => {
      setCurrentPage(1);
      setSearch(value);
    };

    const fetchTapyblVideos = () => {
      if (organizationId) {
        myLibraryModalStore
          ?.getMediaFiles(
            currentPage - 1,
            organizationId,
            PAGE_SIZE,
            sortOptions.column,
            search,
            selectedFilter,
            sortOptions.order === 'asc',
          )
          .then(res => {
            setFiles(res.data || []);
            setPages(res.pagesCount);
          });
      }
    };

    const onPageChange = (page: number) => {
      setCurrentPage(page);
    };

    const onSort = (selectedColumn: any, sortOrder: any) => {
      setSortOptions({column: selectedColumn, order: sortOrder});
    };

    return myLibraryModalStore!.libraryShown ? (
      <MyLibraryModalUI
        refreshFiles={fetchTapyblVideos}
        isLoading={myLibraryModalStore!.isLoading}
        onCancel={() => myLibraryModalStore!.closeLibrary()}
        files={files}
        type={myLibraryModalStore!.getSourceType()}
        onFileSelect={(files: MediaFile[]) =>
          myLibraryModalStore!.onFileSelect(files)
        }
        pages={pages}
        currentPage={currentPage}
        onPageChange={onPageChange}
        storageLimit={storageLimit}
        onSort={onSort}
        search={search}
        setSearch={onSearch}
        selectedFilter={selectedFilter}
        setSelectedFilter={setSelectedFilter}
        sortOptions={sortOptions}
        selectMultiple={myLibraryModalStore!.canSelectMultiple}
        preSelectedFiles={myLibraryModalStore!.preSelectedFiles}
      />
    ) : null;
  }),
);

export default MyLibraryModalContainer;
