import React, {useState} from 'react';
import {lessonEditorText} from '../../../lessonEditorText';
import {LessonContentChoiceImageOption} from '../../../../../data/models/LessonContent/LessonContentBase';
import {
  MEDIA_FILES_IMAGE,
  MediaFile,
} from '../../../../../data/models/LibraryFile/MediaFile';
import BoxImagePreview from '../../../../../uiToolkit/BoxImagePreview';
import EditorOptionsFooter from '../../../../../uiToolkit/Editor/EditorOptionsFooter';
import {BaseContainerForwardProps} from '../../BaseOptionsContainer';
import ReplaceNode from '../../ReplaceNode';

interface Props extends BaseContainerForwardProps {
  entityToEdit: LessonContentChoiceImageOption;
}

const ChoiceImageOptionOptionsUI = React.memo(
  ({
    entityToEdit,
    onOpenLibrary,
    isLoading,
    onDelete,
    isPublished,
    onEdit,
  }: Props) => {
    const [newFile, setNewFile] = useState<MediaFile>();

    const openLibrary = () => {
      onOpenLibrary(MEDIA_FILES_IMAGE, files => setNewFile(files[0]));
    };

    const onEditImage = () => {
      if (newFile) {
        onEdit({
          ...entityToEdit,
          contentId: newFile?.storageFileName,
        } as LessonContentChoiceImageOption);
      }
    };

    return (
      <div>
        <BoxImagePreview
          heading={lessonEditorText.source}
          source={newFile ? newFile.fileUrl : entityToEdit.contentUrl}
          actionTitle={lessonEditorText.editSource}
          action={openLibrary}
        />
        <ReplaceNode currentNode={entityToEdit} />
        <EditorOptionsFooter
          onSave={onEditImage}
          saveEnabled={newFile !== undefined}
          onDelete={onDelete}
          isLoading={isLoading}
          deleteDisabled={isPublished}
        />
      </div>
    );
  },
);

export default ChoiceImageOptionOptionsUI;
