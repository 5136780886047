import React from 'react';
import {generateAIVideosText} from '../generateAIVideosText';
import MyLibraryModalStore from '../../../../modules/MyLibraryModal/store/MyLibraryModalStore';
import {inject, observer} from 'mobx-react';
import {
  MEDIA_FILES_DOCUMENT,
  MediaFile,
} from '../../../../data/models/LibraryFile/MediaFile';
import LibraryFilesInput from '../../../../uiToolkit/Inputs/LibraryFilesInput';

interface Props {
  onFileSelected: (file?: MediaFile) => void;
  file?: MediaFile;
  myLibraryModalStore?: MyLibraryModalStore;
}

const UploadFileStep = inject('myLibraryModalStore')(
  observer(({onFileSelected, file, myLibraryModalStore}: Props) => {
    const onOpenLibrary = () => {
      myLibraryModalStore!.showLibrary(
        MEDIA_FILES_DOCUMENT,
        files => onFileSelected(files[0]),
        false,
        file ? [file] : [],
      );
    };

    return (
      <div>
        <h5 className="StepTitle">{generateAIVideosText.uploadPPTText}</h5>
        <h6 className="UploadFileInfo">
          {generateAIVideosText.uploadPPTSubtitle}
        </h6>
        <br />
        <h6 className="UploadFileInfo">
          {generateAIVideosText.uploadPPTInfo1}
        </h6>
        <h6 className="UploadFileInfo">
          {generateAIVideosText.uploadPPTInfo2}
        </h6>
        <div className="d-flex flex-row justify-content-center mt-4 ps-4 pe-4">
          <LibraryFilesInput
            onBrowse={onOpenLibrary}
            files={file ? [file] : []}
            onRemoveFile={() => onFileSelected(undefined)}
          />
        </div>
      </div>
    );
  }),
);

export default UploadFileStep;
